import { DialogProps, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import { ClientInformationSection } from './components/ClientInformationsSection';
import { DealComments } from './components/ClientInformationsSection/components/DealComments';
import { DealCommentsProvider } from './hooks/Comments';

import { Container, Content, DialogCustomized, Header } from './style';

interface IDealDialogProps extends DialogProps {
  handleOnClose(): void;
}

function DealDialog({ handleOnClose, ...rest }: IDealDialogProps): JSX.Element {
  return (
    <DialogCustomized {...rest}>
      <DealCommentsProvider>
        <Container>
          <Header>
            <Typography variant="h6">Informações</Typography>
            <IconButton onClick={handleOnClose}>
              <AiOutlineClose />
            </IconButton>
          </Header>
          <Content>
            <ClientInformationSection />
            <DealComments />
          </Content>
        </Container>
      </DealCommentsProvider>
    </DialogCustomized>
  );
}

export { DealDialog };
