import { ApolloError, useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ArrowBack, Mail } from '@material-ui/icons';
import REQUEST_RECOVER_PASSWORD, {
  requestRecoverPasswordMutationResponse,
  requestRecoverPasswordMutationVars,
} from '../../GraphQL/mutations/requestRecoverPassword';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  Container,
  Content,
  PresentationContainer,
  TurnBackContainer,
} from './style';
import Logo from '../../assets/logo.color.light.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';

function RecoverPasswordRequest(): JSX.Element {
  const formRef = useRef<FormHandles>(null);

  const handleOnRequestRecoverPasswordCompleted = useCallback(() => {
    toast.success(`As instruções foram enviadas com sucesso!`);
  }, []);

  const handleOnRequestRecoverPasswordError = useCallback(
    (error: ApolloError) => {
      toast.warning(
        `Não foi possível realizar a recuperação de email: ${error.message}`,
      );
    },
    [],
  );

  const [requestRecoverPassword, { loading }] = useMutation<
    requestRecoverPasswordMutationResponse,
    requestRecoverPasswordMutationVars
  >(REQUEST_RECOVER_PASSWORD, {
    onError: handleOnRequestRecoverPasswordError,
    onCompleted: handleOnRequestRecoverPasswordCompleted,
  });

  const handleOnSubmit = useCallback(
    async (data: requestRecoverPasswordMutationVars) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Você precisa digitar um email')
            .email('Digite um email válido'),
        });
        await schema.validate(data, { abortEarly: false });
        await requestRecoverPassword({ variables: data });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          toast.warning('Por favor, preencha os dados corretamente');
          return;
        }
        toast.error('Houve um erro ao consultar o banco de dados');
      }
    },
    [requestRecoverPassword],
  );

  return (
    <Container>
      <Content ref={formRef} onSubmit={handleOnSubmit}>
        <img src={Logo} alt="Logotipo LCC" className="logo" />
        <PresentationContainer>
          <h1>Esqueceu a senha?</h1>
          <h2>
            Digite o e-mail associado à sua conta e nós enviaremos as instruções
            para resetar sua senha.{' '}
          </h2>
        </PresentationContainer>
        <Input name="email" label="E-mail" />
        <Button fullWidth type="submit" endIcon={<Mail />}>
          {`${!loading ? 'Enviar' : 'Enviando'} Instruções`}
        </Button>
        <TurnBackContainer to="/login">
          <ArrowBack />
          <p>Voltar</p>
        </TurnBackContainer>
      </Content>
    </Container>
  );
}

export default RecoverPasswordRequest;
