import { gql } from '@apollo/client';
import { Contact } from './getContacts';

export interface getCompanyContactQueryVars {
  contactID: number;
}

export interface getCompanyContactQueryResponse {
  getCompanyContact: Contact;
}

const GET_COMPANY_CONTACT = gql`
  query getCompanyContact($contactID: Int!) {
    getCompanyContact(data: { contactID: $contactID }) {
      ID
      NAME
      UF_CRM_1607694757
      UF_CRM_1602185690
      DATE_CREATE
      DATE_MODIFY
      EMAIL {
        VALUE
      }
      PHONE {
        VALUE
      }
    }
  }
`;

export { GET_COMPANY_CONTACT };
