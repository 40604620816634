import styled from 'styled-components';
import { IconButton ,withStyles } from '@material-ui/core';

export const Container = styled.section`
  grid-area: avatar;
  justify-self: end;
  height: 100%;
  width: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;
  & .MuiPaper-elevation8 {
    border: 0.322581px solid #ebebf9;
    box-sizing: border-box;
    box-shadow: 0px 6.45161px 9.67742px rgba(0, 0, 0, 0.1),
      0px 2.58065px 3.87097px rgba(0, 0, 0, 0.05);
    border-radius: 5.16129px;
  }
`;

export const ButtonStyles = withStyles({
  root: {
    height: '50px',
  },
})(IconButton);
