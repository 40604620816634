import { Button } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/Auth';

import { Container } from './style';

function Controller(): JSX.Element {
  const { signOut } = useAuth();
  const history = useHistory();
  const handleOnLogoutButtonClick = useCallback(() => {
    signOut();
  }, [signOut]);

  const handleOnMyProfileButtonCLick = useCallback(() => {
    history.push('/perfil');
  }, [history]);

  return (
    <Container>
      <Button fullWidth onClick={handleOnMyProfileButtonCLick}>
        Meu Perfil
      </Button>
      <Button fullWidth onClick={handleOnLogoutButtonClick}>
        Sair
      </Button>
    </Container>
  );
}

export default Controller;
