/* eslint-disable jsx-a11y/label-has-associated-control */
import { IconButton, IconButtonProps } from '@material-ui/core';
import { useField } from '@unform/core';
import React, { useRef, useEffect } from 'react';

import { IoMdAttach } from 'react-icons/io';

interface InputFileProps extends IconButtonProps {
  name: string;
}

function InputFile({ name, ...rest }: InputFileProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files',
      clearValue(ref: HTMLInputElement) {
        // eslint-disable-next-line no-param-reassign
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);
  return (
    <>
      <label htmlFor="deal-comment-icon-button-file">
        <IconButton {...rest} color="primary" component="span">
          <IoMdAttach />
        </IconButton>
      </label>
      <input
        name={fieldName}
        type="file"
        ref={inputRef}
        id="deal-comment-icon-button-file"
        accept=""
        style={{
          display: 'none',
        }}
      />
    </>
  );
}

export { InputFile };
