import { TextField, withStyles } from '@material-ui/core';

export const TextFieldCustomized = withStyles({
  root: {
    transition: '0.3s all ease',
    '& label, svg': {
      color: '#363C4C',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#38816A',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#38816A',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#38816A',
      },
    },
  },
})(TextField);
