import styled from 'styled-components';
import colors from '../../../../../../../styles/colors';

export const Container = styled.section`
  width: 100%;
  height: 6.813rem;

  display: flex;
  align-items: flex-start;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;

  background: #ffffff;
  /* shadow-base */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;

  & > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.text.third};
  }

  & > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: ${colors.text.second};
  }

  & > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.text.third};
  }

  padding: 1rem;
`;
