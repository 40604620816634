import styled from 'styled-components';
import { Form } from '@unform/web';
import colors from '../../styles/colors';

export const Container = styled.section`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${colors.background.first};
`;

export const Content = styled(Form)`
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    width: 100%;
  }
  & > img {
    width: 50%;
    margin: 1rem 0;
  }
`;

export const SignInContainer = styled.div`
  display: flex;
  align-self: start;
  gap: 0.5rem;
  font-size: 16px;
  & > p {
    color: ${colors.text.second};
  }

  & > a {
    color: ${colors.text.first};
    text-decoration: underline;
  }
`;
