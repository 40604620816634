import { gql } from '@apollo/client';

export interface verifyIfCompanySawTutorialQueryResponse {
  verifyIfCompanySawTutorial: boolean;
}

const VERIFY_IF_COMPANY_SAW_TUTORIAL = gql`
  query {
    verifyIfCompanySawTutorial
  }
`;

export default VERIFY_IF_COMPANY_SAW_TUTORIAL;
