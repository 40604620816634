import { TableCell, withStyles } from '@material-ui/core';
import colors from '../../../../../../../styles/colors';

export const HeadTableCell = withStyles({
  root: {
    color: colors.text.first,
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
})(TableCell);
