import { IconButton, Popover } from '@material-ui/core';
/* import { HelpOutline } from '@material-ui/icons'; */
import React, { MouseEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '../../../../components/Avatar';
import { useModal } from '../../../../hooks/Modal';
import NewsOnboardWindow from '../../../../pages/NewsOnboard';
import PaperController from './PaperController';
import { Container } from './style';

function UserController(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setModalContent, setIsModalOpen } = useModal();

  const handleOnAvatarClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleOnPopoverCloseClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const history = useHistory();

  const handleOnHelpButtonClick = useCallback(() => {
    history.push('/onboarding');
  }, [history]);

  const handleOnNewsButtonClick = useCallback(() => {
    setModalContent(<NewsOnboardWindow />);
    setIsModalOpen(true);
    history.push('/negocios');
  }, [history, setIsModalOpen, setModalContent]);

  return (
    <Container>
            <IconButton
        style={{
          width: 120,
          height: 50,
          display: 'flex',
          backgroundColor: '#46A184',
          color: '#F6F9FB',
          borderRadius: 5,
          marginRight: 20,
          fontSize: 14,
          fontWeight: 'bold',
        }}
        onClick={handleOnNewsButtonClick}
      >
        Novidades
      </IconButton>
      <IconButton
        style={{
          width: 120,
          height: 50,
          display: 'flex',
          backgroundColor: '#16415F',
          color: '#F6F9FB',
          borderRadius: 5,
          marginRight: 20,
          fontSize: 14,
          fontWeight: 'bold',
        }}
        onClick={handleOnHelpButtonClick}
      >
        Tutorial
      </IconButton>
      <IconButton
        onClick={handleOnAvatarClick}
        style={{
          padding: 0,
          marginRight: 20,
        }}
      >
        <Avatar />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleOnPopoverCloseClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{
          top: 7,
          left: -50,
        }}
      >
        <PaperController />
      </Popover>
    </Container>
  );
}

export default UserController;
