import { ApolloError, useMutation } from '@apollo/client';
import {
  CircularProgress,
  Table,
  TableContainer,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { SimulationData } from '../../../../../../../GraphQL/mutations/createSimulation';
import GET_PRICE_TABLE_SIMULATION, {
  getPriceTableSimulationResponse,
  getPriceTableSimulationVars,
} from '../../../../../../../GraphQL/mutations/getPriceTableSimulation';
import GET_VEHICLE_TABLE_SIMULATION, {
  getVehicleTableSimulationResponse,
  getVehicleTableSimulationVars,
} from '../../../../../../../GraphQL/mutations/getVehicleTableSimulation';
import {
  GET_SAC_SIMULATION,
  getSACSimulationResponse,
  getSACSimulationVars,
  InstallmentData,
} from '../../../../../../../GraphQL/mutations/getSACSimulation';
import RealStateTable from './RealStateTable';
import VehicleTable from './VehicleTable';

interface SimulationTableProps {
  simulation: SimulationData;
}

function SimulationTable({ simulation }: SimulationTableProps): JSX.Element {
  const [installments, setInstallments] = useState<InstallmentData[]>(
    [] as InstallmentData[],
  );
  const handleOnGetPriceTableSimulationCompleted = useCallback(
    ({
      getPriceTableSimulation: fetchedInstallments,
    }: getPriceTableSimulationResponse) => {
      setInstallments(fetchedInstallments);
    },
    [],
  );

  const handleOnGetPriceTableSimulationError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(
        `Não foi possível obter a tabela de simulações PRICE: ${message}`,
      );
    },
    [],
  );

  const [
    getPriceTableSimulation,
    { loading: IsGetPriceTableSimulationLoading },
  ] = useMutation<getPriceTableSimulationResponse, getPriceTableSimulationVars>(
    GET_PRICE_TABLE_SIMULATION,
    {
      onCompleted: handleOnGetPriceTableSimulationCompleted,
      onError: handleOnGetPriceTableSimulationError,
    },
  );

  const handleOnGetVehicleTableSimulationCompleted = useCallback(
    ({
      getVehicleTableSimulation: fetchedInstallments,
    }: getVehicleTableSimulationResponse) => {
      setInstallments(fetchedInstallments);
    },
    [],
  );

  const handleOnGetVehicleTableSimulationError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(
        `Não foi possível obter a tabela de simulações PRICE: ${message}`,
      );
    },
    [],
  );

  const [
    getVehicleTableSimulation,
    { loading: IsGetVehicleTableSimulationLoading },
  ] = useMutation<
    getVehicleTableSimulationResponse,
    getVehicleTableSimulationVars
  >(GET_VEHICLE_TABLE_SIMULATION, {
    onCompleted: handleOnGetVehicleTableSimulationCompleted,
    onError: handleOnGetVehicleTableSimulationError,
  });

  const handleOnGetSACTableSimulationCompleted = useCallback(
    ({ getSACSimulation: fetchedInstallments }: getSACSimulationResponse) => {
      setInstallments(fetchedInstallments);
    },
    [],
  );

  const handleOnGetSACTableSimulationError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(
        `Não foi possível obter a tabela de simulações SAC: ${message}`,
      );
    },
    [],
  );

  const [
    getSACTableSimulation,
    { loading: isGetSACTableSimulationLoading },
  ] = useMutation<getSACSimulationResponse, getSACSimulationVars>(
    GET_SAC_SIMULATION,
    {
      onCompleted: handleOnGetSACTableSimulationCompleted,
      onError: handleOnGetSACTableSimulationError,
    },
  );

  useEffect(() => {
    if (simulation.amortizationType === 0) {
      getSACTableSimulation({
        variables: {
          loanAmount: simulation.value,
          loanInterest: simulation.selicRate + simulation.averageRate,
          numberOfInstallments: simulation.numberOfInstallments,
          paymentEntry: simulation.paymentEntry,
        },
      });
    }
    if (simulation.amortizationType === 1) {
      getPriceTableSimulation({
        variables: {
          loanAmount: simulation.value,
          loanInterest: simulation.selicRate + simulation.averageRate,
          numberOfInstallments: simulation.numberOfInstallments,
          paymentEntry: simulation.paymentEntry,
        },
      });
    }
    if (simulation.amortizationType === 2) {
      getVehicleTableSimulation({
        variables: {
          loanAmount: simulation.value,
          loanInterest: simulation.averageRate,
          numberOfInstallments: simulation.numberOfInstallments,
          paymentEntry: simulation.paymentEntry,
        },
      });
    }
  }, [
    getVehicleTableSimulation,
    getPriceTableSimulation,
    getSACTableSimulation,
    simulation,
  ]);
  return (
    <>
      {isGetSACTableSimulationLoading ||
      IsGetPriceTableSimulationLoading ||
      IsGetVehicleTableSimulationLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Table>
            {simulation.amortizationType === 0 ||
            simulation.amortizationType === 1 ? (
              <RealStateTable installments={installments} />
            ) : (
              <VehicleTable installments={installments} />
            )}
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default SimulationTable;
