import { IconButton, Tooltip } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { SimulationData } from '../../../../../../../GraphQL/mutations/createSimulation';
import api from '../../../../../../../services/api';
import colors from '../../../../../../../styles/colors';

interface DownloadSimulationButtonProps {
  simulation: SimulationData;
}

function DownloadSimulationButton({
  simulation,
}: DownloadSimulationButtonProps): JSX.Element {
  const handleOnClick = useCallback(async () => {
    const response = await api.post(
      `/simulation/pdf/${simulation.id}`,
      {},
      {
        responseType: 'blob',
      },
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'simulacao.pdf');
    document.body.appendChild(link);
    link.click();
  }, [simulation]);
  return (
    <Tooltip title="Baixar Simulação">
      <IconButton
        style={{
          color: colors.primary.normal.first,
        }}
        onClick={handleOnClick}
      >
        <GetApp />
      </IconButton>
    </Tooltip>
  );
}

export default DownloadSimulationButton;
