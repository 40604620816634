import React, { useRef, useEffect, useState, useCallback } from 'react';
import { AiFillFile } from 'react-icons/ai';
import { Skeleton } from '@material-ui/lab';
import {
  CommentFooter,
  CommentText,
  Container,
  PresentationContainer,
} from './style';
import { IBitrixTimeLineComment } from '../../../../../../../../../../../../../../../GraphQL/queries/listTimeLineComments';
import { DownloadButton } from './components/DownloadButton';
import { useCompany } from '../../../../../../../../../../../../../../../hooks/Company';
import { DealCommentProvider } from './hooks/DealComment';

interface CommentContainerProps {
  comment: IBitrixTimeLineComment;
  fillBackgroundColor?: boolean;
  isLoading?: boolean;
}

function CommentContainer({
  fillBackgroundColor = false,
  isLoading = false,
  comment,
}: CommentContainerProps): JSX.Element {
  const { company } = useCompany();
  const reference = useRef<HTMLDivElement>(null);

  const [timeSinceMessage, setTimeSinceMessage] = useState<string>('');

  const getTimeSince = useCallback((date: string): string => {
    const seconds = Math.floor((Date.now() - Date.parse(date)) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return `${Math.floor(interval)} anos`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return `${Math.floor(interval)} meses`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return `${Math.floor(interval)} dias`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return `${Math.floor(interval)} horas`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      return `${Math.floor(interval)} minutos`;
    }
    return `${Math.floor(seconds)} segundos`;
  }, []);

  useEffect(() => {
    if (reference.current) {
      reference.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    const message = getTimeSince(comment.CREATED);
    setTimeSinceMessage(message);
  }, [comment, getTimeSince]);

  return (
    <DealCommentProvider comment={comment}>
      <Container
        fillBackground={comment.AUTHOR_ID !== 113 || fillBackgroundColor}
        ref={reference}
      >
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <PresentationContainer>
              <h1>
                {comment.AUTHOR_ID === 113 ? company.name : 'Suporte LCC'}
              </h1>
              <p>{timeSinceMessage}</p>
            </PresentationContainer>
            <CommentText>{comment?.COMMENT}</CommentText>
            <CommentFooter>
              {comment.FILES &&
                comment.AUTHOR_ID === 113 &&
                comment.FILES.map((file) => (
                  <DownloadButton
                    file={file}
                    color="primary"
                    endIcon={<AiFillFile />}
                    key={file.id}
                  >
                    {file.name}
                  </DownloadButton>
                ))}
            </CommentFooter>
          </>
        )}
      </Container>
    </DealCommentProvider>
  );
}

export { CommentContainer };
