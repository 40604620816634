import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AvatarContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.8rem;
  border-radius: 50%;
`;

export const UserInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0.8rem 0;
  & > h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    /* or 120% */
    text-align: center;
    /* Text/Text Dark */
    color: #363c4c;
  }
  & > h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    /* or 123% */
    text-align: center;
    /* Text/Text Dark */
    color: #363c4c;
  }
`;
