import { Button, ButtonProps } from '@material-ui/core';
import React, { useCallback } from 'react';

import { IBitrixFile } from '../../../../../../../../../../../../../../../../../GraphQL/queries/listTimeLineComments';
import api from '../../../../../../../../../../../../../../../../../services/api';
import { useDealComment } from '../../hooks/DealComment';

interface DownloadButton extends ButtonProps {
  file: IBitrixFile;
}

function DownloadButton({
  file,
  children,
  ...rest
}: DownloadButton): JSX.Element {
  const { comment } = useDealComment();
  const handleOnButtonClick = useCallback(async () => {
    const response = await api.post(
      '/deal/downloadCommentFile',
      {
        commentID: comment.ID,
        fileID: file.id,
      },
      {
        responseType: 'blob',
      },
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.name);
    document.body.appendChild(link);
    link.click();
  }, [comment, file]);

  return (
    <Button onClick={handleOnButtonClick} {...rest}>
      {children}
    </Button>
  );
}

export { DownloadButton };
