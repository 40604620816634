import { gql } from '@apollo/client';

export interface RecoverPasswordMutationVars {
  token: string;
  password: string;
}

export interface RecoverPasswordMutationData {
  email: string;
}

export interface RecoverPasswordMutationResponse {
  recoverPassword: RecoverPasswordMutationData;
}

const RECOVER_PASSWORD = gql`
  mutation recoverPassword($password: String!, $token: String!) {
    recoverPassword(data: { password: $password, token: $token }) {
      email
    }
  }
`;

export default RECOVER_PASSWORD;
