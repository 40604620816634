import { createGlobalStyle } from 'styled-components';
import colors from './colors';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    scroll-behavior: smooth;

    &::-webkit-scrollbar{
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-track{
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${colors.primary.dark.first};
      border-radius: 15px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${colors.primary.dark.third};
    }
  }
`;
