import { gql } from '@apollo/client';
import { DealCategoryData } from '../queries/getDealsCategories';

export interface deleteDealProductMutationVars {
  id: string;
}

export interface deleteDealProductMutationResponse {
  deleteDealProduct: DealCategoryData;
}

const DELETE_DEAL_PRODUCT = gql`
  mutation deleteDealProduct($id: String!) {
    deleteDealProduct(data: { id: $id }) {
      id
      name
    }
  }
`;

export default DELETE_DEAL_PRODUCT;
