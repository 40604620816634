import { TextField, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../../styles/colors';

export const Container = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ControlSection = styled.section`
  width: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  & button {
    padding: 0.5rem;
    & svg {
      color: ${colors.primary.normal.first};
    }
  }
`;

export const SelectCustomized = withStyles({
  root: {
    transition: '0.3s all ease',
    '& label, svg': {
      color: '#363C4C',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#38816A',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#38816A',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#38816A',
      },
    },
  },
})(TextField);
