import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 47px;
  display: grid;
  align-items: center;
  justify-items: left;
  grid-auto-flow: column;
  grid-template-columns: 1fr min-content;
  gap: 0.1rem;
  padding: 7px;
  border: 1px solid #cfd7e5;
  border-radius: 3px;
  & > button:first-of-type {
    width: 100%;
  }
  & > button:last-of-type {
    background-color: #46a184;
    color: #fff;
    width: 33px;
    height: 33px;
    border-radius: 3px;
    & svg {
      width: 1rem;
      height: 1rem;
    }
  }
  & a {
    text-decoration: none;
  }
`;
