import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';

export const Container = styled(ScrollContainer)`
  display: flex;

  gap: 1rem;
  overflow: auto;
  cursor: grab;
  padding-bottom: 1rem;

  & > * {
    cursor: default;
  }
`;
