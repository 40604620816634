import React from 'react';

import { IBitrixTimeLineComment } from '../../../../../../../../../../../../../GraphQL/queries/listTimeLineComments';
import { CommentContainer } from './components/CommentContainer';
import { CommentForm } from './components/CommentForm';
import { useDealComments } from '../../../../hooks/Comments';

import { Container, Content } from './style';

function DealComments(): JSX.Element {
  const { comments, deal, loading } = useDealComments();
  return (
    <Container>
      <Content>
        {loading ? (
          <>
            <CommentContainer
              comment={{} as IBitrixTimeLineComment}
              isLoading
            />
            <CommentContainer
              comment={{} as IBitrixTimeLineComment}
              fillBackgroundColor
              isLoading
            />
            <CommentContainer
              comment={{} as IBitrixTimeLineComment}
              isLoading
            />
            <CommentContainer
              comment={{} as IBitrixTimeLineComment}
              fillBackgroundColor
              isLoading
            />
            <CommentContainer
              comment={{} as IBitrixTimeLineComment}
              isLoading
            />
            <CommentContainer
              comment={{} as IBitrixTimeLineComment}
              fillBackgroundColor
              isLoading
            />
            <CommentContainer
              comment={{} as IBitrixTimeLineComment}
              isLoading
            />
            <CommentContainer
              comment={{} as IBitrixTimeLineComment}
              fillBackgroundColor
              isLoading
            />
          </>
        ) : (
          comments.map((comment) => (
            <CommentContainer key={comment.ID} comment={comment} />
          ))
        )}
      </Content>
      <CommentForm deal={deal} />
    </Container>
  );
}

export { DealComments };
