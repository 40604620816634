import styled, { keyframes } from 'styled-components';

const animationOpacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 700px;
  min-height: 400px;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    min-width: 100%;
    height: 100%;
    max-height: 450px ;
    border-radius: 10px;
    animation: ${animationOpacity} 0.5s ease-in-out;
  }
  @media screen and (max-width: 415px){
    min-height: 0;
  }
`;
