import { ApolloError, useQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { SimulationData } from '../../../GraphQL/mutations/createSimulation';
import GET_SIMULATIONS, {
  getSimulationsQueryResponse,
} from '../../../GraphQL/queries/getSimulations';

interface SimulationContextData {
  simulations: SimulationData[];
  setSimulations(simulations: SimulationData[]): void;
  isGetSimulationsQueryLoading: boolean;
}

const SimulationContext = createContext<SimulationContextData>(
  {} as SimulationContextData,
);

interface SimulationProviderProps {
  children: ReactNode;
}

export function SimulationProvider({
  children,
}: SimulationProviderProps): JSX.Element {
  const [simulations, setSimulations] = useState<SimulationData[]>(
    [] as SimulationData[],
  );

  const handleOnGetSimulationsQueryCompleted = useCallback(
    ({ getSimulations: fetchedSimulations }: getSimulationsQueryResponse) => {
      setSimulations(fetchedSimulations);
    },
    [],
  );

  const handleOnGetSimulationsQueryError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(`Não foi possível obter as simulações: ${message}`);
    },
    [],
  );

  const { loading: isGetSimulationsQueryLoading } = useQuery(GET_SIMULATIONS, {
    onCompleted: handleOnGetSimulationsQueryCompleted,
    onError: handleOnGetSimulationsQueryError,
  });

  return (
    <SimulationContext.Provider
      value={{ simulations, setSimulations, isGetSimulationsQueryLoading }}
    >
      {children}
    </SimulationContext.Provider>
  );
}

export function useSimulation(): SimulationContextData {
  const context = useContext(SimulationContext);
  if (!context) {
    throw new Error('useSimulation should be used within a SimulationProvider');
  }
  return context;
}
