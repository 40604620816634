import React, { useEffect, useRef, useState } from 'react';

import { BaseDateTimePickerProps } from '@material-ui/pickers';
import { useField } from '@unform/core';
import { KeyboardDatePickerCustomized } from './style';

interface InputProps extends BaseDateTimePickerProps {
  name: string;
  label: string;
}

function InputDateLight({ name, label, ...rest }: InputProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [date, setDate] = useState(defaultValue || new Date());
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return date;
      },
      setValue: (ref, value) => {
        // eslint-disable-next-line no-param-reassign
        setDate(value);
      },
      clearValue: () => {
        // eslint-disable-next-line no-param-reassign
        setDate(new Date());
      },
    });
  }, [date, fieldName, registerField]);

  const minYearDate = new Date(new Date().getFullYear() - 18, 1, 1);
  return (
    <KeyboardDatePickerCustomized
      name={fieldName}
      onChange={setDate}
      value={date}
      ref={inputRef}
      helperText={error}
      error={!!error}
      format="dd/MM/yyyy"
      fullWidth
      label={label}
      views={['year', 'month', 'date']}
      inputVariant="filled"
      maxDate={minYearDate}
      {...rest}
    />
  );
}

export default InputDateLight;
