import styled, { css } from 'styled-components';
import colors from '../../../../styles/colors';

interface ContainerProps {
  gridArea?: string;
}

export const Container = styled.section<ContainerProps>`
  ${({ gridArea }) =>
    !!gridArea &&
    css`
      grid-area: ${gridArea};
    `}
  width: 100%;
  max-width: 590px;
  min-width: 400px;
  display: grid;
  align-items: start;
  justify-items: start;
  grid-auto-rows: min-content;
  gap: 1rem;

  & > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 16px;
    white-space: nowrap;
    padding-left: 0.5rem;
    height: 1rem;

    color: ${colors.text.third};
  }

  @media screen and (max-width: 1500px){
    max-width: 90%;
    margin: 0 auto;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  background-color: #fff;
  box-shadow: 0px 1px 2px  rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1rem;
`;
