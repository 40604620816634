import React, { ComponentType } from 'react';
import { SvgIconProps } from '@material-ui/core';

import { Container, IconContainer, InfoContainer } from './style';
import colors from '../../../../../styles/colors';

interface CardProps {
  icon: ComponentType<SvgIconProps>;
  title: string;
  value: number;
  iconBackgroundColor?: string;
}

function Card({
  icon: Icon,
  title,
  value,
  iconBackgroundColor = colors.primary.normal.first,
}: CardProps): JSX.Element {
  return (
    <Container>
      <IconContainer backgroundColor={iconBackgroundColor}>
        <Icon />
      </IconContainer>
      <InfoContainer>
        <p>{title}</p>
        <h1>{value}</h1>
      </InfoContainer>
    </Container>
  );
}

export default Card;
