import { gql } from '@apollo/client';
import { SimulationData } from './createSimulation';

export interface DeleteSimulationMutationVars {
  id: string;
}

export interface DeleteSimulationMutationResponse {
  deleteSimulation: SimulationData;
}

const DELETE_SIMULATION = gql`
  mutation deleteSimulation($id: String!) {
    deleteSimulation(data: { id: $id }) {
      id
    }
  }
`;

export default DELETE_SIMULATION;
