import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
} from 'react';

import { toast } from 'react-toastify';

import UPDATE_PROFILE, {
  UpdateProfileMutationResponse,
  updateProfileMutationVars,
} from '../../../GraphQL/mutations/updateProfile';
import { useCompany } from '../../../hooks/Company';

interface IProfileContext {
  updateProfile(
    options: MutationFunctionOptions<
      UpdateProfileMutationResponse,
      updateProfileMutationVars
    >,
  ): void;
  loading: boolean;
}

const profileContext = createContext<IProfileContext>({} as IProfileContext);

interface ProfileProviderProps {
  children: ReactNode;
}

export function ProfileProvider({
  children,
}: ProfileProviderProps): JSX.Element {
  const { setCompany } = useCompany();

  const handleOnUpdateProfileMutationCompleted = useCallback(
    ({ updateProfile: updatedCompany }: UpdateProfileMutationResponse) => {
      setCompany(updatedCompany);
      toast.success('Perfil atualizado com sucesso!');
    },
    [setCompany],
  );

  const handleOnUpdateProfileMutationError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(
        `Não foi possível realizar as alterações no seu perfil: ${message}`,
      );
    },
    [],
  );

  const [updateProfile, { loading }] = useMutation<
    UpdateProfileMutationResponse,
    updateProfileMutationVars
  >(UPDATE_PROFILE, {
    onCompleted: handleOnUpdateProfileMutationCompleted,
    onError: handleOnUpdateProfileMutationError,
  });

  return (
    <profileContext.Provider
      value={{
        loading,
        updateProfile,
      }}
    >
      {children}
    </profileContext.Provider>
  );
}

export function useProfile(): IProfileContext {
  const context = useContext(profileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
}
