import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ApolloError, useMutation } from '@apollo/client';
import { FaUserAlt } from 'react-icons/fa';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { Container, ButtonsContainer, InputCustomizedRed, Text } from './style';
import Button from '../../../../components/Button';
import { useCompanies } from '../../../../hooks/ListCompanies';
import { ICompany } from '../../../../GraphQL/queries/getCompany';
import reactivateCompany, {
  reactivatedCompanyIDMutationResponse,
  reactivatedCompanyIDMutationVars,
} from '../../../../GraphQL/mutations/reactivateCompany';

const ReactivateCompany = (): JSX.Element => {
  const formRef = useRef<FormHandles>(null);
  const [selectedEmail, setSelectedEmail] = useState<string>('');
  const [expiresIn, setExpiresIn] = useState<string>('');

  const { companies } = useCompanies();

  const findCompanyIdByEmail = useCallback(
    (email) => {
      const selectedCompany = companies.find(
        (company: ICompany) => company.email === email,
      );
      return selectedCompany?.id ? selectedCompany.id : '';
    },
    [companies],
  );

  const handleOnReactivatedCompanyMutationCompleted = useCallback(() => {
    toast.success(`O usuário foi reativado com sucesso!`);
    setSelectedEmail('');
    setExpiresIn('');
  }, []);

  const handleOnReactivatedCompanyMutationError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(
        `Não foi possível suspender a empresa selecionada : ${message}`,
      );
    },
    [],
  );

  const [getReactivatedCompanyID, { loading }] = useMutation<
    reactivatedCompanyIDMutationResponse,
    reactivatedCompanyIDMutationVars
  >(reactivateCompany, {
    onCompleted: handleOnReactivatedCompanyMutationCompleted,
    onError: handleOnReactivatedCompanyMutationError,
  });

  const handleOnSubmit = useCallback(async () => {
    try {
      formRef.current?.setErrors({});
      const companyID = findCompanyIdByEmail(selectedEmail);
      if (companyID) {
        await getReactivatedCompanyID({
          variables: {
            companyID,
            expiresIn,
          },
        });
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast.warning('Por favor, preencha os dados corretamente');
        return;
      }
      toast.warning('Houve um erro ao enviar os dados para o servidor');
    }
  }, [findCompanyIdByEmail, getReactivatedCompanyID, selectedEmail, expiresIn]);

  return (
    <>
      <Container onSubmit={handleOnSubmit} ref={formRef}>
        <InputCustomizedRed
          name="email"
          label="E-mail"
          fullWidth
          variant="filled"
          value={selectedEmail}
          onChange={(e) => setSelectedEmail(e.target.value)}
        />
        <InputCustomizedRed
          name="expiresIn"
          label="Expira em"
          fullWidth
          variant="filled"
          value={expiresIn}
          onChange={(e) => setExpiresIn(e.target.value)}
        />
        <ButtonsContainer>
          <Button
            fullWidth
            type="submit"
            endIcon={<FaUserAlt />}
            style={{
              backgroundColor: '#246C9F',
              color: '#FFFFFF',
              fontSize: '0,8rem',
              display: 'flex',
              justifyContent: 'space-between',
              textTransform: 'none',
              padding: '10px 15px',
            }}
          >
            {`${loading ? 'Reativando' : 'Reativar'}`}
          </Button>
        </ButtonsContainer>
        <Text>Esta ação irá re-ativar o usuário suspenso</Text>
        {/* <CSVButton /> */}
      </Container>
    </>
  );
};

export default ReactivateCompany;
