import React from 'react';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';
import { MdAttachMoney } from 'react-icons/md';
import { FaRegMoneyBillAlt, FaHourglassEnd, FaHome, FaCarAlt, FaHandHoldingUsd } from 'react-icons/fa';
import ProductItem from '../ProductItem';
import { Box, ProductsBox } from './style';
import { useCompany } from '../../../hooks/Company';

function ProductBox(): JSX.Element {
  const { company } = useCompany();

  return (
    <ProductsBox>
      <Box>
        <ProductItem
          name="Crédito Imobiliário"
          icon={<FaHome style={{ fontSize: '30px' }} />}
          backcolor="#E1F9F5"
          color="#6ADFCF"
          path={`https://querocredito.app.br/imobiliario/${company.id}`}
        />
        <ProductItem
          name="Crédito Veicular"
          icon={<FaCarAlt style={{ fontSize: '30px' }} />}
          backcolor="#DBDBEB"
          color="#4A4C9D"
          path={`https://querocredito.app.br/veicular/${company.id}`}
        />
        <ProductItem
          name="Antecipação de Recebíveis"
          icon={<FaHourglassEnd style={{ fontSize: '30px' }} />}
          backcolor="#D8E7FD"
          color="#3F87F8"
          path={`https://querocredito.app.br/antecipacao-de-recebiveis/${company.id}`}
        />
      </Box>
      <Box>
        <ProductItem
          name="Crédito Pessoal"
          icon={<FaHandHoldingUsd style={{ fontSize: '30px' }} />}
          backcolor="#FBDCDE"
          color="#EB515C"
          path={`https://querocredito.app.br/pessoal/${company.id}`}
        />
        <ProductItem
          name="Crédito Consignado"
          icon={<RiMoneyDollarBoxLine style={{ fontSize: '30px' }} />}
          backcolor="#FCEBD9"
          color="#F19C40"
          path={`https://querocredito.app.br/consignado/${company.id}`}
        />
        <ProductItem
          name="Câmbio"
          icon={<FaRegMoneyBillAlt style={{ fontSize: '30px' }} />}
          backcolor="#E0F6E9"
          color="#62D490"
          path={`https://querocredito.app.br/cambio/${company.id}`}
        />
      </Box>
      <Box>
        <ProductItem
          name="Saque FGTS"
          icon={<MdAttachMoney style={{ fontSize: '30px' }} />}
          backcolor="#CCF8EB"
          color="#02AF7B"
          path={`https://querocredito.app.br/saque-fgts/${company.id}`}
        />
      </Box>
    </ProductsBox>
  );
}

export default ProductBox;
