import styled from 'styled-components';

export const Container = styled.section`
  width: 80%;
  margin: 20px auto;
  height: min-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`;
