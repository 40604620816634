import { TextField, BaseTextFieldProps } from '@material-ui/core';
import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';

interface IInputProps extends BaseTextFieldProps {
  name: string;
}

function Input({ name, ...rest }: IInputProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        // eslint-disable-next-line no-param-reassign
        ref.current.value = value;
      },
      clearValue: (ref) => {
        // eslint-disable-next-line no-param-reassign
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <TextField
      inputRef={inputRef}
      inputProps={{
        name: fieldName,
        value: defaultValue,
      }}
      error={!!error}
      helperText={error}
      fullWidth
      {...rest}
    />
  );
}

export default Input;
