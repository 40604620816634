/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Directions } from '@material-ui/icons';

import { Container, Content } from './style';

import Logo from '../../assets/logo.color.light.svg';
import { useModal } from '../../hooks/Modal';

import Welcome from './components/Welcome';
import Button from '../../components/Button';
import { useCompany } from '../../hooks/Company';

function Onboard(): JSX.Element {
  const { company } = useCompany();
  const history = useHistory();
  const { setModalContent, setIsModalOpen } = useModal();

  const handleOnContinueButtonClick = useCallback(() => {
    setModalContent(<Welcome />);
    setIsModalOpen(true);
    history.push('/negocios');
  }, [history, setIsModalOpen, setModalContent]);

  return (
    <>
      <Container>
        <img style={{
          marginTop: '30px',
        }} src={Logo} alt="LCC" />
        <Content>
          <iframe
            src="https://www.youtube.com/embed/pQKYIuDInMA?controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />

          <h1>{`Bem vindo(a), ${company.personName}!`}</h1>
          <h2 style={{
            marginBottom: '20px'
          }}>
            Aprenda a fazer negócios como fazem as Instituições Financeiras e
            lucrar com isso.
          </h2>
          <Button
            endIcon={<Directions />}
            onClick={handleOnContinueButtonClick}
          >
            Continuar
          </Button>
        </Content>
      </Container>
    </>
  );
}

export default Onboard;
