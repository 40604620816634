import styled from 'styled-components';
import { rgba } from 'polished';
import colors from '../../../../../../styles/colors';

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 0;
  gap: 1rem;
`;

export const TitleSection = styled.article`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(34, 44, 59, 0.05);
  font-size: 1rem;
  line-height: 0.875rem;
  padding: 1rem;
  font-weight: 500;
`;

export const InfoSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Info = styled.article`
  width: min-content;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  flex-direction: column;
  white-space: nowrap;
  & > h1 {
    font-size: 1rem;
    color: ${colors.primary.normal.second};
    font-weight: 400;
  }
  & > h2 {
    font-size: 1.125rem;
    color: ${colors.text.fourth};
    font-weight: normal;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ResultSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    gap: 1rem;
    flex-direction: column;
    & > section {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;

export const Amount = styled.section`
  width: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 1px;
  border: 1px solid ${colors.secondary.normal.first};
  & > article {
    padding: 0.5rem;
    color: ${colors.secondary.normal.first};
    background-color: ${rgba(colors.secondary.normal.first, 0.1)};
    border-radius: 1px;
  }
`;

export const PaymentEntry = styled.section`
  width: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 1px;
  border: 1px solid ${colors.primary.normal.first};
  & > article {
    padding: 0.5rem;
    color: ${colors.primary.normal.first};
    background-color: ${rgba(colors.primary.normal.first, 0.1)};
    border-radius: 1px;
  }
`;

export const Installments = styled.section`
  width: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 1px;
  border: 1px solid ${colors.primary.normal.first};
  & > article {
    padding: 0.5rem;
    color: ${colors.primary.normal.first};
    background-color: ${rgba(colors.primary.normal.first, 0.1)};
    border-radius: 1px;
  }
`;

export const AmortizationType = styled.section`
  width: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 1px;
  border: 1px solid ${colors.secondary.normal.first};
  & > article {
    padding: 0.5rem;
    color: ${colors.secondary.normal.first};
    background-color: ${rgba(colors.secondary.normal.first, 0.1)};
    border-radius: 1px;
  }
`;

export const AverageRate = styled.section`
  width: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 1px;
  border: 1px solid ${colors.secondary.normal.first};
  & > article {
    padding: 0.5rem;
    color: ${colors.secondary.normal.first};
    background-color: ${rgba(colors.secondary.normal.first, 0.1)};
    border-radius: 1px;
  }
`;
