import styled from 'styled-components';
import colors from '../../../../../../../../../../../../../../../styles/colors';

export const Container = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
`;

export const PresentationSection = styled.article`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;

export const IconContainer = styled.section`
  width: 3.75rem;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  & > article {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.primary.normal.first};
    border-radius: 0.813rem;
    width: 3.75rem;
    height: 3.75rem;
    color: #fff;
    & > svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  @media screen and (max-width: 768px) {
    align-items: center;
  }
`;

export const ClientInformationContainer = styled.article`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;

  & > span {
    width: 100%;
    height: 100%;
  }

  & > h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #a6acbe;
    text-transform: capitalize;
  }
  & > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.313rem;
    display: flex;
    align-items: center;
    color: #363c4c;
  }
  & > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.188rem;
    color: #a6acbe;
    & > p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #363c4c;
    }
  }
  @media screen and (max-width: 768px) {
    & > h1 {
      white-space: pre-wrap;
    }
  }
`;

export const ContactInformationContainer = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  & > article {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    & > span {
      width: 100%;
    }
    & > svg {
      width: 1rem;
      height: 1rem;
      color: ${colors.primary.normal.first};
    }
    & > p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
`;

export const DateContainer = styled.p`
  position: absolute;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.797rem;
  line-height: 1.625rem;
  top: 0;
  right: 0;
`;
