import styled from 'styled-components';

export const ProductsBox = styled.div`
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  overflow-y: scroll;

  @media screen and (max-width: 960px) {
    margin: 25px 25px 0 25px;
  }
  @media screen and (max-width: 822px) {
    justify-content: space-between;
    margin: 25px 0px 0px 0px;
  }
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  @media screen and (max-width: 960px){
    flex-direction: column;
    align-items: center;
  }
`;
