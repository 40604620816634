import React, { ReactNode } from 'react';

import { Container } from './style';

interface ICardContainerProps {
  children: ReactNode;
}

function CardContainer({ children }: ICardContainerProps): JSX.Element {
  return <Container>{children}</Container>;
}

export { CardContainer };
