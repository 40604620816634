import { gql } from '@apollo/client';

export interface createCompanyMutationVars {
  cpf_cnpj: string;
  email: string;
  name: string;
  password: string;
  personName: string;
  phone: string;
  token: string;
}

export interface createCompanyMutationData {
  name: string;
}

export interface createCompanyMutationResponse {
  createCompany: createCompanyMutationData;
}

const CREATE_COMPANY = gql`
  mutation createCompany(
    $cpf_cnpj: String
    $email: String!
    $name: String!
    $password: String!
    $personName: String!
    $phone: String!
    $token: String!
  ) {
    createCompany(
      data: {
        cpf_cnpj: $cpf_cnpj
        email: $email
        name: $name
        password: $password
        personName: $personName
        phone: $phone
        token: $token
      }
    ) {
      name
    }
  }
`;

export default CREATE_COMPANY;
