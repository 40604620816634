import React, { ReactNode, useRef } from 'react';
import ScrollToRightButton from './components/ScrollToRightButton';
import ScrollToLeftButton from './components/ScrollToLeftButton';

import { Container } from './style';

interface ScrollDivProps {
  children: ReactNode;
}

function ScrollDiv({ children }: ScrollDivProps): JSX.Element {
  const reference = useRef(null);
  return (
    <Container
      className="fourth-step"
      hideScrollbars={false}
      innerRef={reference}
    >
      {children}
      <ScrollToRightButton reference={reference} />
      <ScrollToLeftButton reference={reference} />
    </Container>
  );
}

export default ScrollDiv;
