import { ApolloError, useLazyQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import GET_DEAL_CATEGORY_STAGE, {
  DealCategoryStageData,
  GetDealCategoryStagesQueryResponse,
  GetDealCategoryStagesQueryVars,
} from '../GraphQL/queries/getDealsCategoryStages';
import { useDealsCategories } from './DealsCategories';

interface DealsStagesContextData {
  dealsStages: DealCategoryStageData[];
  isGetDealCategoryStagesQueryLoading: boolean;
}

const DealsStagesContext = createContext<DealsStagesContextData>(
  {} as DealsStagesContextData,
);

interface DealsStagesProviderProps {
  children: ReactNode;
}

export function DealsStagesProvider({
  children,
}: DealsStagesProviderProps): JSX.Element {
  const [dealsStages, setDealsStages] = useState<DealCategoryStageData[]>(
    [] as DealCategoryStageData[],
  );
  const [
    currentDealCategoryIndex,
    setCurrentDealCategoryIndex,
  ] = useState<number>(0);
  const { dealsCategories } = useDealsCategories();
  const handleOnGetDealCategoryStagesQueryCompleted = useCallback(
    ({
      getDealCategoryStages: fetchedStages,
    }: GetDealCategoryStagesQueryResponse) => {
      setDealsStages([...dealsStages, ...fetchedStages]);
      if (currentDealCategoryIndex + 1 < dealsCategories.length) {
        setCurrentDealCategoryIndex(currentDealCategoryIndex + 1);
      }
    },
    [currentDealCategoryIndex, dealsCategories, dealsStages],
  );
  const handleOnGetDealCategoryStagesError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(
        `Não foi possível obter todas as categories de negócios: ${message}`,
      );
    },
    [],
  );
  const [
    getDealCategoryStages,
    { loading: isGetDealCategoryStagesQueryLoading },
  ] = useLazyQuery<
    GetDealCategoryStagesQueryResponse,
    GetDealCategoryStagesQueryVars
  >(GET_DEAL_CATEGORY_STAGE, {
    onCompleted: handleOnGetDealCategoryStagesQueryCompleted,
    onError: handleOnGetDealCategoryStagesError,
  });

  useEffect(() => {
    if (currentDealCategoryIndex < dealsCategories.length) {
      const dealCategory = dealsCategories[currentDealCategoryIndex];
      getDealCategoryStages({
        variables: {
          dealCategoryID: dealCategory.id,
        },
      });
    }
  }, [currentDealCategoryIndex, dealsCategories, getDealCategoryStages]);
  return (
    <DealsStagesContext.Provider
      value={{
        dealsStages,
        isGetDealCategoryStagesQueryLoading,
      }}
    >
      {children}
    </DealsStagesContext.Provider>
  );
}

export function useDealsStages(): DealsStagesContextData {
  const context = useContext(DealsStagesContext);
  if (!context) {
    throw new Error('useDealsStage must be used within a DealsStagesProvider');
  }
  return context;
}
