import { ApolloError, useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import InputNumberLight from '../../../../../../components/InputNumber/InputNumberLight';
import EDIT_DEAL_PRODUCT, {
  EditDealProductMutationResponse,
  EditDealProductMutationVars,
} from '../../../../../../GraphQL/mutations/editDealProduct';
import { DealProductData } from '../../../../../../GraphQL/queries/getDealsProducts';
import { useDealsProducts } from '../../../../../../hooks/DealsProducts';
import colors from '../../../../../../styles/colors';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import DeleteDealProductButton from './DeleteDealProductButton';

import { Container, InputSection, ButtonsSection } from './style';

interface DealProductEditFormProps {
  dealProduct: DealProductData;
}

function DealProductEditForm({
  dealProduct,
}: DealProductEditFormProps): JSX.Element {
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    formRef.current?.setData({
      averageRate: dealProduct.averageRate,
      competitiveRate: dealProduct.competitiveRate,
      maxNumberOfInstallments: dealProduct.maxNumberOfInstallments,
    });
  }, [dealProduct]);

  const { dealsProducts, setDealsProducts } = useDealsProducts();

  const handleOnEditDealProductMutationCompleted = useCallback(
    ({
      editDealProduct: editedDealProduct,
    }: EditDealProductMutationResponse) => {
      const filteredDealsProducts = dealsProducts.filter(
        (someDealProduct) => someDealProduct.id !== editedDealProduct.id,
      );
      setDealsProducts([...filteredDealsProducts, editedDealProduct]);
      toast.success(`${editedDealProduct.name} alterado com sucesso`);
    },
    [dealsProducts, setDealsProducts],
  );

  const handleOnEditDealProductMutationError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(`Não foi possível editar o produto: ${message}`);
    },
    [],
  );

  const [
    editDealProduct,
    { loading: isEditDealCategoryMutationLoading },
  ] = useMutation<EditDealProductMutationResponse, EditDealProductMutationVars>(
    EDIT_DEAL_PRODUCT,
    {
      onCompleted: handleOnEditDealProductMutationCompleted,
      onError: handleOnEditDealProductMutationError,
    },
  );

  const handleOnSubmit = useCallback(
    async (data: Omit<EditDealProductMutationVars, 'id'>) => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string(),
          averageRate: Yup.number()
            .required('Você precisa informar o valor da taxa média')
            .typeError('É necessário fornecer um valor numérico')
            .positive('Por favor, insira um valor positivo'),
          competitiveRate: Yup.number()
            .required('Você precisa informar o valor da taxa competitiva')
            .typeError('É necessário fornecer um valor numérico')
            .positive('Por favor, insira um valor positivo'),
          maxNumberOfInstallments: Yup.number().required(
            'Você precisa informar o número máximo de parcelas',
          ),
        });
        await schema.validate(data, { abortEarly: false });
        await editDealProduct({
          variables: {
            ...data,
            averageRate: Number(data.averageRate),
            competitiveRate: Number(data.competitiveRate),
            maxNumberOfInstallments: Number(data.maxNumberOfInstallments),
            id: dealProduct.id,
            name: dealProduct.name,
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          toast.warning('Por favor, preencha os dados corretamente');
          return;
        }
        toast.warning(
          'Houve um erro ao editar o produto. Por favor, tente novamente mais tarde.',
        );
      }
    },
    [dealProduct.id, editDealProduct, dealProduct.name],
  );

  return (
    <Container ref={formRef} onSubmit={handleOnSubmit}>
      <h2>{dealProduct.name}</h2>
      <InputSection>
        <InputNumberLight name="averageRate" label="Taxa Média (%)" />
        <InputNumberLight name="competitiveRate" label="Taxa Competitiva (%)" />
        <InputNumberLight
          name="maxNumberOfInstallments"
          label="Max. de Parcelas"
        />
      </InputSection>
      <ButtonsSection>
        <Button
          style={{ color: colors.primary.normal.first }}
          variant="outlined"
          type="submit"
        >
          {`${isEditDealCategoryMutationLoading ? 'Salvando' : 'Salvar'}`}
        </Button>
        <DeleteDealProductButton dealProduct={dealProduct} />
      </ButtonsSection>
    </Container>
  );
}

export default DealProductEditForm;
