import { ApolloError, useLazyQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  IGetCompaniesQueryResponse,
  listCompaniesQuery
} from '../GraphQL/queries/getCompanies';
import { ICompany } from '../GraphQL/queries/getCompany';
import { useAuth } from './Auth';
import { useCompany } from './Company';

interface ICompaniesContext {
  companies: ICompany[];
  setCompanies(companies: ICompany[]): void;
  loading: boolean;
  newAccountsThisMonth: number;
  accountsSuspendedThisMonth: number;
}

const listCompaniesContext = createContext<ICompaniesContext>(
  {} as ICompaniesContext,
);

interface ICompaniesResolver {
  children: ReactNode;
}

export function CompaniesProvider({
  children,
}: ICompaniesResolver): JSX.Element {
  const [companies, setCompanies] = useState<ICompany[]>([] as ICompany[]);
  const [newAccountsThisMonth, setNewAccountsThisMonth] = useState(0);
  const [accountsSuspendedThisMonth, setAccountsSuspendedThisMonth] = useState(
    0,
  );
  const { isCompanyLogged } = useAuth();
  const { isAdmin } = useCompany();
  const handleOnGetCompaniesQueryCompleted = useCallback(
    ({ listCompanies }: IGetCompaniesQueryResponse) => {
      setCompanies(listCompanies);
    },
    [],
  );
  const handleOnGetCompaniesQueryError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ message }: ApolloError) => {
      /* toast.warn(`Não foi possível obter os dados do usuário: ${message}`); */
    },
    [],
  );
  const [listCompanies, { loading }] = useLazyQuery<IGetCompaniesQueryResponse>(
    listCompaniesQuery,
    {
      onCompleted: handleOnGetCompaniesQueryCompleted,
      onError: handleOnGetCompaniesQueryError,
    },
  );

  useEffect(() => {
    if (isCompanyLogged && isAdmin) {
      listCompanies();
    }
  }, [isCompanyLogged, isAdmin, listCompanies]);

  useEffect(() => {
    if (companies.length) {
      const filteredByMonth = companies.filter(
        (company) =>
          new Date(company.createdAt).getMonth() === new Date().getMonth(),
      );
      const suspendedTotalAccounts = companies.filter(
        (company) => company.isSuspended,
      );
      const filteredSuspendedThisMonth = suspendedTotalAccounts.filter(
        (company) =>
          new Date(company.updatedAt).getMonth() === new Date().getMonth(),
      );
      setAccountsSuspendedThisMonth(filteredSuspendedThisMonth.length);
      setNewAccountsThisMonth(filteredByMonth.length);
    }
  }, [companies]);

  useEffect(() => {
    const localStorageCompanyName = '@LCC:companies';
    localStorage.removeItem(localStorageCompanyName);
  }, []);

  return (
    <listCompaniesContext.Provider
      value={{
        companies,
        newAccountsThisMonth,
        accountsSuspendedThisMonth,
        loading,
        setCompanies,
      }}
    >
      {children}
    </listCompaniesContext.Provider>
  );
}

export function useCompanies(): ICompaniesContext {
  const context = useContext(listCompaniesContext);
  if (!context) {
    throw new Error('useCompany must be used within a CompaniesProvider');
  }
  return context;
}
