import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Container = styled.section`
  width: 30rem;
  height: 30rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;

  padding: 1rem 2rem;

  background-color: #fff;

  border: 1px solid #9caab9;
  box-sizing: border-box;
  /* Shadow */

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  & > img {
    height: 124px;
    @media screen and (max-width: 768px) {
      height: 60px;
    }
  }

  & > h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: ${colors.text.fourth};
    white-space: nowrap;
  }

  & > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${colors.text.second};
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: min-content;
    & > img {
      height: 60px;
    }
  }
`;
