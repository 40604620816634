import {
  PaletteColorOptions,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { palette } from '../styles/palette';
import { colorsTheme } from '../styles/Theme';

interface IColorTheme {
  [key: string]: PaletteColorOptions;
}

interface IThemeContextData {
  colorTheme: IColorTheme;
  setColorTheme(colorTheme: IColorTheme): void;
}

const ThemeContext = createContext<IThemeContextData>({} as IThemeContextData);

interface IThemeContextProps {
  children: ReactNode;
}

export function ThemeProvider({ children }: IThemeContextProps): JSX.Element {
  const [colorTheme, setColorTheme] = useState<IColorTheme>(palette);

  const companyTheme = createMuiTheme({
    palette: colorsTheme,
  });

  return (
    <ThemeContext.Provider
      value={{
        colorTheme,
        setColorTheme,
      }}
    >
      <StyledThemeProvider theme={colorTheme}>
        <MaterialThemeProvider theme={companyTheme}>
          {children}
        </MaterialThemeProvider>
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
}

export function useTheme(): IThemeContextData {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}
