import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';
import colors from '../../../../../../styles/colors';

export const Container = styled.div`
  flex-shrink: 0;

  width: 100%;
  max-width: 265px;

  display: flex;
  flex-direction: column;
  border: 0.5px solid #a6acbe;
  border-radius: 10px;
`;

export const Header = styled.div`
  width: 100%;
  height: min-content;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  text-align: start;

  & > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: ${colors.text.fourth};
  }

  & > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: ${colors.primary.normal.first};
  }
  padding: 1rem;
`;

export const Section = styled(ScrollContainer)`
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0; /*here the height is set to 0px*/
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
