import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Content = styled.article`
  flex: 1 1 auto;
  height: 0px;
  min-width: 0;

  overflow: auto;
`;
