import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: min-content;
  display: grid;

  align-items: center;
  justify-items: stretch;
  justify-content: stretch;

  grid-auto-flow: column;
  grid-template-columns: min-content min-content 1fr min-content;
  grid-template-areas: 'burger title children avatar';

  gap: 1rem;
  padding: 1rem 0;

  @media screen and (max-width: 768px) {
    grid-template-columns: min-content min-content 1fr;
    grid-template-rows: repeat(2, min-content);
    grid-template-areas: 'burger title avatar' 'children children children';
  }
`;

export const Title = styled.h1`
  grid-area: title;
  justify-self: start;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;

  white-space: nowrap;
`;

export const Article = styled.article`
  grid-area: children;
  justify-self: stretch;
  align-self: center;
  @media screen and (max-width: 768px) {
    & > button {
      width: 100%;
    }
  }
`;
