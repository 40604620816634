import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.section`
  width: 100%;
  min-height: 100vh;
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  gap: 1rem;

  background-color: ${colors.background.first};
  padding: 1rem 1rem 0 1rem;
`;

export const CardsContainer = styled.section`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
