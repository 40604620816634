import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  max-width: 900px;
  width: 90%;
  margin: 0 auto;
  height: 650px;
  user-select: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #fff;
  border: 0.5px solid #ebebf9;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  & > h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    /* or 127% */
    /* Text/Text Darker */
    color: #071620;
  }
  & > p {
    margin-top: 1rem;
    text-align: justify;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    /* or 133% */
    /* Text/Text Dark */
    color: #363c4c;
  }
  @media screen and (max-width: 415px){
    height: 500px;
  }
`;

export const ButtonOutlined = styled.button`
  padding: 7px 14px;
  font-size: 16px;
  border: 1px solid ${colors.primary.normal.first};
  box-sizing: border-box;
  border-radius: 50%;
  background: #fff;
  color: ${colors.primary.normal.first};
  cursor: pointer;
  align-self: flex-end;
  margin-top: 1rem;
`;
