import { gql } from '@apollo/client';
import { DealProductData } from '../queries/getDealsProducts';

export interface EditDealProductMutationVars {
  id: string;
  name: string;
  averageRate: number;
  competitiveRate: number;
  maxNumberOfInstallments: number;
}

export interface EditDealProductMutationResponse {
  editDealProduct: DealProductData;
}

const EDIT_DEAL_PRODUCT = gql`
  mutation editDealProduct(
    $id: String!
    $name: String
    $averageRate: Float!
    $competitiveRate: Float!
    $maxNumberOfInstallments: Int!
  ) {
    editDealProduct(
      data: {
        id: $id
        name: $name
        averageRate: $averageRate
        competitiveRate: $competitiveRate
        maxNumberOfInstallments: $maxNumberOfInstallments
      }
    ){
      id
      name
      averageRate
      competitiveRate
      maxNumberOfInstallments
      bitrix_id
      dealCategory{
        id
        name
      }
    }
  }
`;

export default EDIT_DEAL_PRODUCT;
