import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: stretch;
`;

export const Aside = styled.aside`
  display: flex;
  width: min-content;
`;

export const Section = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${colors.background.second};
  & > section:first-child {
    padding: 1rem;
  }
  & > section:nth-child(2) {
    padding: 0 1rem;
    overflow-y: auto;
    min-height: 0;
  }
  padding-bottom: 1rem;
`;
