/* eslint-disable no-nested-ternary */
import React from 'react';
import { SimulationData } from '../../../../../../GraphQL/mutations/createSimulation';
import SimulationTable from './SimulationTable';
import formatToPTBRMoneyValue from '../../../../../../utils/formatMoney';

import {
  Container,
  TitleSection,
  InfoSection,
  Info,
  ResultSection,
  Amount,
  Installments,
  AmortizationType,
  AverageRate,
  PaymentEntry,
} from './style';

interface SimulationVisualizationProps {
  simulation: SimulationData;
}

function SimulationVisualization({
  simulation,
}: SimulationVisualizationProps): JSX.Element {
  return (
    <Container>
      <TitleSection>Dados do cliente</TitleSection>
      <InfoSection>
        <Info>
          <h1>Nome</h1>
          <h2>{simulation.name}</h2>
        </Info>
        <Info>
          <h1>E-mail</h1>
          <h2>{simulation.email}</h2>
        </Info>
        <Info>
          <h1>Telefone</h1>
          <h2>{simulation.phone}</h2>
        </Info>
      </InfoSection>
      <TitleSection>
        Resultado da Simulação{' '}
        {simulation.amortizationType === 0 || simulation.amortizationType === 1
          ? 'do Imóvel'
          : 'Veicular'}
      </TitleSection>
      <ResultSection>
        <Amount>
          <article>
            Valor do{' '}
            {simulation.amortizationType === 0 ||
            simulation.amortizationType === 1
              ? 'Imóvel'
              : 'Veículo'}
            :
          </article>
          <p>{formatToPTBRMoneyValue(simulation.value)}</p>
        </Amount>
        <PaymentEntry>
          <article>Entrada:</article>
          <p>{formatToPTBRMoneyValue(simulation.paymentEntry)}</p>
        </PaymentEntry>
        <AmortizationType>
          <article>Tabela: </article>
          <p>{`${
            simulation.amortizationType === 0
              ? 'SAC'
              : simulation.amortizationType === 1
              ? 'PRICE'
              : 'Padrão'
          }`}</p>
        </AmortizationType>
        <Installments>
          <article>Prazo:</article>
          <p>{`${simulation.numberOfInstallments} meses`}</p>
        </Installments>
        <AverageRate>
          <article>Taxa Média: </article>
          <p>{`${simulation.averageRate}%`}</p>
        </AverageRate>
      </ResultSection>
      <SimulationTable simulation={simulation} />
    </Container>
  );
}

export default SimulationVisualization;
