import { gql } from '@apollo/client';

export interface IGetCompanyDealsQueryVars {
  dealCategoryID?: string;
  page?: number;
}

export interface IBitrixDeal {
  ID: number;
  TITLE: string;
  STAGE_ID: string;
  CURRENCY_ID: string;
  OPPORTUNITY: number;
  COMPANY_ID: number;
  CONTACT_ID: number;
  DATE_CREATE: string;
  DATE_MODIFY: string;
  COMMENTS: string;
  ADDITIONAL_INFO: string;
}

export interface IGetCompanyDealsQueryData {
  result: IBitrixDeal[];
  total: number;
  next?: number;
}

export interface IGetCompanyDealsQueryResponse {
  getCompanyDeals: IGetCompanyDealsQueryData;
}

export const getCompanyDealsQuery = gql`
  query getCompanyDeals($page: Int, $dealCategoryID: String) {
    getCompanyDeals(data: { dealCategoryID: $dealCategoryID, page: $page }) {
      next
      total
      result {
        ID
        TITLE
        STAGE_ID
        CURRENCY_ID
        OPPORTUNITY
        COMPANY_ID
        CONTACT_ID
        DATE_CREATE
        DATE_MODIFY
        COMMENTS
        ADDITIONAL_INFO
      }
    }
  }
`;
