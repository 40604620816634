import React from 'react';
import Navbar from '../../templates/DashboardTemplate/Navbar';
import CreateSimulationButton from './components/CreateSimulationButton';
import SimulationsTable from './components/SimulationsTable';
import { SimulationProvider } from './hooks/simulation';

import { Container, Content } from './style';

function Simulation(): JSX.Element {
  return (
    <SimulationProvider>
      <Navbar title="Simulação de Crédito">
        <CreateSimulationButton />
      </Navbar>
      <Container>
        <Content>
          <h2>Histórico de Simulações</h2>
          <SimulationsTable />
        </Content>
      </Container>
    </SimulationProvider>
  );
}

export default Simulation;
