import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DealCategoryData } from '../../../GraphQL/queries/getDealsCategories';
import { DealCategoryStageData } from '../../../GraphQL/queries/getDealsCategoryStages';
import { BitrixDealData } from '../../../GraphQL/queries/getDealsUpdated';
import { useDeals } from '../../../hooks/Deals';
import { useDealsCategories } from '../../../hooks/DealsCategories';
import { useDealsStages } from '../../../hooks/DealsStages';

interface BusinessContextData {
  selectedDealCategory: DealCategoryData;
  setSelectedDealCategory(dealCategory: DealCategoryData): void;
  deals: BitrixDealData[];
  dealsStages: DealCategoryStageData[];
}

const BusinessContext = createContext<BusinessContextData>(
  {} as BusinessContextData,
);

interface BusinessProviderProps {
  children: ReactNode;
}

export function BusinessProvider({
  children,
}: BusinessProviderProps): JSX.Element {
  const [
    selectedDealCategory,
    setSelectedDealCategory,
  ] = useState<DealCategoryData>({} as DealCategoryData);
  const { deals } = useDeals();
  const { dealsStages } = useDealsStages();
  const { dealsCategories } = useDealsCategories();
  const [filteredDeals, setFilteredDeals] = useState<BitrixDealData[]>(
    [] as BitrixDealData[],
  );
  const [filteredDealsStages, setFilteredDealsStages] = useState<
    DealCategoryStageData[]
  >([] as DealCategoryStageData[]);

  useEffect(() => {
    setFilteredDeals(
      deals.filter((deal) =>
        deal.STAGE_ID.includes(`C${selectedDealCategory.bitrix_id}`),
      ),
    );
  }, [deals, selectedDealCategory]);

  useEffect(() => {
    setFilteredDealsStages(
      dealsStages.filter((dealStage) =>
        dealStage.STATUS_ID.includes(
          'C#:'.replace('#', String(selectedDealCategory.bitrix_id)),
        ),
      ),
    );
  }, [dealsStages, selectedDealCategory]);

  useEffect(() => {
    if (dealsCategories.length > 0) {
      setSelectedDealCategory(dealsCategories[0]);
    }
  }, [dealsCategories, setSelectedDealCategory]);

  return (
    <BusinessContext.Provider
      value={{
        deals: filteredDeals,
        selectedDealCategory,
        setSelectedDealCategory,
        dealsStages: filteredDealsStages,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
}

export function useBusiness(): BusinessContextData {
  const context = useContext(BusinessContext);
  if (!context) {
    throw new Error('UseBusiness must be used within a BusinessProvider');
  }
  return context;
}
