import { ApolloError, useMutation } from '@apollo/client';
import { ChevronRight } from '@material-ui/icons';
import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Logo from '../../assets/logo.color.light.svg';
import Button from '../../components/Button';
import Input from '../../components/Input';
import RECOVER_PASSWORD, {
  RecoverPasswordMutationResponse,
  RecoverPasswordMutationVars,
} from '../../GraphQL/mutations/recoverPassword';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  Container,
  Content,
  PresentationContainer,
} from '../RecoverPasswordRequest/style';

interface RouteParams {
  token: string;
}

interface FormData {
  password: string;
  confirmPassword: string;
}

function RecoverPassword(): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { token } = useParams<RouteParams>();
  const handleOnRecoverPasswordError = useCallback((error: ApolloError) => {
    toast.warning(
      `Houve um erro ao tentar alterar a sua senha: ${error.message}. Por favor, tente refazer o procedimento`,
    );
  }, []);

  const handleOnRecoverPasswordCompleted = useCallback(() => {
    toast.success('Senha alterada com sucesso');
    history.push('/login');
  }, [history]);

  const [recoverPassword, { loading }] = useMutation<
    RecoverPasswordMutationResponse,
    RecoverPasswordMutationVars
  >(RECOVER_PASSWORD, {
    onCompleted: handleOnRecoverPasswordCompleted,
    onError: handleOnRecoverPasswordError,
  });

  const handleOnSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().min(
            8,
            'A senha deve conter pelo menos 8 caracteres',
          ),
          confirmPassword: Yup.string()
            .min(8, 'A senha deve conter pelo menos 8 caracteres')
            .oneOf([Yup.ref('password'), null], 'As senhas não coincidem'),
        });
        await schema.validate(data, { abortEarly: false });
        await recoverPassword({
          variables: { password: data.password, token },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          toast.warning('Por favor, preencha os dados corretamente');
          return;
        }
        toast.warning(
          'Houve um erro ao consultar o banco de dados. Por favor, tente novamente mais tarde.',
        );
      }
    },
    [recoverPassword, token],
  );

  return (
    <Container>
      <Content ref={formRef} onSubmit={handleOnSubmit}>
        <img src={Logo} alt="Logotipo LCC" className="logo" />

        <PresentationContainer>
          <h1>Crie uma nova senha</h1>
          <h2>
            Sua nova senha precisa ser diferente das senhas utilizadas
            anteriormente
          </h2>
        </PresentationContainer>

        <Input name="password" label="Nova senha" type="password" />
        <Input
          name="confirmPassword"
          label="Confirmação da nova senha"
          type="password"
        />
        <Button fullWidth type="submit" endIcon={<ChevronRight />}>
          {`${loading ? 'Redefinindo' : 'Redefinir'} Senha`}
        </Button>
      </Content>
    </Container>
  );
}

export default RecoverPassword;
