import { Add } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import Button from '../../../../components/Button';
import CreateSimulationDialog from './CreateSimulationDialog';
import { SimulationStepsProvider } from './hooks/SimulationSteps';

function CreateSimulationButton(): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const handleOnDialogClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);
  const handleOnSimulateButtonClick = useCallback(() => {
    setIsDialogOpen(true);
  }, []);
  return (
    <>
      <Button onClick={handleOnSimulateButtonClick} endIcon={<Add />}>
        <p>Simular Crédito</p>
      </Button>
      <SimulationStepsProvider setIsDialogOpen={setIsDialogOpen}>
        <CreateSimulationDialog
          handleOnDialogClose={handleOnDialogClose}
          open={isDialogOpen}
          fullWidth
        />
      </SimulationStepsProvider>
    </>
  );
}

export default CreateSimulationButton;
