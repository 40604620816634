import React from 'react';
import { Link } from 'react-router-dom';
import PoliciesTemplate from '../../../templates/PoliciesTemplate';

function TermsAndConditions(): JSX.Element {
  return (
    <PoliciesTemplate>
      <h1>TERMOS E CONDIÇÕES DE USO</h1>
      <small>
        <strong>
          Estes Termos e Condições de Uso foram modificados pela última vez em
          24 de março de 2021.
        </strong>
      </small>
      <p>
        Estes termos e condições regulam o uso do nosso site (
        <Link to={{ pathname: '/' }}>
          https://app.lucrandocomcredito.com.br
        </Link>
        ) e aplicativo, denominados em conjunto “Plataforma”, bem como dos
        serviços e produtos disponibilizados pela LUCRANDO COM CREDITO SOLUÇÕES
        FINANCEIRAS – ME (A L P DE VASCONCELOS) (“LCC”), inscrita no CNPJ/ME sob
        o nº 39.830.139/0001-06, com sede na Av. Antônio Basílio, 2652, Lagoa
        Nova, Natal/RN, CEP 59.054-380.
      </p>
      <h4>
        AVISO: LEIA ESTES TERMOS CUIDADOSAMENTE ANTES DE CONTINUAR UTILIZANDO
        NOSSA PLATAFORMA OU DE UTILIZAR QUAISQUER DE NOSSOS SERVIÇOS.
      </h4>
      <p>
        Estes Termos de Uso (“Termos”) estão divididos em (i) Condições Gerais e
        (ii) Condições Adicionais, (iii) Anexo com informações sobre as
        Instituições Financeiras Parceiras. Em conjunto com os demais contratos
        e a nossa Política de Privacidade, estabelecem os termos e condições
        aplicáveis à utilização da nossa Plataforma.
      </p>
      <p>
        Leia este documento para compreender as regras que regem o uso do site e
        serviços da LCC.
      </p>
      <h4>ACEITAÇÃO DOS TERMOS</h4>
      <p>
        O USUÁRIO CONFIRMA QUE, AO SE CADASTRAR E UTILIZAR QUAISQUER DAS
        FUNCIONALIDADES DA PLATAFORMA, TERÁ LIDO, ENTENDIDO E ACEITADO TODOS OS
        TERMOS E CONDIÇÕES LISTADOS ABAIXO. Caso não concorde com estes Termos
        de Uso (“Termos” ou “Termo”), o usuário não poderá utilizar a
        Plataforma. A Plataforma pode conter outros avisos de propriedade e
        informações protegidas por direitos autorais, cujos termos devem ser
        observados e cumpridos.
      </p>
      <p>
        Ao aceitar as disposições dessses Termos, o usuário declara
        expressamente que domina a língua portuguesa, está familiarizado com o
        ambiente da Plataforma, possui capacidade civil, e que tem conhecimento
        suficiente dos produtos e serviços ofertados.
      </p>
      <p>
        Não coletamos ou solicitamos intencionalmente dados de identificação
        pessoal de pessoas menores de 18 anos, motivo pelo qual o usuário
        declara expressamente ter, no mínimo, (i) 18 (dezoito) anos de idade; ou
        (ii) 16 (dezesseis) anos de idade, desde que seja emancipado ou que
        esteja sob supervisão e autorização do adulto responsável, para
        prosseguir na navegação da Plataforma. Caso o usuário seja menor de
        idade ou, se tiver entre 16 (dezesseis) e 18 (dezoito) anos, não seja
        emancipado ou não esteja sob supervisão e autorização do adulto
        responsável, não deverá utilizar a Plataforma, não tente se registrar
        nos Serviços nem nos envie quaisquer dados pessoais.
      </p>
      <p>
        Se porventura uma pessoa menor de 18 anos nos tiver fornecido seus Dados
        Pessoais, entre em contato conosco em
        plataforma@app.lucrandocomcredito.com.br
      </p>
      <h4>MODO DE ATUAÇÃO DA LCC</h4>
      <p>
        Não somos instituição financeira, companhia de seguros, operadora de
        meios de pagamento ou bandeira de cartão de crédito. A LCC é uma
        sociedade prestadora de serviços, atuando enquantocorrespondente
        bancário (conforme Resolução nº 3.954, de 24 de fevereiro de 2011, do
        Conselho Monetário Nacional, conforme alterada), com o fim de facilitar
        o processo de contratação, pelos clientes e usuários da Plataforma, de
        empréstimos e/ou financiamentos (melhor descrito nos Termos e Condições
        Adicionais (mais abaixo) junto a “Instituições Financeiras Parceiras”
        (instituições financeiras e demais instituições autorizadas a funcionar
        pelo Banco Central do Brasil), com quem a LCC mantém relacionamento.
      </p>
      <h4>UTILIZAÇÃO DA PLATAFORMA</h4>
      <p>
        É possível navegar pela maior parte da Plataforma sem estar registrado
        na conta de usuário, caso em que serão aplicáveis somente as condições
        gerais abaixo descritas. Porém, para acessar determinadas áreas,
        funcionalidades, produtos e serviços da Plataforma, além da atenção
        quanto às condições gerais, o usuário também deverá confirmar
        expressamente sua anuência com os Termos e Condições Adicionais,
        presentes no Anexo I a estes Termos, antes do seu primeiro acesso.
      </p>
      <h4>PRIVACIDADE E SEGURANÇA</h4>
      <p>
        A LCC se compromete com a privacidade de seus usuários nos termos de sua
        política de privacidade, disponível em{' '}
        <Link to={{ pathname: '/sobre/privacidade' }}>
          https://app.lucrandocomcredito.com.br/sobre/privacidade
        </Link>{' '}
        (“Política de Privacidade”), que é parte integrante e indissociável
        destes Termos de Uso.
      </p>
      <p>
        O usuário não poderá compartilhará a sua conta ou senha com ninguém,
        devendo mantê-las em segurança. O usuário é responsável por qualquer
        atividade associada à sua conta, concordando em notificar imediatamente
        a LCC no caso de qualquer uso não autorizado.
      </p>
      <h4>CONDIÇÕES GERAIS</h4>
      <p>
        Dependendo do serviço contratado pelo usuário poderão ser aplicadas
        diferentes condições comerciais (“Condições Comerciais”), podendo
        inclusive serem cobrados valores diretamente do usuário. Tais Condições
        Comerciais deverão ser verificadas antes da contratação do respectivo
        serviço.
      </p>
      <p>
        Em alguns casos, poderemos receber remunerações das Instituições
        Financeiras Parceiras, anunciantes e outros parceiros para que eles
        possam ser listados na nossa Plataforma. No caso de contratação de
        serviços junto às Instituições Financeiras Parceiras, estas poderão
        cobrar taxas do usuário, previamente informadas, sem qualquer
        responsabilidade ou envolvimento da <strong>LCC</strong>.
      </p>
      <p>
        Ao utilizar a Plataforma, o usuário expressamente reconhece que a{' '}
        <strong>LCC</strong>:
      </p>
      <ul>
        <li>
          Não é responsável pelo cumprimento das normas e requisitos legais por
          parte das Instituições Financeiras Parceiras, anunciantes e outros
          parceiros indicados na Plataforma. Sendo o usuário o único responsável
          pela verificação prévia da regularidade e idoneidade desses agentes;
        </li>
        <li>
          Não atua e nem substitui consultores e assessores especializados na
          contratação de serviços financeiros, mesmo que tais serviços sejam
          indicados na Plataforma;
        </li>
        <li>
          Não é responsável pelas decisões financeiras que o usuário possa vir a
          fazer por meio da Plataforma;
        </li>
        <li>
          Não garantimos que as Instituições Financeiras Parceiras cumpram suas
          obrigações contratuais e legalmente estabelecidas;
        </li>
        <li>
          {' '}
          Não asseguramos a continuidade dos serviços ou a manutenção das
          ofertas e cotações, sem prejuízo da boa conclusão dos serviços já
          iniciados;
        </li>
        <li>
          Não atuamos em nome do usuário, em nome das Instituições Financeiras
          Parceiras ou de quaisquer terceiros, mas somente disponibilizamos uma
          plataforma para facilitar a contratação de produtos financeiros
          oferecidos pelas Instituições Financeiras Parceiras; e
        </li>
        <li>
          Não prometemos qualquer forma de rentabilidade ou de previsibilidade
          de taxas, tarifas, ganhos, lucros, perdas ou condições de mercado.
        </li>
      </ul>
      <h4>DISPONIBILIDADE DA PLATAFORMA</h4>
      <p>
        A <strong>LCC</strong> poderá alterar o conteúdo da Plataforma a
        qualquer momento, sem prévio aviso, a fim de mantê-lo atualizado e
        completo, livre de quaisquer defeitos ou vírus. Além disso, iremos
        empreender nossos melhores esforços para garantir a maior
        disponibilidade possível da Plataforma e de seus serviços.
      </p>
      <p>
        O acesso à Plataforma pode ser interrompido, suspenso ou ficar
        intermitente temporariamente, sem qualquer aviso prévio, em caso de
        falha de sistema, manutenção, atualização, alteração de sistemas, ou por
        motivos que escapem ao nosso controle. No entanto, não somos
        responsáveis por qualquer indisponibilidade da Plataforma ou de seus
        serviços, por qualquer período ou momento.
      </p>
      <p>
        A LCC não poderá decidir interromper permanentemente o acesso à
        Plataforma e os serviços ofertados. Nesta hipótese, porém, tomaremos
        providências para que os serviços já iniciados tenham conclusão ou sejam
        adequadamente migrados, sem prejuízos às partes envolvidas.
      </p>
      <h4>CONDUTA DOS USUÁRIOS E SANÇÕES</h4>
      <p>O usuário concorda em não utilizar a Plataforma para:</p>
      <ul>
        <li>
          Interferir ou interromper a Plataforma, servidores de computador ou
          placas acessíveis por meio do <em>website</em>;
        </li>
        <li>
          Assumir a identidade de qualquer pessoa ou falsamente declarar ou
          adulterar sua afiliação a uma pessoa ou entidade;
        </li>
        <li>
          Violar os direitos de qualquer terceiro, incluindo, entre outros,
          direitos de propriedade intelectual, direitos contratuais e de
          privacidade ou direitos de publicidade;
        </li>
        <li>
          Violar ou solicitar a violação de qualquer lei ou regulamento local,
          estadual ou nacional aplicável;
        </li>
        <li>
          Intencionalmente, buscar informações, obter cópias ou modificar
          arquivos ou outros dados ou senhas que pertencem a outros usuários sem
          permissão;
        </li>
        <li>
          Coletar nomes, endereços de e-mail ou outra informação pessoal de
          outros usuários para qualquer propósito;
        </li>
        <li>
          Violar o sigilo dos dados em relação a terceiros, sem utilizá-la de um
          modo individual;
        </li>
        <li>
          Permitir fragilidades à Plataforma com equipamentos incompatíveis,
          serviço de conexão à Internet sem antivírus e <em>firewall</em>;
        </li>
        <li>Realizar ou permitir que realize engenharia reversa;</li>
        <li>
          Injuriar, caluniar, abusar, molestar, perseguir, ameaçar ou violar os
          direitos (tais como direitos de privacidade e publicidade) de
          terceiros;
        </li>
        <li>
          Fazer uso de linguagem preconceituosa difamatória ou linguagem
          ofensiva;
        </li>
        <li>
          Fazer uso de linguagem explícita/obscena ou solicitar/postar imagens
          sexualmente explícitas (reais ou simuladas);
        </li>
        <li>
          Discutir ou incitar atividade ilegal ou a prática de crimes de
          qualquer natureza;
        </li>
        <li>
          Promover informação que saiba ser falsa ou enganosa ou que promova ou
          facilite atividades ilegais, pirataria ou conduta abusiva, ameaçadora,
          obscena, difamatória ou acusatória;
        </li>
        <li>Praticar qualquer tipo de fraude; e</li>
        <li>
          Envolver-se ou facilitar a transmissão em massa não solicitada de
          e-mails ou spams.
        </li>
      </ul>
      <p>
        Caso p usuário utilize a Plataforma para cometer qualquer dos atos
        acima, a LCC poderá, a seu exclusivo critério, cumulativamente (quando
        aplicável):
      </p>
      <ul>
        <li>(a) Enviar advertência;</li> e/ou
        <li>
          (b) Suspender, temporária ou definitivamente, seu cadastro;
        </li>{' '}
        e/ou
        <li>(c) Cancelar definitivamente seu cadastro;</li> e/ou
        <li>(d) Suspender a prestação dos serviços;</li> e/ou
        <li>
          (e) Aplicar outra sanção razoável, conforme a natureza da infração;
        </li>{' '}
        e/ou
        <li>
          (f) Adotar as sanções legais pela configuração de delitos ou
          contravenções, ou de responsabilidade civil pelos danos causados aos
          outros usuários ou à Plataforma.
        </li>
      </ul>
      <p>As mesmas sanções acima serão aplicáveis nos casos em que:</p>
      <ul>
        <li>
          (a) O usuário não cumprir qualquer dispositivo destes Termos e/ou
          qualquer política estabelecida e divulgada previamente pela{' '}
          <strong>LCC</strong>;
        </li>
        <li>
          (b) Descumprir qualquer obrigação atribuível ao usuário por meio
          destes Termos;
        </li>
        <li>
          (c) Não for possível verificar a identidade ou se qualquer informação
          fornecida pelo usuário for constatada, pela <strong>LCC</strong>,
          incorreta ou incompleta;
        </li>
        <li>
          (d) A <strong>LCC</strong> entender que os anúncios ou qualquer
          atitude do usuário tenha causado quaisquer danos a terceiros ou à
          própria <strong>LCC</strong>, ou tenham a potencialidade de fazê-lo;
          ou
        </li>
        <li>
          (e) A <strong>LCC</strong> suspeitar de qualquer ilegitimidade, fraude
          ou ato contrário às disposições destes Termos.
        </li>
      </ul>
      <h4>PROPRIEDADE INTELECTUAL</h4>
      <p>
        O uso comercial do nome, dos desenhos e da expressão “
        <strong>Lucrando com Crédito</strong>” e “<strong>LCC</strong>” como
        nome empresarial, marca, ou nome de domínio, bem como os conteúdos das
        telas relativas aos nossos serviços assim como os infográficos, artigos,
        colunas, widgets, programas, bancos de dados, documentos e demais
        utilidades e aplicações que permitem ao usuário acessar e usar sua conta
        de usuário são de propriedade da <strong>LCC</strong> e estão protegidos
        por todas as leis e tratados aplicáveis.
      </p>
      <p>
        O Usuário reconhece que a Plataforma é protegida por leis e tratados
        internacionais relativos à propriedade intelectual. A organização, a
        estrutura, a sequência, a lógica, os fluxogramas, o código objeto e o
        código fonte da Plataforma constituem e/ou incorporam segredo de
        empresa, direitos autorais e/ou outros direitos de propriedade
        intelectual exclusivos e não publicados da <strong>LCC</strong>, que se
        reserva e detém todos os direitos, títulos e interesses a eles
        relacionados.
      </p>
      <p>
        Não é permitido o uso comercial de qualquer marca, patente, direito
        autoral, segredo de negócio, software, informação confidencial ou
        qualquer outro direito de propriedade intelectual, registrado ou não, de
        titularidade da <strong>LCC</strong> ou de terceiro que a tenha
        licenciado, sem a prévia e expressa autorização da <strong>LCC</strong>.
      </p>
      <p>
        Igualmente, não é permitida a reprodução total ou parcial, para fins
        comerciais, de quaisquer marcas ou quaisquer outros direitos de
        propriedade intelectual da <strong>LCC</strong> ou de terceiro que a
        tenha licenciado, sem a sua autorização prévia e expressa.
      </p>
      <p>
        Qualquer material disponibilizado na Plataforma é protegido por direitos
        de propriedade intelectual e poderá ser utilizado, caso haja autorização
        prévia e expressa da <strong>LCC</strong>, apenas para fins de
        informação, desde que não possuam quaisquer propósitos comerciais.
      </p>
      <p>Não é permitida:</p>
      <ul>
        <li>
          A comercialização de qualquer informação, material, software, produto
          ou serviço da <strong>LCC</strong>;
        </li>
        <li>
          A modificação, cópia, distribuição, transmissão, reprodução,
          publicação e criação de trabalhos ou materiais derivados da
          Plataforma; e
        </li>
        <li>
          A utilização da Plataforma com propósitos imorais, ilegais, fora dos
          Termos de Uso da Plataforma ou com qualquer outro propósito que viole
          a legislação vigente.
        </li>
      </ul>
      <h4>RECURSOS DE TERCEIROS E WEBSITES </h4>
      <p>
        Em virtude das parcerias ou por se acreditar que possa ser do interesse
        do usuário, a <strong>LCC</strong> poderá conectar junto a nossa
        Plataforma, a nosso exclusivo critério, outros websites e
        funcionalidades da internet, sem que isso signifique que esses websites
        sejam de propriedade ou operados pela <strong>LCC</strong>.
      </p>
      <p>
        A <strong>LCC</strong> não tem nenhum controle sobre tais websites,
        portanto, não será responsável ou obrigada, direta ou indiretamente, por
        qualquer dano ou perda causada ou alegada, com relação ao uso ou
        confiança em quaisquer de tais conteúdos, bens ou website disponíveis em
        ou por meio de qualquer tal website ou recurso.
      </p>
      <h4>LIMITAÇÃO DE RESPONSABILIDADE</h4>
      <p>
        O usuário reconhece que a <strong>LCC</strong> se limita à prestação dos
        serviços informados nestes Termos, não sendo responsável por quaisquer
        obrigações fiscais, tributárias, trabalhistas, consumeristas ou de
        qualquer outra natureza referentes às transações realizadas por meio da
        Plataforma com as Instituições Financeiras Parceiras e outros terceiros.
      </p>
      <p>
        Na medida máxima permitida pela legislação aplicável, a LCC também não
        será responsável por eventuais prejuízos, perdas ou danos, de qualquer
        espécie, causados por terceiros.
      </p>
      <p>
        A LCC se exime de toda e qualquer responsabilidade, perante o usuário e
        terceiros, decorrente da utilização da Plataforma, incluindo, sem
        limitação, a realização de transações consideradas ilícitas, ou que
        infrinjam direitos de terceiros e/ou dados pessoais de terceiros
        utilizados sem autorização do seu respectivo titular
      </p>
      <h4>ALTERAÇÃO DOS TERMOS</h4>
      <p>
        A <strong>LCC</strong> está em constante busca por melhorarias em nossos
        Serviços e, assim, pode ser necessário alterar estes Termos de Uso
        juntamente com os Serviços, motivo pelo qual se reserva no direito de
        promover tais modificações a qualquer tempo. Em caso de alteração
        substancial, será o Usuário devidamente notificado, por meio de aviso na
        Plataforma, encaminhamento de mensagens através endereço eletrônico
        fornecido pelo próprio Usuário, de postagem chamativa na Plataforma e/ou
        por outros meios disponíveis.
      </p>
      <p>
        As novas alterações não vigorarão em relação às negociações e propostas
        iniciadas antes da publicação da nova versão dos Termos de Uso, única
        hipótese na qual será aplicável a redação anterior. Algumas condições
        específicas para certos serviços podem substituir ou complementar estes
        Termos, conforme alertado nos respectivos avisos legais disponíveis em
        outras áreas da Plataforma.
      </p>
      <p>
        A LCC sugere que o usuário consulte eventuais atualizações de tempos em
        tempos. A última versão destes Termos de Uso estará disponível na
        Plataforma em{' '}
        <Link to={{ pathname: '/sobre/termos' }}>
          https://app.lucrandocomcredito.com.br
        </Link>
      </p>
      <p>
        Caso não concorde com as alterações, o usuário deverá descontinuar o uso
        da Plataforma e seu cadastro será automaticamente cancelado pela{' '}
        <strong>LCC</strong>, de acordo com as disposições previstas nestes
        Termos. Seus dados poderão ser mantidos pela <strong>LCC</strong> caso
        haja alguma fundamentação e finalidade conforme legislação vigente.
      </p>
      <h4>GERAL</h4>
      <p>
        Estes Termos constituem o contrato integral entre o usuário e a{' '}
        <strong>LCC</strong> com respeito à Plataforma e substituem todos os
        contratos e entendimentos anteriores entre o usuário e a{' '}
        <strong>LCC</strong> para este fim.
      </p>
      <p>
        Caso a <strong>LCC</strong> deixe de exercer qualquer direito previsto
        nestes Termos, isto não deverá ser interpretado como uma renúncia,
        abdicação ou revogação de disposição aqui constante.
      </p>
      <p>
        No caso de parte destes Termos de Uso serem consideradas inexequíveis, a
        parte inexequível será ao máximo possível interpretada de acordo com a
        lei aplicável para refletir as intenções originais das partes e o
        restante das disposições permanecerá em pleno vigor e efeito.
      </p>
      <h4>LEI APLICÁVEL E FORO DE ELEIÇÃO</h4>
      <p>
        Trata-se a <strong>LCC</strong> de uma empresa brasileira, de modo que o
        Website e seus serviços são criados e mantidos em fiel cumprimento às
        leis locais e demais tratados que são incorporados ao ordenamento
        jurídico brasileiro. Caso o usuário esteja usufruindo dos serviços da{' '}
        <strong>LCC</strong> fora do Brasil, será ele o responsável pelo
        cumprimento das leis locais, na medida em que forem aplicáveis.
      </p>
      <p>
        Em caso de dificuldade de interpretação ou execução destes Termos, ou
        ainda, qualquer controvérsia relacionada ou decorrente do descumprimento
        destes Termos, tal questão será submetida ao foro da Comarca da Capital
        do Estado de Natal/RN, com a exclusão de qualquer outro, por mais
        privilegiado que seja.
      </p>
      <p>
        Para esclarecimento de quaisquer dúvidas, Você poderá entrar em contato
        com a LCC por meio do e-mail plataforma@app.lucrandocomcredito.com.br
      </p>
      <h1>TERMOS E CONDIÇÕES ADICIONAIS</h1>
      <p>
        <strong>
          Estes Termos e Condições Adicionais são complementares aos Termos
          acima.
        </strong>
      </p>
      <h4>CADASTRO E REGISTRO</h4>
      <p>
        Para ter acesso a determinadas funcionalidades, serviços e produtos da
        Plataforma, o usuário deverá cadastrar-se nela, através da inserção de
        seu e-mail, criação de senha pessoal e intrasferível e prestação das
        informações solicitadas. A <strong>LCC</strong> não se responsabiliza
        pelo uso inadequado e divulgação destes dados para terceiros feitos pelo
        próprio usuário. Nem a <strong>LCC</strong>, nem quaisquer de seus
        empregados ou prepostos, solicitarão a informação da senha como condição
        para a realização de qualquer atendimento.
      </p>
      <p>
        O login e senha são confidenciais e sigilosos, devendo o usuário adotar
        as cautelas e diligências necessárias para impedir a sua utilização
        indevida por terceiros.
      </p>
      <p>
        O cadastro deverá conter apenas informações verídicas, sob pena do
        próprio usuário ser responsabilizado civil e criminalmente por falsidade
        ideológica. A <strong>LCC</strong> não se responsabiliza pela idoneidade
        ou capacidade civil do usuário, cabendo a ele agir com cautela e
        discernimento, respondendo, perante a <strong>LCC</strong> e terceiros,
        por toda as atividades realizadas por meio da Plataforma e informações
        prestadas.
      </p>
      <p>
        Em caso de perda ou furto de sua senha de acesso, o usuário deverá
        imediatamente solicitar a alteração de sua senha na Plataforma. Caso tal
        solicitação não seja possível por qualquer motivo, o usuário deverá
        comunicar imediatamente a <strong>LCC</strong>, sob pena de permanecer
        responsável por todas as ações na Plataforma feitas por meio do cadastro
        de tal usuário.
      </p>
      <p>
        A <strong>LCC</strong> poderá negar ou cancelar o cadastro de qualquer
        usuário, independentemente de qualquer aviso ou notificação prévia, caso
        (i) alguma informação apresentada seja irregular, insuficiente ou falsa;
        (ii) seja identificado qualquer indício de fraude ou irregularidade no
        cadastro ou na utilização da Plataforma; ou (iii) o cadastro originário
        do usuário tenha sido cancelado por infração às condições e termos
        destes Termos.
      </p>
      <p>
        O usuário poderá, a qualquer momento, solicitar o cancelamento de seu
        cadastro na Plataforma.
      </p>
      <p>
        O Usuário se compromete a notificar a <strong>LCC</strong> imediatamente
        e por meio seguro a respeito de qualquer uso ou suspeita de uso não
        autorizado de sua conta, bem como seu acesso não autorizado por
        terceiros. O Usuário será o único responsável pelas operações efetuadas
        em sua conta, uma vez que o acesso somente será possível mediante a
        inclusão da senha, que deverá ser de seu conhecimento e propriedade
        exclusiva.
      </p>
      <p>
        Não é permitida a manutenção de mais de um cadastro por um mesmo
        Usuário, nem a criação de novo cadastro por usuário cujo cadastro
        original tenha sido suspenso temporária ou definitivamente em
        decorrência de infrações a estes Termos ou à legislação aplicável.
      </p>
      <p>
        O Usuário deverá fazer o upload na Plataforma dos documentos solicitados
        pela <strong>LCC</strong>, para que a <strong>LCC</strong> possa
        verificar sua a identidade.
      </p>
      <h4>SOLICITAÇÃO DE PROPOSTAS OU COTAÇÕES</h4>
      <p>
        Através da Plataforma, o usuário poderá requerer que as Instituições
        Financeiras Parceiras enviem cotações ou propostas relativas a serviços
        e produtos financeiros que sejam do seu interesse. Ao fazer isso, o
        usuário expressamente reconhece que:
      </p>
      <ul>
        <li>
          As Instituições Financeiras Parceiras poderão solicitar diretamente
          outras informações sobre o usuário, cuja coleta, guarda e tratamento
          não serão de responsabilidade da <strong>LCC</strong>, se realizadas
          pelas Instituições Parceiras;
        </li>
        <li>
          Alguns serviços oferecidos pelas Instituições Financeiras Parceiras
          poderão gerar impostos, tarifas, emolumentos, custas, taxas e
          cobranças ou retenções específicas, legal ou contratualmente
          previstas, as quais não serão responsabilidade da <strong>LCC</strong>
          . Caberá ao usuário verifica-los, para que tenha inteiro conhecimento
          de quaisquer das mencionadas retenções ou cobranças, sendo seu
          adimplemento de exclusiva responsabilidade da parte a quem a lei ou o
          contrato atribuir responsabilidade;{' '}
        </li>
        <li>
          A <strong>LCC</strong> poderá enviar as informações fornecidas pelo
          usuário para as Instituições Financeiras Parceiras, em conformidade
          com a Política de Privacidade. Tais informações poderão ser usadas
          pelas Instituições Financeiras Parceiras e por outras instituições a
          elas ligadas para determinarem o perfil de consumidor do usuário, seu
          histórico de crédito e outros fatores relevantes para que possam
          enviar uma proposta adequada, incluindo, mas não se limitando para o
          fornecimento de crédito suplementar. As Instituições Financeiras
          Parceiras poderão entrar em contato com o Usuário, através das formas
          de contato que o usuário concedeu ao se cadastrar como usuário da
          Plataforma; e
        </li>
        <li>
          Cada serviço oferecido pela <strong>LCC</strong>, por Instituições
          Financeiras Parceiras possui termos contratuais e legais próprios,
          complementares aos presentes Termos de Uso, que deverão ser
          cuidadosamente verificados pelo Usuário antes da contratação.
        </li>
      </ul>
      <p>
        Vale ressaltar ainda que alguns serviços de Plataforma{' '}
        <strong>LCC</strong> só estarão disponíveis única e exclusivamente para
        funcionários ou empregados das instituições ou empresas que possuam
        Convênio com a <strong>LCC</strong> ou a qualquer empresa de seu grupo
        societário.{' '}
      </p>
      <p>
        A <strong>LCC</strong> não é parte no contrato ou relação entre o
        usuário e as Instituições Financeiras Parceiras, e não poderá se
        responsabilizar pelas obrigações ali assumidas. Tais obrigações serão de
        responsabilidade exclusiva das partes do contrato, na medida e forma em
        que tais responsabilidades forem alocadas.
      </p>
      <p>
        Caso o usuário deseje, deverá manifestar, por sua conta e risco, sua
        vontade em contratar um dos serviços ou produtos financeiros oferecidos
        pelas Instituições Financeiras Parceiras, dentre as opções
        disponibilizadas pelas Instituições Financeiras Parceiras na Plataforma
        da <strong>LCC</strong>, conforme o valor, condições de prazo e
        pagamento aplicáveis. Em seguida, o usuário deverá fornecer as
        informações solicitadas, que, juntamente com informações extraídas de
        bases de dados públicas e/ou privadas disponíveis, servirão de
        fundamento para a análise de seu risco de crédito pelas Instituições
        Financeiras Parceiras.
      </p>
      <p>
        Caso o usuário queira obter uma proposta de crédito nova e/ou
        suplementar com a Instituição Parceira por meio da Plataforma da{' '}
        <strong>LCC</strong>, deverá (i) autorizar o acesso da Instituição
        Parceira, conforme aplicável, às informações que lhe são relativas e que
        constem do Sistema de Informações de Crédito do Banco Central (“SCR”),
        dos órgãos de proteção ao crédito, de bases de dados públicas e
        privadas, conforme aplicável, e demais fontes que possam auxiliar na
        definição de seu risco de crédito; (ii) autorizar o compartilhamento das
        informações coletadas pela Instituição Parceira com a LCC e com as
        demais Instituições Financeiras Parceiras, conforme previsto nestes
        Termos; e (iii) autorizar a execução de procedimentos de{' '}
        <em>Know Your Client</em> (“KYC”), inclusive aqueles dispostos nos
        termos das leis anticorrupção, antiterrorismo, contra a lavagem de
        capitais e a evasão de divisas, nos termos da regulamentação bancaria
        aplicável e sempre que necessário ou adequado.
      </p>
      <h1>INSTITUIÇÕES FINANCEIRAS PARCEIRAS</h1>
      <p>
        A <strong>LCC</strong> é correspondente bancários das seguintes
        instituições: xxxxxxxxxxxxxxxxx
      </p>
    </PoliciesTemplate>
  );
}

export default TermsAndConditions;
