import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import colors from '../../styles/colors';

const enterContainer = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.section`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.background.second};
`;

export const Content = styled(Form)`
  width: 50%;
  max-width: 500px;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: ${enterContainer} 1s;
  background-color: ${colors.background.first};
  gap: 1rem;
  padding: 0rem 3rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(7, 22, 32, 0.6);
  @media screen and (max-width: 768px) {
    padding: 1rem;
    width: 100%;
  }
  & > img {
    width: 50%;
    margin: 0 auto 2rem auto;
    color: #071620;
  }
`;

export const ForgotPassword = styled(Link)`
  font-size: 14px;
  font-weight: 400;
  align-self: start;
  transform: translateY(-50%);
  color: ${colors.text.first};
  margin-top: 0.3rem;
`;

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  align-self: start;
  font-size: 16px;
  & > p {
    color: ${colors.text.first};
  }

  & > a {
    color: ${colors.text.first};
    text-decoration: underline;
  }
`;
