import { gql } from '@apollo/client';
import { SimulationData } from '../mutations/createSimulation';

export interface getSimulationsQueryResponse {
  getSimulations: SimulationData[];
}

const GET_SIMULATIONS = gql`
  query getSimulations {
    getSimulations {
      id
      value
      numberOfInstallments
      name
      cpf_cnpj
      email
      phone
      averageRate
      competitiveRate
      selicRate
      paymentEntry
      dealCategory {
        id
      }
      dealProduct {
        id
      }
      createdAt
      updatedAt
      personType
      amortizationType
    }
  }
`;

export default GET_SIMULATIONS;
