import React from 'react';
import { ModalProvider } from '../../hooks/Modal';
import Navbar from '../../templates/DashboardTemplate/Navbar';
import CardsContainer from './components/CardsContainer';
import DealsCategoriesSection from './components/DealsCategoriesSection';
import NewUser from './components/forms/NewUser';
import ReactivateCompany from './components/ReactivateCompany';
import Section from './components/Section';
import SuspendCompany from './components/SuspendCompany';
import TableCustomers from './components/TableCustomers';
import { AdminProvider } from './hooks/Admin';

import { Container, Content, ContentSection, TableSection } from './style';

function Admin(): JSX.Element {
  return (
    <AdminProvider>
      <ModalProvider>
        <Navbar title="Administração" />
        <Container>
          <CardsContainer />
          <TableSection title="Procurar Usuários">
            <TableCustomers />
          </TableSection>
          <Content>
            <Section title="Adicionar Novo Usuário">
              <ContentSection title="">
                <NewUser />
              </ContentSection>
            </Section>
            <Section title="Suspender / Reativar Usuário">
              <ContentSection title="">
                <SuspendCompany />
              </ContentSection>
              <ContentSection title="">
                <ReactivateCompany />
              </ContentSection>
            </Section>
            <Section title="Alterar Taxas do Simulador">
              <ContentSection title="">
                <DealsCategoriesSection />
              </ContentSection>
            </Section>
          </Content>
        </Container>
      </ModalProvider>
    </AdminProvider>
  );
}

export default Admin;
