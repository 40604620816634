import { PaletteColorOptions } from '@material-ui/core';

interface IPalette {
  [key: string]: PaletteColorOptions;
}

const palette: IPalette = {
  primary: {
    main: '#3F9177',
    light: '#B5D9CE',
    dark: '#2A614F',
  },
  secondary: {
    main: '#20618F',
    light: '#A7C4D9',
    dark: '#123650',
  },
  success: {
    main: '#02DB9A',
    light: '#CCF8EB',
    dark: '#02AF7B',
  },
  error: {
    main: '#DB0242',
    light: '#F8CCD9',
    dark: '#AF0235',
  },
  warning: {
    main: '#F18805',
    light: '#FCE7CD',
    dark: '#C16D04',
  },
  info: {
    main: '#246C9F',
    light: '#BDD3E2',
    dark: '#16415F',
  },
  text: {
    main: '#A6ACBE',
    light: '#F6F9FB',
    dark: '#363C4C',
  },
  background: {
    main: '#071620',
    light: '#E9F0F5',
  },
};

export { palette };
