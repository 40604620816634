import { ApolloError, useMutation } from '@apollo/client';
import { AxiosRequestConfig } from 'axios';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import LOGIN, {
  LoginMutationVars,
  LoginMutationResponse,
} from '../GraphQL/mutations/login';
import api from '../services/api';

import client from '../services/client';
import { verifyIfTokenIsExpired } from '../utils/verifyIfTokenIsExpired';

interface AuthContextData {
  signIn(credentials: LoginMutationVars): Promise<void>;
  signOut(): void;
  isLoginLoading: boolean;
  isCompanyLogged: boolean;
  setIsCompanyLogged(isCompanyLogged: boolean): void;
  token: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const localStorageTokenName = '@LCC:token';
  const [isCompanyLogged, setIsCompanyLogged] = useState<boolean>(() => {
    const storedToken = localStorage.getItem(localStorageTokenName);
    const isTokenExpired = verifyIfTokenIsExpired(storedToken || '');
    return !isTokenExpired;
  });
  const [token, setToken] = useState<string>('');

  const history = useHistory();

  const handleOnSignInCompleted = useCallback(
    ({ login }: LoginMutationResponse): void => {
      client.resetStore();
      setIsCompanyLogged(true);
      const { token: fetchedToken } = login;
      localStorage.setItem(localStorageTokenName, fetchedToken);
      setToken(fetchedToken);
      history.push('/');
    },
    [history],
  );

  const handleOnSignInError = useCallback(
    (mutationError: ApolloError): void => {
      toast.warn(`Não foi possível realizar o login: ${mutationError.message}`);
    },
    [],
  );

  const [login, { loading: isLoginLoading }] = useMutation<
    LoginMutationResponse,
    LoginMutationVars
  >(LOGIN, {
    onCompleted: handleOnSignInCompleted,
    onError: handleOnSignInError,
  });

  const signIn = useCallback(
    async ({ email, password }: LoginMutationVars): Promise<void> => {
      await login({
        variables: { email, password },
      });
    },
    [login],
  );

  const signOut = useCallback(() => {
    client.clearStore();
    localStorage.removeItem(localStorageTokenName);
    history.push('/login');
    setToken('');
    setIsCompanyLogged(false);
  }, [history]);

  useEffect(() => {
    const storedToken = localStorage.getItem(localStorageTokenName);
    const isTokenExpired = verifyIfTokenIsExpired(storedToken || '');
    setIsCompanyLogged(!isTokenExpired);
    setToken(storedToken || '');
  }, []);

  useEffect(() => {
    const newRequestConfigurations: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    Object.assign(api.defaults, newRequestConfigurations);
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        token,
        signIn,
        signOut,
        isLoginLoading,
        isCompanyLogged,
        setIsCompanyLogged,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}
