import { gql } from '@apollo/client';
import { ICompany } from './getCompany';

export interface IGetCompaniesQueryResponse {
  listCompanies: ICompany[];
}

const listCompaniesQuery = gql`
  query listCompanies {
    listCompanies {
      id
      name
      personName
      isSuspended
      createdAt
      updatedAt
      bitrix_id
      isSuspended
      eduzzBillID
      name
      personName
      email
      cpf_cnpj
      roleId {
        id
        name
        permissions
      }
      simulations {
        id
        name
        paymentEntry
      }
    }
  }
`;

export { listCompaniesQuery };
