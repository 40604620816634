import { gql } from '@apollo/client';
import { DealCategoryData } from '../queries/getDealsCategories';
import { DealProductData } from '../queries/getDealsProducts';

export interface CreateSimulationMutationVars {
  value: number;
  numberOfInstallments: number;
  name: string;
  cpf_cnpj: string;
  email: string;
  phone: string;
  dealCategoryID: string;
  dealProductID: string;
  amortizationType: number;
  personType: string;
  birthday: Date;
  paymentEntry: number;
  bitrix_id?: string;
}

export interface SimulationData {
  id: string;
  value: number;
  numberOfInstallments: number;
  name: string;
  cpf_cnpj: string;
  email: string;
  phone: string;
  averageRate: number;
  competitiveRate: number;
  selicRate: number;
  dealCategory: DealCategoryData;
  dealProduct: DealProductData;
  createdAt: Date;
  updatedAt: Date;
  personType: string;
  amortizationType: number;
  birthday: Date;
  paymentEntry: number;
  bitrix_id: string;
}

export interface CreateSimulationMutationResponse {
  createSimulation: SimulationData;
}

const CREATE_SIMULATION = gql`
  mutation createSimulation(
    $value: Float!
    $numberOfInstallments: Int!
    $name: String!
    $cpf_cnpj: String!
    $email: String!
    $phone: String!
    $dealCategoryID: String!
    $dealProductID: String!
    $amortizationType: Int!
    $personType: String!
    $birthday: DateTime!
    $paymentEntry: Float!
  ) {
    createSimulation(
      data: {
        value: $value
        numberOfInstallments: $numberOfInstallments
        name: $name
        cpf_cnpj: $cpf_cnpj
        email: $email
        phone: $phone
        dealCategoryID: $dealCategoryID
        dealProductID: $dealProductID
        amortizationType: $amortizationType
        personType: $personType
        birthday: $birthday
        paymentEntry: $paymentEntry
      }
    ) {
      id
      value
      numberOfInstallments
      name
      cpf_cnpj
      email
      phone
      averageRate
      competitiveRate
      selicRate
      createdAt
      updatedAt
      personType
      amortizationType
      birthday
      paymentEntry
    }
  }
`;

export default CREATE_SIMULATION;
