import { ApolloError, useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import Transition from '../../../../../../../../components/Transition';
import { SimulationData } from '../../../../../../../../GraphQL/mutations/createSimulation';
import DELETE_SIMULATION, {
  DeleteSimulationMutationResponse,
  DeleteSimulationMutationVars,
} from '../../../../../../../../GraphQL/mutations/deleteSimulation';
import { useSimulation } from '../../../../../../hooks/simulation';

interface DeleteSimulationConfirmDialogProps {
  simulation: SimulationData;
  handleOnClose(): void;
  isOpen: boolean;
  handleOnCloseMainDialog(): void;
}

function DeleteSimulationConfirmDialog({
  simulation,
  handleOnClose,
  isOpen,
  handleOnCloseMainDialog,
}: DeleteSimulationConfirmDialogProps): JSX.Element {
  const { simulations, setSimulations } = useSimulation();
  const handleOnDeleteSimulationMutationCompleted = useCallback(
    ({
      deleteSimulation: deletedSimulation,
    }: DeleteSimulationMutationResponse) => {
      setSimulations(
        simulations.filter(
          (someSimulation) => someSimulation.id !== deletedSimulation.id,
        ),
      );
      toast.success('Simulação excluída com sucesso');
      handleOnCloseMainDialog();
    },
    [handleOnCloseMainDialog, setSimulations, simulations],
  );

  const handleOnDeleteSimulationMutationError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(`Não foi possível excluir a simulação: ${message}`);
    },
    [],
  );
  const [deleteSimulation, { loading }] = useMutation<
    DeleteSimulationMutationResponse,
    DeleteSimulationMutationVars
  >(DELETE_SIMULATION, {
    onCompleted: handleOnDeleteSimulationMutationCompleted,
    onError: handleOnDeleteSimulationMutationError,
  });

  const handleOnButtonClick = useCallback(() => {
    deleteSimulation({
      variables: {
        id: simulation.id,
      },
    });
  }, [deleteSimulation, simulation]);
  return (
    <Dialog
      onClose={handleOnClose}
      open={isOpen}
      TransitionComponent={Transition}
    >
      <DialogTitle>Confirmação</DialogTitle>
      <DialogContent>
        <p>Você tem certeza que quer que deseja excluir esta simulação</p>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          endIcon={<Delete />}
          onClick={handleOnButtonClick}
        >
          {`${loading ? 'Excluindo' : 'Confirmar'}`}
        </Button>
        <Button color="primary" onClick={handleOnClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteSimulationConfirmDialog;
