import React from 'react';
import Navbar from '../../templates/DashboardTemplate/Navbar';
import { ContactsProvider } from './hooks/Contacts';

import { Container, Content } from './style';
import ContactsTable from './ContactsTable';

function Contacts(): JSX.Element {
  return (
    <ContactsProvider>
      <Navbar title="Meus Clientes" />
      <Container>
        <Content>
          <ContactsTable />
        </Content>
      </Container>
    </ContactsProvider>
  );
}

export default Contacts;
