import { MenuItem } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDealsCategories } from '../../../../../../hooks/DealsCategories';
import { TextFieldCustomized } from './style';
import { DealCategoryData } from '../../../../../../GraphQL/queries/getDealsCategories';

interface ICreditTypes {
  onCategorySelected: (category: string) => void;
}

function CategorySelect({ onCategorySelected }: ICreditTypes): JSX.Element {
  const [categorySelect, setCategorySelect] = useState<DealCategoryData>(
    {} as DealCategoryData,
  );
  const [selectedCategoryID, setSelectedCategoryID] = useState<string>('');
  const { dealsCategories } = useDealsCategories();

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event?.target.value;
      const dealCategorySelected = dealsCategories.find(
        (dealCategory) => dealCategory.id === value,
      );
      if (typeof dealCategorySelected !== 'undefined') {
        setCategorySelect(dealCategorySelected);
      }
      setSelectedCategoryID(value);
    },
    [dealsCategories, setCategorySelect, setSelectedCategoryID],
  );

  useEffect(() => {
    if (selectedCategoryID) {
      onCategorySelected(selectedCategoryID);
    }
  }, [dealsCategories, onCategorySelected, selectedCategoryID]);

  return (
    <TextFieldCustomized
      variant="outlined"
      select
      style={{
        whiteSpace: 'nowrap',
        marginBottom: '1rem',
        maxWidth: '300px',
      }}
      onChange={handleOnChange}
      defaultValue={categorySelect.id}
      className="third-step"
      label="Tipo de Crédito"
      key={categorySelect.id}
    >
      {dealsCategories
        .filter(
          (dealCategory) =>
            dealCategory.bitrix_id === 1 || dealCategory.bitrix_id === 5,
        )
        .map((dealCategory) => (
          <MenuItem key={dealCategory.id} value={dealCategory.id}>
            {dealCategory.name}
          </MenuItem>
        ))}
    </TextFieldCustomized>
  );
}

export default CategorySelect;
