import styled, { css } from 'styled-components';
import colors from '../../../styles/colors';

interface ContainerProps {
  isSidebarCompressed: boolean;
}

export const Container = styled.section<ContainerProps>`
  /* width: ${({ isSidebarCompressed }) =>
    isSidebarCompressed ? 'calc(5px+32px)' : '15.625rem'}; */
  width: ${({ isSidebarCompressed }) =>
    isSidebarCompressed ? 'max-content' : '15.625rem'};
  height: 100%;

  display: flex;
  align-items: stretch;
  flex-direction: column;

  background-color: ${colors.background.first};
  color: ${colors.text.first};

  overflow-x: hidden;

  transition: all 0.1s ease;

  ${(props) =>
    props.isSidebarCompressed &&
    css`
      padding: 0 1rem;
    `}

  @media screen and (max-width: 768px) {
    width: 3rem;
    padding: 0 1rem;

    ${({ isSidebarCompressed }) =>
      isSidebarCompressed &&
      css`
        display: none;
      `}
  }
`;

interface HeaderProps {
  isSidebarCompressed: boolean;
}

export const Header = styled.header<HeaderProps>`
  width: 100%;
  height: min-content;

  display: flex;
  align-items: flex-start;
  justify-content: space-around;

  padding: 1rem 0;

  & > img {
    height: 3rem;
    align-self: flex-start;
    cursor: pointer;
  }

  & > button {
    color: ${colors.text.first};
    ${({ isSidebarCompressed }) =>
      isSidebarCompressed &&
      css`
        display: none;
      `}
  }

  @media screen and (max-width: 768px) {
    & > button {
      display: none;
    }
  }
`;

export const Article = styled.article`
  height: max-content;
  width: 100%;

  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

interface LinkProps {
  isActive: boolean;
  isInDevelopment: boolean;
  isSidebarCompressed: boolean;
}

export const ExternalLink = styled.a<LinkProps>`
  height: 3rem;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: ${({ isSidebarCompressed }) =>
    isSidebarCompressed ? 'center' : 'flex-start'};

  text-decoration: none;

  cursor: ${({ isInDevelopment }) =>
    isInDevelopment ? 'not-allowed' : 'pointer'};

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: ${({ isActive }) =>
    isActive ? colors.success.third : colors.text.first};

  transition: all ease 0.3s;

  &:hover {
    background-color: ${colors.primary.dark.first};
  }

  @media screen and (min-width: 769px) {
    &::before {
      content: '';
      width: 5px;
      height: 3rem;
      background-color: ${({ isActive }) =>
        isActive ? colors.success.third : 'transparent'};
    }
  }
`;
