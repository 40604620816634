import styled, { css } from 'styled-components';
import colors from '../../../../../../../../../../../../../../../styles/colors';

interface ContainerProps {
  fillBackground: boolean;
}

export const Container = styled.section<ContainerProps>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;

  height: min-content;

  border: 0.031rem solid ${colors.primary.normal.first};
  padding: 1.13rem 2.531rem;
  border-radius: 0.313rem;
  background: ${(props) =>
    props.fillBackground ? colors.primary.normal.first : 'transparent'};
  ${(props) =>
    props.fillBackground &&
    css`
      &&& * {
        color: #fff;
      }
    `}

  &>span {
    width: 100%;
    height: 100%;
  }
`;

export const PresentationContainer = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 0.897rem;
    line-height: 1.063rem;
    color: #0d1c2e;
  }
  & > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 0.797rem;
    line-height: 0.938rem;
    color: #363c4c;
  }
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    & > p {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
`;

export const CommentText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 0.797rem;
  line-height: 1.313rem;
  white-space: pre-wrap;
`;

export const CommentFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
`;
