import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Assignment, AssignmentTurnedIn } from '@material-ui/icons';
import { ButtonLink, Icon, ProductItemContainer, Title } from './style';

interface IProductItem {
  icon: JSX.Element;
  name: string;
  backcolor: string;
  color: string;
  path: string;
}

function ProductItem({
  icon,
  name,
  backcolor,
  color,
  path,
}: IProductItem): JSX.Element {
  const [wasPathCopied, setWasPathCopied] = useState<boolean>(false);

  const handleOnCopyButtonClick = useCallback(() => {
    setWasPathCopied(true);
    navigator.clipboard.writeText(path);
    toast.success(`O Link de captação foi copiado com sucesso`);
  }, [path]);

  return (
    <ProductItemContainer>
      <Icon style={{ backgroundColor: `${backcolor}`, color: `${color}` }}>
        {icon}
      </Icon>
      <Title>{name}</Title>
      <ButtonLink onClick={handleOnCopyButtonClick}>
        {wasPathCopied ? <AssignmentTurnedIn /> : <Assignment />}
        {wasPathCopied ? 'Copiado!' : 'Copiar'}
      </ButtonLink>
    </ProductItemContainer>
  );
}

export default ProductItem;
