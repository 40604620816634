import React, { useCallback } from 'react';
import { BitrixDealData } from '../../../../GraphQL/queries/getDealsUpdated';
import { useBusiness } from '../../hooks/Business';
import DealsStatus from './components/DealsStatus';

function DealsSection(): JSX.Element {
  const { deals, dealsStages } = useBusiness();
  const FilterDealsByStageID = useCallback(
    (stageID: string): BitrixDealData[] => {
      const dealsFiltered = deals.filter((deal) => deal.STAGE_ID === stageID);
      return dealsFiltered;
    },
    [deals],
  );
  return (
    <>
      {dealsStages.map((stage) => {
        const dealsFiltered = FilterDealsByStageID(stage.STATUS_ID);
        return (
          <DealsStatus
            key={stage.NAME}
            deals={dealsFiltered}
            title={stage.NAME}
            dealStage={stage}
          />
        );
      })}
    </>
  );
}

export default DealsSection;
