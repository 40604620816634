import { CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';
import TableIcons from '../../../../components/TableIcons';
import { useCompanies } from '../../../../hooks/ListCompanies';
import { Container, Content } from './style';

function TableCustomers(): JSX.Element {
  const { companies } = useCompanies();

  const filteredCompanies = companies.map((company) => ({
    ...company,
    isSuspended: company.isSuspended ? 'Inativo' : 'Ativo',
  }));

  return (
    <>
      <Container>
        <Content>
          {companies.length > 0 ? (
            <MaterialTable
              columns={[
                { title: 'Nome', field: 'name', tooltip: 'Nome da empresa' },
                {
                  title: 'Nome Fantasia',
                  field: 'personName',
                  tooltip: 'Nome Fantasia',
                },
                { title: 'CPF/CNPJ', field: 'cpf_cnpj', tooltip: 'CPF ou CNPJ' },
                {
                  title: 'ID Bitrix',
                  field: 'bitrix_id',
                  tooltip: 'ID Bitrix',
                },
                { title: 'E-mail', field: 'email', tooltip: 'E-mail' },
                {
                  title: 'Status',
                  field: 'isSuspended',
                  tooltip: 'Status do cliente',
                },
              ]}
              data={JSON.parse(JSON.stringify(filteredCompanies))}
              icons={TableIcons}
              style={{
                width: '100%',
                height: '100%',
              }}
              options={{
                showTitle: false,
                minBodyHeight: 0,
                maxBodyHeight: '100%',
                actionsColumnIndex: -1,
              }}
              actions={[]}
              localization={{
                toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar',
                  searchAriaLabel: 'Pesquisar',
                },
                pagination: {
                  nextTooltip: 'Próximo',
                  previousTooltip: 'Anterior',
                  lastTooltip: 'Ultimo',
                  firstTooltip: 'Primeiro',
                  labelRowsSelect: '',
                  labelDisplayedRows: '{from}-{to} de {count}',
                },
                body: {
                  emptyDataSourceMessage: 'Sem clientes cadastrados',
                },
                header: {
                  actions: 'Visualizar',
                },
              }}
            />
          ) : (
            <CircularProgress />
          )}
        </Content>
      </Container>
    </>
  );
}

export default TableCustomers;
