import React from 'react';
import PoliciesTemplate from '../../../templates/PoliciesTemplate';

function Privacy(): JSX.Element {
  return (
    <PoliciesTemplate>
      <h1>Política de Privacidade</h1>
      <small>
        <em>
          Esta Política de Privacidade foi atualizada pela última vez em 22 de
          março de 2021
        </em>
      </small>
      <p>
        A ‘Lucrando com Crédito Soluções Financeiras’ (A L P de Vasconcelos -
        ME), pessoa jurídica de direito privado inscrita no CNPJ/ME sob o nº
        39.830.139/0001-06, sediada na cidade de Natal/RN, ciente da importância
        de que o <strong>Usuário LCC</strong> mantenha o controle sobre o uso de
        seus dados, bem como das informações fornecidos quando da utilização de
        nossa plataforma, desenvolveu a presente Política de Privacidade.
      </p>
      <p>
        As diretrizes aqui contidas se aplicarão às interações do{' '}
        <strong>Usuário LCC</strong> realizadas em nosso Website
        (https://app.lucrandocomcredito.com.br) ou aplicativos da Lucrando com
        Crédito - LCC na Google Play Store e na Apple App Store (ambos referidos
        como “Aplicativo”). Mas atenção, não nos responsabilizamos pelo uso de
        seus dados caso acesse links de terceiros pela Plataforma.
      </p>
      <p>
        Ao interagir com a plataforma da <strong>LCC</strong>, o usuário está
        ciente da possibilidade de acesso aos seus dados pessoais. Nesta página,
        descrevemos a forma como a <strong>LCC</strong> processa os seus dados,
        seja ao acessar, ao se cadastrar ou utilizar de nossa Plataforma e
        demonstraremos nosso compromisso em tratar seus dados pessoais de forma
        responsável, transparente e segura, garantindo a sua privacidade e o
        respeito a seus direitos.
      </p>
      <p>
        Esta Política de Privacidade se aplica a todos os serviços e operações
        de processamento de dados da <strong>LCC</strong>, tanto por sua matriz,
        quanto pelas filiais que eventualmente venham a se constituir. Lembrando
        que, a qualquer tempo, poderemos revisar o conteúdo da Política, sempre
        com a finalidade de aprimorá-la, em conformidade com os regulamentos
        nacionais, inclusive a Lei Geral de Proteção de Dados (Lei nº
        13.709/2018).
      </p>
      <h1>
        CASO VOCÊ NÃO CONCORDE COM QUAISQUER DAS DISPOSIÇÕES DESTA POLÍTICA DE
        PRIVACIDADE, POR FAVOR, NÃO UTILIZE NOSSO WEBSITE E/OU NOSSO APLICATIVO.
      </h1>
      <p>
        Em caso de sugestões, dúvidas, reclamações sobre o tema ou caso queira
        exercer os direitos relacionados aos seus dados pessoais, entre em
        contato pelos nossos canais de atendimento
        https://app.lucrandocomcredito.com.br ou diretamente com nossa equipe de
        atendimento, por meio do e-mail plataforma@app.lucrandocomcredito.com.br
      </p>
      <h4>A quem esta política se aplica</h4>
      <p>
        Essa Política se aplica aos (i) Usuários LCC, (ii) potenciais clientes e
        (iii) visitantes, pessoas naturais que acessam a nossa Plataforma, por
        si ou por pessoas jurídicas.
      </p>
      <h4>O que você encontrará nesta Política?</h4>
      <p>
        Nesta Política, você encontrará as informações necessárias para entender
        sobre:
      </p>
      <ul>
        <li>Quais informações coletamos sobre o usuário;</li>
        <li>Como coletamos essas informações;</li>
        <li>Para que usamos essas informações;</li>
        <li>Como interromper a coleta de informações;</li>
        <li>Como armazenamos e protegemos as informações coletadas;</li>
        <li>Como exercer os seus direitos;</li>
        <li>Em quais casos poderemos compartilhar tais informações.</li>
      </ul>
      <h4>Natureza Opcional de Concessão dos Dados</h4>
      <p>
        O usuário é livre para fornecer seus dados pessoais ao solicitar os
        serviços oferecidos pela LCC. Contudo, a não concessão dos dados pode
        impossibilitar a obtenção do serviço solicitado.
      </p>
      <h4>Quais informações Nós Coletamos</h4>
      <p>
        Para executar nossos serviços e aprimorarmos continuamente sua
        experiência com a LCC, coletamos informações importantes de nossos
        usuários, sendo elas:
      </p>
      <ul>
        <li>
          <strong>Informações Cadastrais</strong>: Ao se cadastrar, coletamos o
          nome, e-mail, endereço, telefone de contato, CPF, RG, data de
          nascimento e sexo.
        </li>
        <li>
          <strong>Informações Complementares</strong>: Quando o usuário solicita
          cotações, simulações ou propostas, poderemos solicitar informações
          complementares, tais como idade, nacionalidade, estado civil, regime
          civil e filiação (nome da mãe e nome do pai), informações
          profissionais e sobre escolaridade a depender do serviço ou produto
          solicitado.
        </li>
        <li>
          <strong>Informações Financeiras</strong>: Além disso, também
          solicitamos informações financeiras relevantes para que possam ser
          oferecidos produtos ou serviços adequados às suas necessidades.
          Poderemos também consultar bureaus de crédito e fontes externas para
          verificar a capacidade de pagamento, a veracidade das informações,
          proteção ao crédito e prevenir fraudes. Tais informações financeiras
          poderão ainda incluir renda, despesas, débitos em haver, bens de sua
          propriedade, dados bancários, outros dados do seu patrimônio e o seu
          score de crédito.
        </li>

        <li>
          <strong>Informações de Perfil</strong>: Podemos tratar também
          informações sobre as preferências de compra e de navegação na internet
          a partir da Plataforma, para conhecer melhor o perfil do usuário e
          oferecer serviços mais adequados à sua necessidade.
        </li>
        <li>
          <strong>Atividades de navegação</strong>: Poderemos coletar
          informações e dados sobre a experiência de navegação em nosso Website
          ou Aplicativo, por meio da coleta automática do{' '}
          <em>Internet Protocol</em> (IP), de sua localização, seu tipo de
          computador, tipo de celular, tipo de navegador, páginas e serviços
          acessados, entre outros.
        </li>
      </ul>
      <h4>Como Coletamos Informações Sobre o Usuário</h4>
      <p>
        As informações sobre o usuário, mencionadas acima, podem ser coletadas
        pela <strong>LCC</strong> (i) diretamente do usuário, ao se registrar ou
        utilizar algum de nossos Serviços, (ii) de forma automática, como quando
        navega em nossas páginas e utiliza nossa Plataforma, ou ainda (iii)
        através de terceiros.
      </p>
      <ul>
        <li>
          <strong>Coleta de informações diretamente com o Usuário.</strong>
        </li>
      </ul>
      <p>
        Esta espécie de coleta ocorre quando o Usuário realiza o seu cadastro,
        utiliza o Website/Aplicativo da <strong>LCC</strong>, solicita cotações,
        simulações e propostas de instituições financeiras, companhias de
        seguros, operadoras de meios de pagamento ou bandeira de cartão de
        crédito (“Instituições Parceiras”).
      </p>
      <ul>
        <li>
          Coleta de informações por meio de atividades de navegação e da
          utilização de nossa Plataforma.
        </li>
      </ul>
      <p>
        A <strong>LCC</strong> pode coletar informações e dados sobre a sua
        experiência de navegação em nosso <em>Website</em> ou Aplicativo através
        de <em>cookies</em> (mais informações abaixo), <em>web beacons</em>
        e/ou outras tecnologias de monitoramento semelhantes. Estas tecnologias
        permitem, por exemplo, lembrar-se de você e de suas preferências,
        verificar quais páginas você visita e quando você visita tais páginas,
        além de outros sites que foram visitados antes, além de coletar
        informações que podem ser usadas para oferecer um conteúdo
        personalizado.
      </p>
      <p>
        <em>Cookies</em> são identificações da interação com nosso{' '}
        <em>Website</em> ou nossas publicidades que são transferidas para o
        aparelho do usuário visando reconhecê-lo na próxima navegação.
        Utilizamos <em>cookies</em> para proporcionar uma melhor experiência em
        nosso <em>Website</em> ou Aplicativo e viabilizar recursos
        personalizados, como recomendações de infográficos, artigos,
        calculadoras, produtos e serviços financeiros, publicidades e
        informações adicionais do seu interesse. <em>Cookies</em> e outras
        tecnologias semelhantes ativadas por terceiros poderão ser encontrados
        em algumas páginas do <em>Website</em>, e utilizadas por terceiros,
        aplicando-se as políticas e termos de uso destes terceiros. Não
        controlamos estas tecnologias e, desta forma, esta Política não se
        aplica a estes
        <em>cookies</em> e tecnologias de terceiros.
      </p>
      <ul>
        <li>Coleta de informações por meio de terceiros e dados públicos.</li>
      </ul>
      <p>
        Para fornecer os Serviços solicitados e enviar propostas para o usuário,
        coletaremos informações que lhe são relativas e que constem do Sistema
        de Informações de Crédito do Banco Central (“SCR”), dos órgãos de
        proteção ao crédito, de bases de dados públicas e privadas, conforme
        aplicável, e demais fontes que possam auxiliar na definição de seu risco
        de crédito para verificar a sua capacidade de pagamento, bem como para
        garantir a sua segurança e para fins de prevenção de fraudes ou de
        proteção ao crédito. Ademais, poderemos coletar informações para a
        execução de procedimentos de prevenção à lavagem de dinheiro e
        financiamento ao terrorismo - <em>Know Your Client (“KYC”)</em>,
        inclusive aqueles dispostos nos termos das leis anticorrupção,
        antiterrorismo, contra a lavagem de capitais e a evasão de divisa (por
        exemplo, para verificar se Você faz parte de alguma lista de Pessoa
        Politicamente Exposta – PEP – e demais listas de restrição – OFAC e
        demais listas internacionais).
      </p>
      <p>
        Para não ter suas informações pessoais coletadas, recomendamos que o
        usuário não acesse o Website e Aplicativo, bem como não solicite
        quaisquer propostas, simulação e cotações de nossos serviços e produtos.
      </p>
      <p>
        Caso deseje interromper a coleta de informações pelos cookies, o usuário
        deverá desabilitar o salvamento de cookies em seu navegador de internet,
        apagá-los e gerenciar sua utilização por meio da configuração do
        navegador que utiliza para acessar o Website, ou deverá sair da sua
        sessão do Aplicativo e não o utilizar mais.
      </p>
      <p>
        Caso opte por desabilitar alguns recursos de navegação a Plataforma
        poderá deixar de funcionar.
      </p>
      <p>
        Os principais navegadores de internet (ex. Google Chrome, Internet
        Explorer, Microsoft Edge, Mozilla Firefox, Safari, Opera) possibilitam
        ao usuário gerenciar a utilização dos cookies em sua máquina. O usuário
        precisa fazer o procedimento em cada um deles separadamente.
      </p>
      <h4>Para Que Usamos Essas Informações</h4>
      <p>
        Todas as informações coletadas pela <strong>LCC</strong> na Plataforma
        servem para permitir o aperfeiçoamento dos serviços prestados ao
        usuário, tornando-os mais adequados, mais competitivos e mais completos.
      </p>
      <p>
        Sendo assim, a LCC poderá utilizar essas informações (pessoais ou não)
        principalmente para:
      </p>

      <ul>
        <li>Criar a sua conta junto à LCC;</li>
        <li>
          Completar as suas solicitações de cotações, simulações, propostas de
          produtos ou serviços oferecidos pelas Instituições Parceiras;
        </li>
        <li>
          Análise de crédito de acordo com as políticas de crédito da LCC e
          consulta sobre a capacidade de pagamento do usuário em plataformas de
          bureaus de crédito, bem como proteção ao crédito;
        </li>
        <li>
          Traçar perfis e tendências demográficas de uso do Website ou
          Aplicativo;
        </li>
        <li>
          Entrar em contato com o usuário para confirmar ou verificar as
          informações que nos foi fornecido e garantir a segurança os usuários
          da LCC;
        </li>
        <li>
          Entrar em contato com o usuário para fins de marketing sobre os
          produtos e serviços ofertados pela LCC e por Instituições Parceiras;
        </li>
        <li>
          Tratativas sobre dúvidas, reclamações ou sugestões enviadas nos canais
          de atendimento da LCC, bem como na resolução de problemas;
        </li>
        <li>
          Garantir que o Website ou Aplicativo se mantenha sempre interessante e
          útil para o usuário, o que poderá incluir a personalização de anúncios
          e sugestões de conteúdo, produtos ou serviços.
        </li>
        <li>
          Cruzar informações através do Google Analytics para analisar os perfis
          dos usuários e oferecer serviços mais personalizados;
        </li>
        <li>Aprimorar o modelo de precificação e modelo de crédito da LCC;</li>
        <li>
          Proteger a segurança e a integridade da Plataforma, de nossos direitos
          e propriedades, e de nossa base de dados;
        </li>
        <li>Conduzir diligências internas relativas aos negócios da LCC;</li>
        <li>
          Desenvolver, melhorar e oferecer serviços e produtos de terceiros;
        </li>
        <li>
          Exercer os direitos da LCC em processos judiciais e administrativos,
          conforme necessário;
        </li>
        <li>
          Atender obrigações legais ou regulatórias e solicitações de
          autoridades, se pertinentes, bem como a verificação de fraudes, crimes
          financeiros e demais ilícitos.
        </li>
      </ul>
      <h4>Compartilhamento de Informações</h4>
      <p>
        A viabilidade de certos serviços prestados pela LCC só ocorre pelo
        compartilhamento de algumas dessas informações, o que fazemos com
        responsabilidade e seguindo rigorosos parâmetros, principalmente de
        segurança da informação. Abaixo, citamos os casos nos quais o
        compartilhamento de informações se faz necessário:
      </p>
      <p>
        <strong>Instituições Parceiras e Prestadores de Serviços</strong>. Para
        que o Usuário possa receber cotações e propostas de produtos e serviços
        financeiros adequadas ao que procura, tais como cartões de crédito,
        seguros, empréstimos, financiamentos, investimentos e outros, a{' '}
        <strong>LCC</strong>, as Instituições Financeiras e Instituições
        Parceiras, necessitam ter acesso a informações pessoais e não pessoais.
        Ressaltamos que as Instituições Parceiras e Prestadores de Serviços
        terão acesso a essas informações apenas na medida em que forem
        necessárias para oferecer os produtos e serviços que foram solicitados
        (ou que manifestou interesse) e fazer as devidas validações cadastrais e
        financeiras. Exemplificadamente, ao solicitar uma simulação de crédito
        no Website ou Aplicativo, o Usuário concorda e autoriza que o Banco ou a
        Instituição Financeira Parceira tenha acesso e consulte seus dados de
        depósitos, aplicações em outras instituições financeiras e informações a
        seu respeito constantes junto aos órgãos de proteção ao crédito, tais
        como SPC e Serasa.
      </p>
      <p>
        As informações armazenadas no Website e no Aplicativo poderão, ainda,
        ser fornecidas a terceiros, tais como instituições financeiras e demais
        agentes de mercado, para fins de análise, elaboração e gerenciamento de
        Propostas de Captação de Recursos, com o objetivo de gerar uma Proposta
        de Crédito, em consonância com a Política de Avaliação de Crédito. As
        informações submetidas poderão ser utilizadas para manutenção dos
        Serviços de Cobrança das operações de crédito da
        <strong>LCC</strong>, das Instituições Financeiras e futuros Credores
        dos créditos eventualmente cedidos, gerenciamento de aplicações,
        recomendação de investimentos e manutenção de um banco de dados.
      </p>
      <p>
        O Usuário também deve estar ciente de que as suas informações serão
        tratadas por essas instituições de acordo as suas próprias políticas de
        privacidade. Não submeta pedidos de propostas e cotações caso não
        concorde com a forma com a qual as Instituições Financeiras Parceiras
        tratam os seus dados pessoais.
      </p>
      <ul>
        <li>
          <p>Prestadores de serviço de tecnologia</p>. A <strong>LCC</strong>{' '}
          poderá compartilhar dados pessoais com empresas que forneçam suporte
          ou infraestrutura de tecnologia para viabilização do Website,
          Aplicativo e dos produtos e serviços ofertados pela{' '}
          <strong>LCC</strong>.
        </li>
        <li>
          <p>Novos negócios</p>: O compartilhamento de informações de Usuário
          também poderá ocorrer no contínuo desenvolvimento do nosso negócio,
          processos de aquisição e fusão de empresas, estabelecimento de
          parcerias comerciais, joint ventures e outros negócios. Mesmo nesses
          casos a <strong>LCC</strong> garantirá a observância da presente
          Política de Privacidade.
        </li>
        <li>
          <p>Ordem judicial</p>. A <strong>LCC</strong> pode compartilhar dados
          pessoais em caso de requisição judicial, independentemente de prévia
          comunicação ao Usuário.
        </li>
        <li>
          <p>Com a autorização do usuário</p>. Em demais casos, havendo a
          necessidade de compartilhamento das informações para fins não
          estabelecidos nesta Política de Privacidade e/ou para os quais a
          legislação de proteção de dados assim não autorize, a{' '}
          <strong>LCC</strong> enviará ao Usuário uma notificação, solicitando o
          seu consentimento.
        </li>
      </ul>
      <h4>Como as Informações Coletadas são Armazenadas e Protegidas</h4>
      <p>
        Após se coletar os dados e informações mencionados nessa Política de
        Privacidade, serão armazenados sob as mais rígidas práticas de segurança
        de informação. Nosso banco de dados terá seu acesso criteriosamente
        restringido a apenas alguns colaboradores habilitados, que são obrigados
        por contrato a preservar a confidencialidade de suas informações.
      </p>
      <p>
        A <strong>LCC</strong> se compromete a sempre empregar os melhores
        esforços para garantir que as informações de seus usuários serão sempre
        tratadas de acordo com o estabelecido nesta Política de Privacidade e
        com o disposto na legislação aplicável de proteção de dados. Ainda
        assim, o usuário deve saber que a <strong>LCC</strong> não é responsável
        pela hipótese de quebra de segurança de nossa base de dados que cause a
        divulgação ou acesso indevido de informações do usuário. Em tal caso,
        nenhuma compensação por parte da <strong>LCC</strong> será devida ao
        usuário.
      </p>
      <h4>Dos Direitos do Usuário</h4>
      <p>
        Os usuários possuem direitos no que se refere às suas informações
        pessoais, dentre eles:
      </p>

      <ul>
        <li>
          {' '}
          <strong>
            Confirmação da existência de tratamento de dados pessoais.
          </strong>{' '}
          Mediante requisição do usuário, a LCC concederá a confirmação da
          existência de tratamento de dados pessoais, nos termos da legislação
          aplicável.
        </li>
        <li>
          {' '}
          <strong>Acesso aos dados pessoais.</strong> O usuário pode requisitar
          acesso aos seus dados pessoais coletados e que estejam armazenados
          pela LCC.
        </li>
        <li>
          {' '}
          <strong>
            Correção de dados incompletos, inexatos ou desatualizados.
          </strong>{' '}
          O usuário poderá, a qualquer momento, alterar e editar os seus dados
          pessoais.
        </li>
        <li>
          {' '}
          <strong>Informações sobre uso compartilhado de dados.</strong> As
          informações sobre o compartilhamento de dados pessoais encontram-se
          nesta Política de Privacidade. A LCC coloca-se à disposição do Usuário
          para a hipótese de esclarecimentos complementares.
        </li>
        <li>
          {' '}
          <strong>Revisão de decisão automatizada.</strong> O usuário poderá
          solicitar a revisão de decisões tomadas unicamente com base em
          tratamento automatizado de dados pessoais que afetem seus interesses,
          incluídas as decisões destinadas a definir o seu perfil pessoal,
          profissional, de consumo e de crédito ou os aspectos de sua
          personalidade.
        </li>
        <li>
          {' '}
          <strong>Eliminação dos dados pessoais.</strong> O usuário poderá
          solicitar a eliminação de dados pessoais que tenham sido coletadas
          pela LCC a partir do consentimento do usuário, a qualquer momento,
          mediante manifestação gratuita e facilitada. A eliminação dos dados
          pessoais somente poderá ser completa para dados pessoais que não sejam
          necessários para a LCC cumprir com obrigações legais, contratuais,
          para proteção de seu legítimo interesse e nas demais hipóteses
          admitidas legalmente.
        </li>
        <li>
          {' '}
          <strong>Revogação do consentimento.</strong> O usuário poderá revogar
          o consentimento que tenha dado à LCC para tratamento dos seus dados
          pessoais para certas finalidades, a qualquer momento, mediante
          manifestação gratuita e facilitada. Importante informar que os
          tratamentos realizados anteriormente à revogação do consentimento são
          ratificados e que o pedido de revogação não implicará a eliminação dos
          dados pessoais anteriormente tratados e que sejam mantidos pela LCC
          com base em outros fundamentos legais.
        </li>
        <li>
          {' '}
          <strong>Portabilidade:</strong> A Autoridade Nacional de Proteção de
          Dados (ANPD) irá regulamentar o direito de portabilidade. A partir de
          então, o usuário poderá solicitar à LCC a portabilidade de seus dados
          para outro prestador de serviço.
        </li>
        <li>
          {' '}
          <strong>
            Exercício regular do direito pelo Titular dos Dados Pessoais:
          </strong>{' '}
          Com a devida constituição da ANPD, o usuário poderá peticionar por
          seus direitos perante a Autoridade Nacional de Proteção de Dados.
        </li>
      </ul>
      <p>
        Caso o usuário deseje exercer qualquer um desses direitos, poderá entrar
        em contato com nossa equipe através dos canais atendimento disponível no
        Website (https://app.lucrandocomcredito.com.br) ou no App ou diretamente
        com nossa equipe através do e-mail
        plataforma@app.lucrandocomcredito.com.br No caso de alguma solicitação
        sobre seus direitos, podemos solicitar comprovação da sua identidade,
        como medida de segurança e prevenção à fraude.
      </p>
      <h4>Links para Sites de Terceiros</h4>
      <p>
        Através da Plataforma, a <strong>LCC</strong> poderá disponibilizar
        links para outros sites na Internet.{' '}
        <strong>
          A LCC NÃO SE RESPONSABILIZA POR ESTES WEBSITES E CONTEÚDOS E, AINDA,
          NÃO COMPARTILHA DADOS, SUBSCREVE, MONITORA, VALIDA OU ACEITA A FORMA
          COMO ESSES WEBSITES OU FERRAMENTAS DE ARMAZENAMENTO DE CONTEÚDO
          COLETAM, PROCESSAM E TRANSFEREM SUAS INFORMAÇÕES PESSOAIS.
        </strong>{' '}
        Recomendamos que o usuário consulte as respectivas políticas de
        privacidade de tais websites para se informar adequadamente a respeito
        do uso de suas informações pessoais.
      </p>
      <h4>Alterações nesta Política de Privacidade</h4>
      <p>
        Reservamo-nos o direito de corrigir ou atualizar esta Política de
        Privacidade periodicamente. Em situações relevantes, principalmente na
        eventual modificação das finalidades para os quais os dados tenham sido
        coletados, o usuário será informado quanto às alterações realizadas.
      </p>
      <p>
        Ao atualizarmos esta Política de Privacidade, atualizaremos também a
        data na parte superior desta página. A nova Política de Privacidade
        entrará em vigor imediatamente após a publicação.
      </p>
      <p>
        Em caso de não concordância com a política, altere suas preferências no
        Website ou Aplicativo ou deixe de utilizá-los. Se continuar acessando
        depois que essas alterações entrarem em vigor, o usuário estará
        confirmando que foi informado sobre a Política de Privacidade e que
        concorda com ela em sua versão revisada.
      </p>
      <h4>Contato do Controlador e do Encarregado</h4>
      <p>
        A <strong>LCC</strong>, já qualificada acima, é a controladora dos dados
        pessoais dos usuários tratados no âmbito desta Política de Privacidade.
      </p>
      <p>
        Caso tenha qualquer dúvida após a leitura desta Política, a LCC
        disponibiliza alguns canais de contato para diversos temas, confira em:
        https://app.lucrandocomcredito.com.br e em nosso Aplicativo.
      </p>
      <p>
        O usuário pode entrar em contato direto com o nosso Encarregado através
        do e-mail plataforma@app.lucrandocomcredito.com.br
      </p>
      <h4>Termos de Uso </h4>
      <p>
        Esta Política de Privacidade faz parte dos nossos Termos de Uso,
        disponíveis em{' '}
        <a href="https://app.lucrandocomcredito.com.br">
          https://app.lucrandocomcredito.com.br
        </a>
        .
      </p>
    </PoliciesTemplate>
  );
}

export default Privacy;
