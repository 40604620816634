import React from 'react';
import PlatformCard from './PlatformCard';
import businessIcon from '../../assets/Icons/businessIcon.svg';
import learnIcon from '../../assets/Icons/learnIcon.svg';

import { Container, CardsContainer } from './style';
import Header from './Header';

function Home(): JSX.Element {
  return (
    <Container>
      <Header />
      <CardsContainer>
        <PlatformCard
          title="Plataforma de Negócios"
          subtitle="Todas as soluções de todos os bancos ao mesmo tempo, em uma só plataforma"
          path="/negocios"
          icon={businessIcon}
        />
        <PlatformCard
          title="Plataforma de Aprendizagem"
          subtitle="Aprenda tudo sobre soluções de credito e prepare-se para empreender nesse mercado"
          path="https://lucrandocomcredito.memberkit.com.br/"
          icon={learnIcon}
          isAnExternalPath
        />
      </CardsContainer>
    </Container>
  );
}

export default Home;
