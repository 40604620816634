import { ApolloError, useMutation } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import CREATE_SIMULATION, {
  CreateSimulationMutationResponse,
  CreateSimulationMutationVars,
} from '../../../../../GraphQL/mutations/createSimulation';
import { DealCategoryData } from '../../../../../GraphQL/queries/getDealsCategories';
import { DealProductData } from '../../../../../GraphQL/queries/getDealsProducts';
import { useDealsProducts } from '../../../../../hooks/DealsProducts';
import { useSimulation } from '../../../hooks/simulation';
import FirstStepForm from '../CreateSimulationDialog/forms/FirstStepForm';
import SecondStepForm from '../CreateSimulationDialog/forms/SecondStepForm';
import ThirdStepForm from '../CreateSimulationDialog/forms/ThirdStepForm';

interface SimulationStepsContextData {
  currentStep: number;
  setCurrentStep(step: number): void;
  currentForm: ReactNode;
  setCurrentForm(form: ReactNode): void;
  selectedDealCategory: DealCategoryData;
  setSelectedDealCategory(dealCategory: DealCategoryData): void;
  selectedDealProduct: DealProductData;
  setSelectedDealProduct(dealProduct: DealProductData): void;
  availableDealsProducts: DealProductData[];
  setAvailableDealsProducts(dealProducts: DealProductData[]): void;
  personType: string;
  setPersonType(personType: string): void;
  value: number;
  setValue(value: number): void;
  numberOfInstallments: number;
  setNumberOfInstallments(numberOfInstallments: number): void;
  amortizationType: string;
  setAmortizationType(amortizationType: string): void;
  name: string;
  setName(name: string): void;
  birthday: Date;
  setBirthday(date: Date): void;
  cpf_cnpj: string;
  setCPF_CNPJ(cpf_cnpj: string): void;
  email: string;
  setEmail(email: string): void;
  phone: string;
  setPhone(phone: string): void;
  handleOnClose(): void;
  isCreateSimulationMutationLoading: boolean;
  createSimulation(data: CreateSimulationMutationVars): Promise<void>;
  cancelButtonText: string;
  setCancelButtonText(text: string): void;
  confirmButtonText: string;
  setConfirmButtonText(text: string): void;
  dialogContentText: string;
  setDialogContentText(text: string): void;
  isMutationLoading: boolean;
  setIsMutationLoading(isMutationLoading: boolean): void;
  isBackButtonDisabled: boolean;
  setIsBackButtonDisabled(isBackButtonDisabled: boolean): void;
  nextButtonText: string;
  setNextButtonText(text: string): void;
  paymentEntry: number;
  setPaymentEntry(paymentEntry: number): void;
}

const SimulationStepsContext = createContext<SimulationStepsContextData>(
  {} as SimulationStepsContextData,
);

interface SimulationStepsProviderProps {
  children: ReactNode;
  setIsDialogOpen(isDialogOpen: boolean): void;
}

const forms = [<FirstStepForm />, <SecondStepForm />, <ThirdStepForm />];

export function SimulationStepsProvider({
  children,
  setIsDialogOpen,
}: SimulationStepsProviderProps): JSX.Element {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentForm, setCurrentForm] = useState<ReactNode>(forms[0]);
  const [cancelButtonText, setCancelButtonText] = useState<string>('Cancelar');
  const [confirmButtonText, setConfirmButtonText] = useState<string>('Próximo');
  const [dialogContentText, setDialogContentText] = useState<string>(
    'Siga os passos para criar uma simulação de crédito.',
  );
  const [
    selectedDealCategory,
    setSelectedDealCategory,
  ] = useState<DealCategoryData>({} as DealCategoryData);
  const [
    selectedDealProduct,
    setSelectedDealProduct,
  ] = useState<DealProductData>({} as DealProductData);
  const [availableDealsProducts, setAvailableDealsProducts] = useState<
    DealProductData[]
  >([] as DealProductData[]);
  const [personType, setPersonType] = useState<string>('');
  const [value, setValue] = useState<number>(0);
  const [birthday, setBirthday] = useState<Date>(new Date());
  const [numberOfInstallments, setNumberOfInstallments] = useState<number>(0);
  const [amortizationType, setAmortizationType] = useState<string>('');
  const [paymentEntry, setPaymentEntry] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [cpf_cnpj, setCPF_CNPJ] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const { dealsProducts } = useDealsProducts();

  const [isMutationLoading, setIsMutationLoading] = useState<boolean>(false);
  const [isBackButtonDisabled, setIsBackButtonDisabled] = useState<boolean>(
    false,
  );
  const [nextButtonText, setNextButtonText] = useState<string>('Próximo');

  useEffect(() => {
    setIsBackButtonDisabled(currentStep === 0 || currentStep === 2);
  }, [currentStep]);

  useEffect(() => {
    setNextButtonText(currentStep === forms.length - 1 ? 'fechar' : 'Próximo');
  }, [currentStep]);

  const handleOnClose = useCallback(() => {
    setIsDialogOpen(false);
    setCurrentStep(0);
    setSelectedDealCategory({} as DealCategoryData);
    setCancelButtonText('Cancelar');
    setConfirmButtonText('Próximo');
    setDialogContentText('Siga os passos para criar uma simulação de crédito.');
    window.location.reload();
  }, [setIsDialogOpen]);

  const { simulations, setSimulations } = useSimulation();

  const handleOnCreateSimulationMutationCompleted = useCallback(
    ({
      createSimulation: createdSimulation,
    }: CreateSimulationMutationResponse) => {
      setSimulations([
        ...simulations.map((simulation) => simulation),
        createdSimulation,
      ]);
      toast.success('Simulação criada com sucesso');
      setConfirmButtonText('Finalizar');
      setDialogContentText(
        'Parabéns, a simulação do crédito foi criada com sucesso',
      );
      setCurrentStep(currentStep + 1);
    },
    [currentStep, setSimulations, simulations],
  );

  const handleOnCreateSimulationMutationError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(`Não foi possível criar a simulação: ${message}`);
    },
    [],
  );

  const [
    createSimulationMutation,
    { loading: isCreateSimulationMutationLoading },
  ] = useMutation<
    CreateSimulationMutationResponse,
    CreateSimulationMutationVars
  >(CREATE_SIMULATION, {
    onCompleted: handleOnCreateSimulationMutationCompleted,
    onError: handleOnCreateSimulationMutationError,
  });

  const createSimulation = useCallback(
    async (data: CreateSimulationMutationVars) => {
      await createSimulationMutation({
        variables: data,
      });
    },
    [createSimulationMutation],
  );

  useEffect(() => {
    if (currentStep < forms.length) {
      setCurrentForm(forms[currentStep]);
    }
  }, [currentStep]);

  useEffect(() => {
    setAvailableDealsProducts(
      dealsProducts.filter(
        (dealProduct) =>
          dealProduct.dealCategory.id === selectedDealCategory.id,
      ),
    );
  }, [dealsProducts, selectedDealCategory]);

  return (
    <SimulationStepsContext.Provider
      value={{
        createSimulation,
        currentStep,
        setCurrentStep,
        cancelButtonText,
        setCancelButtonText,
        confirmButtonText,
        setConfirmButtonText,
        dialogContentText,
        setDialogContentText,
        currentForm,
        setCurrentForm,
        selectedDealCategory,
        setSelectedDealCategory,
        selectedDealProduct,
        setSelectedDealProduct,
        availableDealsProducts,
        setAvailableDealsProducts,
        personType,
        setPersonType,
        value,
        setValue,
        numberOfInstallments,
        setNumberOfInstallments,
        amortizationType,
        setAmortizationType,
        email,
        setEmail,
        phone,
        setPhone,
        name,
        setName,
        cpf_cnpj,
        setCPF_CNPJ,
        birthday,
        setBirthday,
        handleOnClose,
        isCreateSimulationMutationLoading,
        isMutationLoading,
        setIsMutationLoading,
        isBackButtonDisabled,
        setIsBackButtonDisabled,
        nextButtonText,
        setNextButtonText,
        paymentEntry,
        setPaymentEntry,
      }}
    >
      {children}
    </SimulationStepsContext.Provider>
  );
}

export function useSimulationSteps(): SimulationStepsContextData {
  const context = useContext(SimulationStepsContext);
  if (!context) {
    throw new Error(
      'UseSimulationSteps should be used within a SimulationStepsProvider',
    );
  }
  return context;
}
