import styled from 'styled-components';
import { Form } from '@unform/web';
import { withStyles } from '@material-ui/core';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import InputNumber from '../../../components/InputNumber';
import colors from '../../../styles/colors';

export const Container = styled(Form)`
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const RowDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CustomizedButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputCustomized = withStyles({
  root: {
    '& input': {
      color: colors.text.fourth,
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54);',
    },
    '& label.Mui-focused': {
      color: colors.primary.normal.third,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: colors.primary.normal.first,
    },
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: colors.primary.light.first,
    },
  },
})(Input);

export const InputNumberFormattedCustomized = withStyles({
  root: {
    '& input': {
      color: colors.text.fourth,
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54);',
    },
    '& label.Mui-focused': {
      color: colors.primary.normal.third,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: colors.primary.normal.first,
    },
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: colors.primary.light.first,
    },
  },
})(InputNumber);
