import { Timeline, PeopleAlt, TrendingUp, TrendingDown } from '@material-ui/icons';
import React from 'react';
import { useCompanies } from '../../../../hooks/ListCompanies';
/* import { useAdmin } from '../../hooks/Admin'; */
import Card from './Card';
import { Container, Content } from './style';
import colors from '../../../../styles/colors';

function CardsContainer(): JSX.Element {
  const { companies, newAccountsThisMonth, accountsSuspendedThisMonth } = useCompanies();

  const activeAccounts = companies.filter(
    (company) => company.isSuspended === false,
  );

  const inactiveAccounts = companies.filter(
    (company) => company.isSuspended === true,
  );

  return (
    <Container>
      <Content>
        <Card
          title="Total de Contas ativas"
          value={activeAccounts.length}
          icon={PeopleAlt}
          iconBackgroundColor={colors.primary.darker.first}
        />
        <Card
          title="Total de Contas inativas"
          value={inactiveAccounts.length}
          icon={Timeline}
          iconBackgroundColor={colors.warning.third}
        />
        {/* <Card
        title="Acessos na plataforma"
        value={numberOfPlatformAccess}
        icon={DesktopWindows}
      /> */}
        <Card
          title="Contas novas esse mês"
          value={newAccountsThisMonth}
          icon={TrendingUp}
          iconBackgroundColor={colors.primary.normal.first}
        />
        <Card
          title="Contas suspensas esse mês"
          value={accountsSuspendedThisMonth}
          icon={TrendingDown}
          iconBackgroundColor={colors.error.third}
        />

      </Content>
    </Container>
  );
}

export default CardsContainer;
