const validateCPF = (cpf: string): boolean => {
  if (typeof cpf !== 'string') return false;

  const structuredCPF = cpf.replace(/[^\d]+/g, '');

  if (structuredCPF.length !== 11 || !!structuredCPF.match(/(\d)\1{10}/))
    return false;

  const splittedCPF = cpf.split('');

  const validator = splittedCPF
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map((el) => +el);

  const toValidate = (pop: number) =>
    splittedCPF
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map((el) => +el);

  const rest = (count: number, pop: number) =>
    ((toValidate(pop)
      // Calcular Soma dos digitos e multiplicar por 10
      .reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      // Pegar o resto por 11
      11) %
    // transformar de 10 para 0
    10;

  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
};

export default validateCPF;
