import { gql } from '@apollo/client';

export interface requestRecoverPasswordMutationVars {
  email: string;
}

export interface requestRecoverPasswordMutationData {
  wasMailSent: boolean;
}

export interface requestRecoverPasswordMutationResponse {
  requestRecoverPassword: requestRecoverPasswordMutationData;
}

const REQUEST_RECOVER_PASSWORD = gql`
  mutation requestRecoverPassword($email: String!) {
    requestRecoverPassword(data: { email: $email }) {
      wasMailSent
    }
  }
`;

export default REQUEST_RECOVER_PASSWORD;
