import { Button, IconButton } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { DealCategoryData } from '../../../../../../GraphQL/queries/getDealsCategories';
import { useCompany } from '../../../../../../hooks/Company';
import { Container } from './style';

interface BusinessSectionProps {
  dealCategory: DealCategoryData;
}

function BusinessSection({ dealCategory }: BusinessSectionProps): JSX.Element {
  const { company } = useCompany();
  const [url, setURL] = useState<string>('');
  const handleOnCopyLinkButton = useCallback(() => {
    navigator.clipboard.writeText(url);
    toast.success(`O Link de captação foi copiado com sucesso`);
  }, [url]);
  useEffect(() => {
    setURL(`${dealCategory.url}/${company.id}` || '');
  }, [company, dealCategory]);
  return (
    <Container>
      <a href={url}>
        <Button
          size="small"
          style={{
            textTransform: 'none',
            textAlign: 'left',
            width: '100%',
            height: '100%',
            justifyContent: 'left',
          }}
        >
          {dealCategory.name}
        </Button>
      </a>
      <IconButton onClick={handleOnCopyLinkButton}>
        <FileCopy />
      </IconButton>
    </Container>
  );
}

export default BusinessSection;
