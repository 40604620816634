import React, { ComponentType } from 'react';

import {
  Redirect,
  Route as ReactRoute,
  RouteProps as ReactDOMRouteProps,
} from 'react-router-dom';
import { useAuth } from '../../hooks/Auth';
import { useRole } from '../../hooks/Role';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  permission?: number;
  component: ComponentType;
}

function Route({
  permission = 0,
  isPrivate = false,
  component: Component,
  ...rest
}: RouteProps): JSX.Element {
  const { isCompanyLogged } = useAuth();

  const { verifyIfHasPermission } = useRole();

  return (
    <ReactRoute
      {...rest}
      render={() => {
        if (isCompanyLogged || !isPrivate) {
          const hasPermission = verifyIfHasPermission(permission);
          return hasPermission ? (
            <Component />
          ) : (
            <Redirect to={{ pathname: '/' }} />
          );
        }
        return <Redirect to={{ pathname: '/login' }} />;
      }}
    />
  );
}

export default Route;
