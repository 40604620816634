import { withStyles } from '@material-ui/core';
import InputNumber from '..';
import colors from '../../../styles/colors';

const InputNumberLight = withStyles({
  root: {
    '& input': {
      color: colors.text.fourth,
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54);',
    },
    '& label.Mui-focused': {
      color: colors.primary.normal.first,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: colors.primary.normal.first,
    },
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: colors.primary.light.first,
    },
  },
})(InputNumber);

export default InputNumberLight;
