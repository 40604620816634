import styled from 'styled-components';

export const Container = styled.section`
  display: grid;
  width: 100%;
`;

export const Content = styled.article`
  display: flex;
  width: 100%;
  overflow-x: auto;
`;


