import { gql } from '@apollo/client';

export interface reactivatedCompanyIDMutationVars {
  companyID: string;
  expiresIn: string;
}

export interface reactivatedCompanyIDMutationResponse {
  getReactivatedCompanyID: string;
}

const reactivateCompany = gql`
  mutation reactivateCompany($companyID: String!, $expiresIn: String!) {
    reactivateCompany(data: { companyID: $companyID, expiresIn: $expiresIn }) {
      id
    }
  }
`;

export default reactivateCompany;
