import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Content = styled.article`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: stretch;
  gap: 1rem;
  margin: 0 auto;
  transition: all 0.3s ease;

  @media screen and (max-width: 1500px){
    max-width: 90%;
    margin: 0 auto;
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 900px){
    flex-direction: column;
  }

`;
