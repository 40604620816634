import { gql } from '@apollo/client';

export interface createBasicCompanyVars {
  email: string;
  name: string;
  phone: string;
}

export interface createBasicCompanyData {
  name: string;
}

export interface createBasicCompanyResponse {
  createBasicCompany: createBasicCompanyData;
}

const CREATE_BASIC_COMPANY = gql`
  mutation createBasicCompany(
    $email: String!
    $name: String!
    $phone: String!
  ) {
    createBasicCompany(data: { email: $email, name: $name, phone: $phone }) {
      name
    }
  }
`;

export default CREATE_BASIC_COMPANY;
