import { gql } from '@apollo/client';

export interface getContactsQueryVars {
  page?: number;
}

export interface emailObject {
  VALUE: string;
}

export interface phoneObject {
  VALUE: string;
}

export interface Contact {
  ID: number;
  NAME: string;
  DATE_CREATE: Date;
  DATE_MODIFY: Date;
  EMAIL: emailObject[];
  PHONE: phoneObject[];
  UF_CRM_1607694757: string;
  UF_CRM_1602185690: string;
}

export interface getContactsQueryResponse {
  result: Contact[];
  total: number;
  next: number;
}

export interface getContactsQueryData {
  getContacts: getContactsQueryResponse;
}

const GET_CONTACTS = gql`
  query getContacts($page: Int) {
    getContacts(data: { page: $page }) {
      total
      next
      result {
        ID
        NAME
        DATE_CREATE
        DATE_MODIFY
        EMAIL {
          VALUE
        }
        PHONE {
          VALUE
        }
      }
    }
  }
`;

export default GET_CONTACTS;
