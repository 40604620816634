const colorsTheme = {
  primary: {
    main: '#3F9177',
  },
  secondary: {
    main: '#20618F',
  },
  success: {
    main: '#02DB9A',
  },
  error: {
    main: '#DB0242',
  },
  warning: {
    main: '#F18805',
  },
  info: {
    main: '#246C9F',
  },
  default: {
    main: '#363C4C',
  },
};

export { colorsTheme };
