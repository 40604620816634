import { ApolloError, useQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import GET_DEALS_PRODUCTS, {
  DealProductData,
  getDealSProductsQueryResponse,
} from '../GraphQL/queries/getDealsProducts';

interface DealsProductsContextData {
  dealsProducts: DealProductData[];
  setDealsProducts(dealsProducts: DealProductData[]): void;
  isGetDealsProductsQueryLoading: boolean;
}

const DealsProductsContext = createContext<DealsProductsContextData>(
  {} as DealsProductsContextData,
);

interface DealsProductsProviderProps {
  children: ReactNode;
}

export function DealsProductsProvider({
  children,
}: DealsProductsProviderProps): JSX.Element {
  const [dealsProducts, setDealsProducts] = useState<DealProductData[]>(
    [] as DealProductData[],
  );
  const handleOnGetDealsProductsQueryCompleted = useCallback(
    ({
      getDealsProducts: fetchedDealsProducts,
    }: getDealSProductsQueryResponse) => {
      setDealsProducts(fetchedDealsProducts);
    },
    [],
  );

  const handleOnGetDealsProductsQueryError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(
        `Não foi possível obter os produtos dos tipos de negócios: ${message}`,
      );
    },
    [],
  );

  const { loading: isGetDealsProductsQueryLoading } = useQuery(
    GET_DEALS_PRODUCTS,
    {
      onCompleted: handleOnGetDealsProductsQueryCompleted,
      onError: handleOnGetDealsProductsQueryError,
    },
  );

  return (
    <DealsProductsContext.Provider
      value={{
        dealsProducts,
        isGetDealsProductsQueryLoading,
        setDealsProducts,
      }}
    >
      {children}
    </DealsProductsContext.Provider>
  );
}

export function useDealsProducts(): DealsProductsContextData {
  const context = useContext(DealsProductsContext);
  if (!context) {
    throw new Error(
      'useDealsProducts must be used within a DealsProductsProvider',
    );
  }
  return context;
}
