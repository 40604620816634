import styled from 'styled-components';

export const Container = styled.div`
  width: 350px;
  height: min-content;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: column;
  padding: 37px;
  gap: 11px;
  background-color: #fff;
  border-radius: 5px;
  & > h1 {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 20.09px;
    text-align: justify;
  }
  & > h2 {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 18.75px;
    text-align: justify;
  }
`;
