import React, { ComponentType, useEffect, useState, useCallback } from 'react';

import { IconBaseProps } from 'react-icons/lib';
import { useHistory, useLocation } from 'react-router-dom';
import { useNavbarHook } from '../../hooks/NavbarHook';

import { Container } from './style';

interface ISidebarLink {
  path: string;
  label: string;
  icon: ComponentType<IconBaseProps>;
  isExternal?: boolean;
  className?: string;
}

function SidebarLink({
  className,
  icon: Icon,
  label,
  path,
  isExternal,
}: ISidebarLink): JSX.Element {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { isSidebarCompressed } = useNavbarHook();
  const history = useHistory();

  const handleOnClick = useCallback(() => {
    if (isExternal) {
      window.open(path);
    } else {
      history.push(path);
    }
  }, [history, isExternal, path]);

  useEffect(() => {
    setIsActive(pathname === path);
  }, [path, pathname]);
  return (
    <Container
      onClick={handleOnClick}
      isActive={isActive}
      isSidebarCompressed={isSidebarCompressed}
      className={className}
    >
      <Icon />
      {!isSidebarCompressed && <p>{label}</p>}
    </Container>
  );
}

export { SidebarLink };
