/* eslint-disable no-unneeded-ternary */
import { ApolloError, useMutation } from '@apollo/client';
import { Mail } from '@material-ui/icons';
import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../../../components/Button';
import InputNumberLight from '../../../../../components/InputNumber/InputNumberLight';
import RadioGroup from '../../../../../components/RadioGroup';
import SEND_EMAIL_TO_CREATE_COMPANY, {
  requestsendMailToCreateCompanyMutationVars,
  requestsendMailToCreateCompanyMutationResponse,
} from '../../../../../GraphQL/mutations/sendMailToCreateCompany';
import getValidationErrors from '../../../../../utils/getValidationErrors';
/* import CSVButton from './CSVButton'; */
import { Container, InputCustomized, ButtonsContainer, Text } from './style';

function NewUser(): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const handleOnRequestCreateCompanyMutationComplete = useCallback(
    ({
      sendMailToCreateCompany,
    }: requestsendMailToCreateCompanyMutationResponse) => {
      if (sendMailToCreateCompany) {
        toast.success(
          'O email de cadastro na plataforma foi enviado com sucesso',
        );
      } else {
        toast.info(
          'Não foi possível enviar o email de cadastro, verifique as informações de email',
        );
      }
    },
    [],
  );

  const handleOnRequestCreateCompanyMutationError = useCallback(
    (error: ApolloError) => {
      toast.warn(
        `Não foi possível enviar o email de cadastro: ${error.message}`,
      );
    },
    [],
  );

  const [sendMailToCreateCompany, { loading }] = useMutation<
    requestsendMailToCreateCompanyMutationResponse,
    requestsendMailToCreateCompanyMutationVars
  >(SEND_EMAIL_TO_CREATE_COMPANY, {
    onCompleted: handleOnRequestCreateCompanyMutationComplete,
    onError: handleOnRequestCreateCompanyMutationError,
  });

  const handleOnSubmit = useCallback(
    async (data: requestsendMailToCreateCompanyMutationVars) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Você precisa digitar um email')
            .email('Digite um email válido'),
          name: Yup.string().required('Você precisa inserir o nome do usuário'),
          expiresIn: Yup.string().required(
            'Você precisa inserir o tempo de validade',
          ),
          eduzzBillID: Yup.number().required(
            'Caso o usuário não possua um ID da Eduzz, preencha o campo com um 0',
          ),
          recurrence_code: Yup.number().required(
            'Caso o usuário não possua um código de recorrência, preencha o campo com um 0',
          ),
          haveLifetimeAccess: Yup.string().required(
            'Você precisa inserir se o usuário tem acesso vitalício',
          ),
        });
        await schema.validate(data, { abortEarly: false });
        await sendMailToCreateCompany({
          variables: {
            name: data.name,
            email: data.email,
            expiresIn: data.expiresIn,
            eduzzBillID: Number(data.eduzzBillID),
            recurrence_code: Number(data.recurrence_code),
            haveLifetimeAccess:
              data.haveLifetimeAccess.toString() === 'true' ? true : false,
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          toast.warning('Por favor, preencha os dados corretamente');
          return;
        }
        toast.warning('Houve um erro ao enviar os dados para o servidor');
      }
    },
    [sendMailToCreateCompany],
  );
  return (
    <>
      <Container onSubmit={handleOnSubmit} ref={formRef}>
        <InputCustomized name="name" label="Nome" />
        <InputCustomized name="email" label="E-mail" />
        <InputCustomized name="expiresIn" label="Expira em" />
        <InputNumberLight name="eduzzBillID" label="ID Eduzz" />
        <InputNumberLight
          name="recurrence_code"
          label="Código de recorrência"
        />
        <Text>
          Caso não tenha os códigos acima (ID Eduzz e Recorrência), basta
          preencher o campo com o zero ( 0 )
        </Text>
        <RadioGroup
          legend="Acesso vitalício"
          name="haveLifetimeAccess"
          options={[
            {
              label: 'Sim',
              value: 'sim',
            },
            {
              label: 'Não',
              value: 'não',
            },
          ]}
        />
        <ButtonsContainer>
          <Button fullWidth type="submit" endIcon={<Mail />}>{`${
            loading ? 'Enviando' : 'Enviar'
          } email de cadastro`}</Button>
        </ButtonsContainer>
        <Text>
          Após clicar no botão acima, pedir para o usuário verificar o email,
          irá chegar um formulário para o cliente preencher com os seus dados.
        </Text>
        {/* <CSVButton /> */}
      </Container>
    </>
  );
}

export default NewUser;
