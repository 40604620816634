import React from 'react';
import Navbar from '../../templates/DashboardTemplate/Navbar';
import ProductBox from './ProductBox';

function Links(): JSX.Element {
  return (
    <>
      <Navbar title="Links de Captação" />
      <ProductBox />
    </>
  );
}

export default Links;
