import { IconButton } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { AxiosRequestConfig } from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { IoMdSend } from 'react-icons/io';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { BitrixDealData } from '../../../../../../../../../../../../../../../GraphQL/queries/getDealsUpdated';
import { useAuth } from '../../../../../../../../../../../../../../../hooks/Auth';
import api from '../../../../../../../../../../../../../../../services/api';
import getValidationErrors from '../../../../../../../../../../../../../../../utils/getValidationErrors';
import { useDealComments } from '../../../../../../hooks/Comments';
import Input from './components/Input';
import { InputFile } from './components/InputFile';
import { ButtonsContainer, Container } from './style';


interface ICommentFormProps {
  deal: BitrixDealData;
}

interface ICommentDealFormData {
  comment: string;
  files: File[];
}
function CommentForm({ deal }: ICommentFormProps): JSX.Element {
  const formRef = useRef<FormHandles>(null);

  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);

  const { token } = useAuth();

  const { comments, setComments } = useDealComments();

  const handleOnSubmit = useCallback(
    async (data: ICommentDealFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          comment: yup.string().required('Você precisa comentar algo'),
        });
        await schema.validate(data, { abortEarly: false });
        const formData = new FormData();
        formData.append('comment', data.comment);
        if (data.files) {
          for (let i = 0; i < data.files.length; i += 1) {
            formData.append('files', data.files[i]);
          }
        }
        formData.append('dealID', String(deal.ID));
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        };
        setIsRequestLoading(true);
        const response = await api.post('/deal/comment', formData, config);
        if (response.status === 201) {
          const successMessage = 'Comentário adicionado com sucesso';
          toast.success(successMessage);
          setIsRequestLoading(false);
          const createdComment = response.data;
          setComments([...comments, createdComment]);
          formRef.current?.setData({
            comment: '',
            files: null,
          });
        }
      } catch (error) {
        setIsRequestLoading(false);
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          const warningMessage =
            'Por favor, preencha o formulário corretamente.';
          toast.warn(warningMessage);
        }
      }
    },

    [comments, deal, setComments, token],
  );
  return (
    <Container ref={formRef} onSubmit={handleOnSubmit}>
      <Input
        disabled={isRequestLoading}
        name="comment"
        color="primary"
        label="Digite uma mensagem"
      />
      <ButtonsContainer>
        <InputFile name="files" disabled={isRequestLoading} />
        <IconButton color="primary" type="submit" disabled={isRequestLoading}>
          <IoMdSend />
        </IconButton>
      </ButtonsContainer>
    </Container>
  );
}

export { CommentForm };
