import React, { createContext, ReactNode, useContext } from 'react';

import { DealCategoryStageData } from '../../../../../../../GraphQL/queries/getDealsCategoryStages';

interface DealStageContextData {
  dealStage: DealCategoryStageData;
}

const dealStageContext = createContext<DealStageContextData>(
  {} as DealStageContextData,
);

interface IDealStageProvider {
  children: ReactNode;
  dealStage: DealCategoryStageData;
}

export function DealStageProvider({
  children,
  dealStage,
}: IDealStageProvider): JSX.Element {
  return (
    <dealStageContext.Provider value={{ dealStage }}>
      {children}
    </dealStageContext.Provider>
  );
}

export function useDealStage(): DealStageContextData {
  const context = useContext(dealStageContext);
  if (!context) {
    throw new Error('useDealStage must be used within a DealStageProvider');
  }
  return context;
}
