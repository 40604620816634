import React from 'react';

import { Container, ImageContainer } from './style';

interface OnboardContainerProps {
  title: string;
  imageSource?: string;
  description: string;
}

function OnboardContainer({
  title,
  imageSource,
  description,
}: OnboardContainerProps): JSX.Element {
  return (
    <Container>
      <h1>{title}</h1>
      {imageSource && (
        <ImageContainer>
          <img src={imageSource} alt="Onboard Tutorial" />
        </ImageContainer>
      )}
      <p>{description}</p>
    </Container>
  );
}

export default OnboardContainer;
