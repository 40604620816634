import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { ApolloError, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FaExclamationCircle } from 'react-icons/fa';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { Container, ButtonsContainer, InputCustomizedRed, Text } from './style';
import Button from '../../../../components/Button';
import { useCompanies } from '../../../../hooks/ListCompanies';
import { ICompany } from '../../../../GraphQL/queries/getCompany';
import suspendCompany, {
  suspendCompanyIDMutationVars,
  suspendCompanyIDMutationResponse,
} from '../../../../GraphQL/mutations/suspendCompany';

const SuspendCompany = (): JSX.Element => {
  const formRefs = useRef<FormHandles>(null);
  const [selectedEmail, setSelectedEmail] = useState<string>('');
  const { companies } = useCompanies();

  const findCompanyIdByEmail = useCallback(
    (email) => {
      const selectedCompany = companies.find(
        (company: ICompany) => company.email === email,
      );
      return selectedCompany?.id ? selectedCompany.id : '';
    },
    [companies],
  );

  const handleOnSuspendedCompanyMutationCompleted = useCallback(() => {
    toast.success(`O usuário foi suspenso com sucesso!`);
    setSelectedEmail('');
  }, []);

  const handleOnSuspendedCompanyMutationError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(
        `Não foi possível suspender a empresa selecionada : ${message}`,
      );
    },
    [],
  );

  const [getSuspendedCompanyID, { loading }] = useMutation<
    suspendCompanyIDMutationResponse,
    suspendCompanyIDMutationVars
  >(suspendCompany, {
    onCompleted: handleOnSuspendedCompanyMutationCompleted,
    onError: handleOnSuspendedCompanyMutationError,
  });

  const handleOnSubmit = useCallback(
    async () => {
      formRefs.current?.setErrors({});
      try {
        const companyID = findCompanyIdByEmail(selectedEmail);
        if (!companyID) {
          toast.warning('E-mail não encontrado');
          return;
        }
        await getSuspendedCompanyID({
          variables: {
            companyID,
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefs.current?.setErrors(errors);
          toast.warning('Por favor, preencha os dados corretamente');
          return;
        }
        toast.warning('Houve um erro ao enviar os dados para o servidor');
      }
    },
    [findCompanyIdByEmail, getSuspendedCompanyID, selectedEmail],
  );
  return (
      <Container onSubmit={handleOnSubmit}  ref={formRefs}>
        <InputCustomizedRed
          name="email"
          label="E-mail"
          fullWidth
          value={selectedEmail}
          variant="filled"
          onChange={(e) => setSelectedEmail(e.target.value)}
        />
        <ButtonsContainer>
          <Button
            fullWidth
            type="submit"
            endIcon={<FaExclamationCircle />}
            style={{
              backgroundColor: '#AF0235',
              color: '#FFFFFF',
              fontSize: '0,8rem',
              display: 'flex',
              justifyContent: 'space-between',
              textTransform: 'none',
              padding: '10px 15px',
            }}
          >
            {`${loading ? 'Suspendendo' : 'Suspender'}`}
          </Button>
        </ButtonsContainer>
        <Text>ATENÇÃO: esta ação irá suspender o usuário, ele não terá mais acesso à plataforma.</Text>
        {/* <CSVButton /> */}
      </Container>
  );
};

export default SuspendCompany;
