import { gql } from '@apollo/client';
import { ICompany } from '../queries/getCompany';

export interface updateProfileMutationVars {
  name: string;
  personName: string;
  password: string;
  phone: string;
}

export interface UpdateProfileMutationResponse {
  updateProfile: ICompany;
}

const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $name: String!
    $personName: String!
    $password: String!
    $phone: String!
  ) {
    updateProfile(
      data: {
        name: $name
        personName: $personName
        password: $password
        phone: $phone
      }
    ) {
      id
      name
      personName
      email
      phone
      cpf_cnpj
    }
  }
`;

export default UPDATE_PROFILE;
