import styled from 'styled-components';

export const Container = styled.div`
  width: 400px;
  height: 302px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  background-color: #fff;
  border: 0.5px solid #ebebf9;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  & > h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    /* or 127% */
    /* Text/Text Darker */
    color: #071620;
  }
  & > p {
    text-align: justify;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */
    /* Text/Text Dark */
    color: #363c4c;
  }
`;

export const ButtonFilled = styled.div`
  background: #46a184;
  mix-blend-mode: normal;
  border-radius: 4px;
  color: #fff;
`;

export const ButtonOutlined = styled.div`
  border: 1px solid #31715c;
  box-sizing: border-box;
  border-radius: 4px;
  color: #31715c;
`;

export const ControllerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  & > div {
    width: 100%;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;
