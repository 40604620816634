import { Form } from '@unform/web';
import styled from 'styled-components';
import colors from '../../../../../../styles/colors';

export const Container = styled(Form)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;

  & > h2 {
    font-weight: 500;
    font-size: 14.8571px;
    line-height: 15px;
    color: ${colors.text.third};
  }
`;

export const InputSection = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ButtonsSection = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;
