import { IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import React, { ReactNode, useCallback } from 'react';
import { useNavbarHook } from '../hooks/NavbarHook';

import { Container, Title, Article } from './style';
import UserController from './UserController';

interface NavbarProps {
  title: string;
  children?: ReactNode;
}

function Navbar({ title, children }: NavbarProps): JSX.Element {
  const { isSidebarCompressed, setIsSidebarCompressed } = useNavbarHook();

  const handleOnBurgerClick = useCallback(() => {
    setIsSidebarCompressed(false);
  }, [setIsSidebarCompressed]);
  return (
    <Container>
      {isSidebarCompressed && (
        <IconButton
          style={{
            width: 0,
            height: 0,
          }}
          onClick={handleOnBurgerClick}
        >
          <Menu />
        </IconButton>
      )}
      <Title>{title}</Title>
      {children && <Article>{children}</Article>}

      <UserController />
    </Container>
  );
}

export default Navbar;
