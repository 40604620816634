import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  padding: 1.875rem 1.188rem;

  background: #fbfbfb;
  border-radius: 5px;

  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
`;
