import React, { useCallback, useState } from 'react';
import { BitrixDealData } from '../../../../../../../GraphQL/queries/getDealsUpdated';
import { useAuth } from '../../../../../../../hooks/Auth';
import formatDate from '../../../../../../../utils/formateDate';
import formatToPTBRMoneyValue from '../../../../../../../utils/formatMoney';
import { DealDialog } from './Components/DealDialog';
import Transition from './Components/DealDialog/components/Transition';
import { DealProvider } from './hooks/Deal';
import { Container } from './style';

export interface DealCardProps {
  deal: BitrixDealData;
}

function DealCard({ deal }: DealCardProps): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedDeal, setSelectedDeal] = useState<number>(0);
  const { isCompanyLogged } = useAuth();
  const handleOnDealCardClick = useCallback((id: number) => {
    setSelectedDeal(id);
    setIsDialogOpen(true);
  }, []);
  const handleOnClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return (
    <DealProvider deal={deal} selectedDeal={selectedDeal}>
      <Container onClick={() => handleOnDealCardClick(deal.ID)}>
        <h2>{deal.TITLE}</h2>
        <p>{formatDate(deal.DATE_CREATE)}</p>
        <h1>{formatToPTBRMoneyValue(deal.OPPORTUNITY)}</h1>
      </Container>
      <DealDialog
        handleOnClose={handleOnClose}
        TransitionComponent={Transition}
        open={isDialogOpen}
        fullWidth
        fullScreen
        onClose={handleOnClose}
        keepMounted={isCompanyLogged}
      />
    </DealProvider>
  );
}

export default DealCard;
