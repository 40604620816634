import React from 'react'
import { Container } from './style';

interface NewsCardProps {
  image?: string;
  style?: React.CSSProperties;
}

const NewsCard = ({
  image,
  style,
}:NewsCardProps): JSX.Element=> {
  return (
    <Container>
      <img src={image} alt="Foto" style={style}/>
    </Container>
  )
}

export default NewsCard
