import { gql } from '@apollo/client';

const REMOVE_PROFILE_PICTURE = gql`
  mutation removeProfilePicture {
    removeProfilePicture {
      id
      name
      personName
      email
      phone
      cpf_cnpj
      avatarFile
    }
  }
`;

export default REMOVE_PROFILE_PICTURE;
