import { IconButton, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { SimulationData } from '../../../../../../../GraphQL/mutations/createSimulation';

import DeleteSimulationConfirmDialog from './DeleteSimulationConfirmDialog';

interface DeleteSimulationButtonProps {
  simulation: SimulationData;
  handleOnCloseMainDialog(): void;
}

function DeleteSimulationButton({
  simulation,
  handleOnCloseMainDialog,
}: DeleteSimulationButtonProps): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const handleOnButtonClick = useCallback(() => {
    setIsDialogOpen(true);
  }, []);
  const handleOnClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);
  return (
    <>
      <Tooltip title="Excluir Simulação">
        <IconButton color="secondary" onClick={handleOnButtonClick}>
          <Delete />
        </IconButton>
      </Tooltip>
      <DeleteSimulationConfirmDialog
        isOpen={isDialogOpen}
        handleOnClose={handleOnClose}
        simulation={simulation}
        handleOnCloseMainDialog={handleOnCloseMainDialog}
      />
    </>
  );
}

export default DeleteSimulationButton;
