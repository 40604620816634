import { withStyles } from '@material-ui/core';
import Input from '..';
import colors from '../../../styles/colors';

const InputLight = withStyles({
  root: {
    '& input': {
      color: colors.text.fourth,
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& label.Mui-focused': {
      color: colors.primary.normal.third,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: colors.primary.normal.first,
    },
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: colors.primary.light.first,
    },
  },
})(Input);

export default InputLight;
