import React from 'react';

import formatToPTBRMoneyValue from '../../../../../../../../../../../../../../../../../utils/formatMoney';
import { useBusiness } from '../../../../../../../../../../../../../../../hooks/Business';
import { useDealStage } from '../../../../../../../../../../../hooks/DealStage';
import { useDeal } from '../../../../../../../../../../hooks/Deal';

import { Container, LabelContainer, AmountContainer } from './style';

function ValueContainer(): JSX.Element {
  const { deal } = useDeal();
  const { dealStage } = useDealStage();
  const { selectedDealCategory } = useBusiness();
  return (
    <Container>
      <LabelContainer>
        <p>{selectedDealCategory.name}</p>
        <h2>{dealStage.NAME}</h2>
      </LabelContainer>
      <AmountContainer>
        {formatToPTBRMoneyValue(deal.OPPORTUNITY)}
      </AmountContainer>
    </Container>
  );
}

export { ValueContainer };
