import { MenuItem } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { useDealsCategories } from '../../../../hooks/DealsCategories';
import { useBusiness } from '../../hooks/Business';
import { TextFieldCustomized } from './style';

function DealCategorySelect(): JSX.Element {
  const { setSelectedDealCategory, selectedDealCategory } = useBusiness();
  const { dealsCategories } = useDealsCategories();

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const selectedValue = event?.target.value;
      const dealCategorySelected = dealsCategories.find(
        (dealCategory) => dealCategory.id === selectedValue,
      );
      if (typeof dealCategorySelected !== 'undefined') {
        setSelectedDealCategory(dealCategorySelected);
      }
    },
    [dealsCategories, setSelectedDealCategory],
  );
  useEffect(() => {
    if (dealsCategories.length > 0) {
      setSelectedDealCategory(dealsCategories[0]);
    }
  }, [dealsCategories, setSelectedDealCategory]);
  return (
    <TextFieldCustomized
      variant="outlined"
      select
      fullWidth
      onChange={handleOnChange}
      style={{
        whiteSpace: 'nowrap',
      }}
      className="third-step"
      defaultValue={selectedDealCategory.id}
      label="Tipo de Crédito"
      key={selectedDealCategory.id}
    >
      {dealsCategories.map((dealCategory) => (
        <MenuItem value={dealCategory.id} key={dealCategory.id}>
          {dealCategory.name}
        </MenuItem>
      ))}
    </TextFieldCustomized>
  );
}

export default DealCategorySelect;
