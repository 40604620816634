import React, { createContext, ReactNode, useContext } from 'react';
import { BitrixDealData } from '../../../../../../../../GraphQL/queries/getDealsUpdated';

interface IDealContextData {
  deal: BitrixDealData;
  selectedDeal: number;
}

const dealContext = createContext({} as IDealContextData);

interface IDealProvider {
  children: ReactNode;
  deal: BitrixDealData;
  selectedDeal: number;
}

export function DealProvider({ children, deal, selectedDeal }: IDealProvider): JSX.Element {
  return (
    <dealContext.Provider
      value={{
        deal,
        selectedDeal,
      }}
    >
      {children}
    </dealContext.Provider>
  );
}

export function useDeal(): IDealContextData {
  const context = useContext(dealContext);
  if (!context) {
    throw new Error('useDeal must be used within a DealProvider');
  }
  return context;
}
