import { Button, Typography } from '@material-ui/core';
import React from 'react';

import { Container } from './style';

interface SoonBusinessSectionProps {
  name: string;
}

function SoonBusinessSection({ name }: SoonBusinessSectionProps): JSX.Element {
  return (
    <Container>
      <Button
        size="small"
        style={{
          textTransform: 'none',
          textAlign: 'left',
          width: '100%',
          height: '100%',
          justifyContent: 'left',
          whiteSpace: 'nowrap',
        }}
        disabled
      >
        {name}
      </Button>
      <Typography
        variant="caption"
        style={{
          whiteSpace: 'nowrap',
          color: 'rgba(0, 0, 0, 0.26)',
        }}
      >
        Em breve.
      </Typography>
    </Container>
  );
}

export default SoonBusinessSection;
