import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import Tour from 'reactour';
import {
  ApolloError,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import links from '../assets/images/onboard/links.svg';
import kanban1Image from '../assets/images/onboard/kanban_1.svg';
import clientsKanban from '../assets/images/onboard/clientsKanban.svg';
import store from '../assets/images/onboard/store.svg';
import simulation from '../assets/images/onboard/simulation.svg';
import course from '../assets/images/onboard/course.svg';

import OnboardContainer from '../components/OnboardContainer';

import VERIFY_IF_COMPANY_SAW_TUTORIAL, {
  verifyIfCompanySawTutorialQueryResponse,
} from '../GraphQL/queries/verifyIfCompanySawTutorial';
import CONFIRM_TUTORIAL_HAS_BEEN_VIEWED, {
  confirmTutorialHasBeenViewedMutationResponse,
} from '../GraphQL/mutations/confirmTutorialHasBeenViewed';

import { useAuth } from './Auth';

interface Step {
  selector: string;
  content: ReactNode;
}

interface OnboardContextData {
  steps: Step[];
  setSteps(steps: Step[]): void;
  setIsTourOpen(isTourOpen: boolean): void;
  setCurrentStep(currentStep: number): void;
  currentStep: number;
  setHandleOnBeforeClose: Dispatch<SetStateAction<() => void>>;

  verifyIfCompanySawTutorial(
    options?: QueryLazyOptions<OperationVariables>,
  ): void;
  confirmTutorialHasBeenViewed(
    options:
      | MutationFunctionOptions<
          confirmTutorialHasBeenViewedMutationResponse,
          OperationVariables
        >
      | undefined,
  ): Promise<
    FetchResult<
      confirmTutorialHasBeenViewedMutationResponse,
      Record<string, unknown>
    >
  >;
  companyHasBeenViewedTutorial: boolean;
}

const OnboardContext = createContext<OnboardContextData>(
  {} as OnboardContextData,
);

interface OnboardProviderProps {
  children: ReactNode;
}

export function OnboardProvider({
  children,
}: OnboardProviderProps): JSX.Element {
  const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [steps, setSteps] = useState<Step[]>([
    {
      selector: '.first-step',
      content: (
        <OnboardContainer
          title="Loja Virtual"
          imageSource={store}
          description={`Na aba "Minha Loja Virtual" você terá uma área exclusiva com todos os produtos financeiros oferecidos por você! Onde será possível prospectar clientes através dela.`}
        />
      ),
    },
    {
      selector: '.second-step',
      content: (
        <OnboardContainer
          title="Adicionar clientes"
          description="Uma das formas de adicionar um novo cliente é clicando no botão superior e preenchendo as informações necessárias."
        />
      ),
    },
    {
      selector: '.third-step',
      content: (
        <OnboardContainer
          title="Links de Captação"
          description={`Outra forma de adicionar um novo cliente é clicando em "Links de captação" e enviando o formulário para o próprio cliente preencher.`}
          imageSource={links}
        />
      ),
    },
    {
      selector: '.fourth-step',
      content: (
        <OnboardContainer
          title="Seus negócios"
          description="Para encontrar o seu cliente filtre pelo tipo de crédito solicitado no menu de seleção."
        />
      ),
    },
    {
      selector: '.fifth-step',
      content: (
        <OnboardContainer
          title="Acompanhe o processo"
          imageSource={kanban1Image}
          description="A coluna que você encontrará seu cliente
        representará o status do crédito dele. Ao longo dos processos ele passará de uma coluna para a outra."
        />
      ),
    },
    {
      selector: '.sixth-step',
      content: (
        <OnboardContainer
          title="Seus clientes"
          imageSource={clientsKanban}
          description={`Na aba "Clientes" você encontrará as informações de cada cliente que você adicionou na plataforma.`}
        />
      ),
    },
    {
      selector: '.seventh-step',
      content: (
        <OnboardContainer
          title="Simulador"
          imageSource={simulation}
          description={`Na aba "Simulação" você terá a possibilidade de realizar simulações de dos créditos Veicular e Imobiliário, gerar PDFs e enviar aos seus clientes.`}
        />
      ),
    },
    {
      selector: '.eigth-step',
      content: (
        <OnboardContainer
          title="Cursos"
          imageSource={course}
          description={`Na aba "Cursos" você terá acesso à nossa plataforma de ensino, aonde você aprenderá como operar cada tipo de crédito.`}
        />
      ),
    },

  ] as Step[]);

  const defaultHandleOnClose = useCallback(() => {
    setIsTourOpen(false);
  }, []);

  const [handleOnBeforeClose, setHandleOnBeforeClose] = useState(
    () => defaultHandleOnClose,
  );

  const [
    companyHasBeenViewedTutorial,
    setCompanyHasBeenViewedTutorial,
  ] = useState<boolean>(false);

  const history = useHistory();

  const handleOnVerifyIfCompanySawTutorialCompleted = useCallback(
    ({
      verifyIfCompanySawTutorial: didCompanyCompleteTutorial,
    }: verifyIfCompanySawTutorialQueryResponse) => {
      setCompanyHasBeenViewedTutorial(didCompanyCompleteTutorial);
      if (!didCompanyCompleteTutorial) {
        history.push('/onboarding');
      }
    },
    [history],
  );

  const handleOnVerifyIfCompanySawTutorialError = useCallback(
    ({ message }: ApolloError) => {
      const errorMessage = `Não foi possível verificar se o usuário realizou o onboarding ${message}`;
      toast.warn(errorMessage);
    },
    [],
  );

  const [
    verifyIfCompanySawTutorial,
  ] = useLazyQuery<verifyIfCompanySawTutorialQueryResponse>(
    VERIFY_IF_COMPANY_SAW_TUTORIAL,
    {
      onCompleted: handleOnVerifyIfCompanySawTutorialCompleted,
      onError: handleOnVerifyIfCompanySawTutorialError,
    },
  );

  const handleOnConfirmTutorualHasBeenViewedComplete = useCallback(
    ({
      confirmTutorialHasBeenViewed,
    }: confirmTutorialHasBeenViewedMutationResponse) => {
      const { sawTutorial } = confirmTutorialHasBeenViewed;
      setCompanyHasBeenViewedTutorial(sawTutorial);
    },
    [],
  );

  const [
    confirmTutorialHasBeenViewed,
  ] = useMutation<confirmTutorialHasBeenViewedMutationResponse>(
    CONFIRM_TUTORIAL_HAS_BEEN_VIEWED,
    {
      onCompleted: handleOnConfirmTutorualHasBeenViewedComplete,
    },
  );

  const { isCompanyLogged } = useAuth();

  useEffect(() => {
    if (isCompanyLogged) {
      verifyIfCompanySawTutorial();
    }
  }, [isCompanyLogged, verifyIfCompanySawTutorial]);

  return (
    <OnboardContext.Provider
      value={{
        steps,
        setSteps,
        setIsTourOpen,
        setCurrentStep,
        currentStep,
        setHandleOnBeforeClose,
        companyHasBeenViewedTutorial,
        confirmTutorialHasBeenViewed,
        verifyIfCompanySawTutorial,
      }}
    >
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        rounded={8}
        showNumber={false}
        showCloseButton
        showButtons
        accentColor="#38816A"
        goToStep={currentStep}
        onRequestClose={handleOnBeforeClose}
        startAt={currentStep}
        lastStepNextButton={<></>}
      />
      {children}
    </OnboardContext.Provider>
  );
}

export function useOnBoard(): OnboardContextData {
  const context = useContext(OnboardContext);
  if (!context) {
    throw new Error('useOnBoard must be used within a OnboardProvider');
  }
  return context;
}
