import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-auto-flow: row;
  grid-template-rows: min-content 1fr;
  gap: 1rem;
  padding: 1rem;
  background-color: ${colors.background.first};
  & > img {
    width: 200px;
    height: 61px;
    object-fit: contain;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 703px;
  height: 80%;
  display: grid;
  align-content: center;
  align-items: center;
  justify-items: center;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  gap: 1rem;
  padding: 2rem 4rem;
  background: #fbfbfb;
  /* Stroke #1 */
  border: 0.5px solid #ebebf9;
  box-sizing: border-box;
  /* shadow-lg */
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  & > iframe {
    width: 560px;
    height: 315px;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
  & > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #222c3b;
  }
  & > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #222c3b;
  }
  & > Button {
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 1rem;
    & > h1 {
      font-size: 16px;
    }
    & > h2 {
      font-size: 16px;
      text-align: justify;
    }
  }
`;
