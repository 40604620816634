import { gql } from '@apollo/client';

export interface getSACSimulationVars {
  loanAmount: number;
  numberOfInstallments: number;
  loanInterest: number;
  paymentEntry: number;
}

export interface InstallmentData {
  amortization: number;
  amount: number;
  interest: number;
  installment: number;
  paymentEntry: number;
}

export interface getSACSimulationResponse {
  getSACSimulation: InstallmentData[];
}

export const GET_SAC_SIMULATION = gql`
  mutation getSACSimulation(
    $loanAmount: Float!
    $numberOfInstallments: Int!
    $loanInterest: Float!
    $paymentEntry: Float!
  ) {
    getSACSimulation(
      data: {
        loanAmount: $loanAmount
        numberOfInstallments: $numberOfInstallments
        loanInterest: $loanInterest
        paymentEntry: $paymentEntry
      }
    ) {
      amortization
      amount
      interest
      installment
      paymentEntry
    }
  }
`;
