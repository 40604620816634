import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
`;

export const ButtonsContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;
