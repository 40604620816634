import styled from 'styled-components';
import colors from '../../../../../../../../../../../../../../../styles/colors';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export const IconSection = styled.section`
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.813rem;
  background-color: ${colors.primary.normal.first};
  padding: 0.5rem;
  & > svg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${colors.primary.normal.first};
    color: #fff;
  }
  @media screen and (max-width: 768px) {
    width: 3rem;
    height: 3rem;
  }
`;
