import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { ChevronRight } from '@material-ui/icons';
import { LoginMutationVars } from '../../GraphQL/mutations/login';
import { Container, Content, ForgotPassword, SignUpContainer } from './style';
import { useAuth } from '../../hooks/Auth';
import getValidationErrors from '../../utils/getValidationErrors';
import Logo from '../../assets/logo.color.dark.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';

function Login(): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const { signIn, isLoginLoading } = useAuth();
  const handleOnSubmit = useCallback(
    async (data: LoginMutationVars) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Você precisa digitar um email')
            .email('Digite um email válido'),
          password: Yup.string().min(
            8,
            'Sua senha deve conter no mínimo 8 dígitos',
          ),
        });
        await schema.validate(data, { abortEarly: false });
        await signIn(data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          toast.warning('Por favor, preencha os dados corretamente');
          return;
        }
        toast.warning(
          'Houve um erro ao tentar enviar os dados para o servidor',
        );
      }
    },
    [signIn],
  );

  return (
    <Container>
      <Content onSubmit={handleOnSubmit} ref={formRef}>
        <img src={Logo} alt="Logotipo LCC" className="logo" />
        <Input name="email" label="E-mail" />
        <Input name="password" label="Senha" type="password" />
        <ForgotPassword to="/recuperar-senha">Esqueceu a senha?</ForgotPassword>
        <Button
          fullWidth
          type="submit"
          endIcon={<ChevronRight />}
          disabled={isLoginLoading}
        >
          {`${isLoginLoading ? 'Entrando' : 'Entrar'}`}
        </Button>
        <SignUpContainer>
          <p>Ainda não tem uma conta? </p>
          <p>
            Clique
            <Link
              to={{
                pathname: 'https://wa.me/message/KEB7QFSGW6HBF1',
              }}
              target="_blank"
              style={{
                color: '#46A184',
                margin: '0 5px',
              }}
            >
              aqui
            </Link>
            e entre em contato com o nosso time de vendas!
          </p>
        </SignUpContainer>
      </Content>
    </Container>
  );
}

export default Login;
