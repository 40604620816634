import { withStyles } from '@material-ui/core';
import { Form } from '@unform/web';
import styled from 'styled-components';
import Input from '../../../../../components/Input';
import colors from '../../../../../styles/colors';

export const Container = styled(Form)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const ButtonsContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
`;

export const InputCustomized = withStyles({
  root: {
    '& input': {
      color: colors.text.fourth,
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54);',
    },
    '& label.Mui-focused': {
      color: colors.primary.normal.third,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: colors.primary.normal.first,
    },
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: colors.primary.light.first,
    },
  },
})(Input);

export const Text = styled.p`
  font-size: 0.8rem;
  text-align: center;
  color: #888;
`;
