import React from 'react';
import { AiFillDollarCircle } from 'react-icons/ai';
import { Container, IconSection } from './style';
import { ValueContainer } from './components/ValueContainer';
import { CardContainer } from '../../../CardContainer';

function DealInformation(): JSX.Element {
  return (
    <CardContainer>
      <Container>
        <IconSection>
          <AiFillDollarCircle />
        </IconSection>
        <ValueContainer />
      </Container>
    </CardContainer>
  );
}

export { DealInformation };
