import { ApolloError, useMutation } from '@apollo/client';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import Transition from '../../../../../../../components/Transition';
import DELETE_DEAL_PRODUCT, {
  deleteDealProductMutationResponse,
  deleteDealProductMutationVars,
} from '../../../../../../../GraphQL/mutations/deleteDealProduct';
import { DealProductData } from '../../../../../../../GraphQL/queries/getDealsProducts';
import { useDealsProducts } from '../../../../../../../hooks/DealsProducts';
import colors from '../../../../../../../styles/colors';

interface DeleteDealProductButtonProps {
  dealProduct: DealProductData;
}

function DeleteDealProductButton({
  dealProduct,
}: DeleteDealProductButtonProps): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const handleOnDialogClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);
  const handleOnDeleteButtonClick = useCallback(() => {
    setIsDialogOpen(true);
  }, []);
  const { dealsProducts, setDealsProducts } = useDealsProducts();
  const handleOnDeleteDealProductMutationCompleted = useCallback(
    ({
      deleteDealProduct: deletedDealProduct,
    }: deleteDealProductMutationResponse) => {
      setDealsProducts(
        dealsProducts.filter(
          (someDealProduct) => someDealProduct.id !== deletedDealProduct.id,
        ),
      );
      toast.success(
        `O produto ${deletedDealProduct.name} foi removido com sucesso`,
      );
    },
    [dealsProducts, setDealsProducts],
  );

  const handleOnDeleteDealProductMutationError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(`Não foi possível remover o produto: ${message}`);
    },
    [],
  );

  const [
    deleteDealProduct,
    { loading: isDeleteDealProductMutationLoading },
  ] = useMutation<
    deleteDealProductMutationResponse,
    deleteDealProductMutationVars
  >(DELETE_DEAL_PRODUCT, {
    onCompleted: handleOnDeleteDealProductMutationCompleted,
    onError: handleOnDeleteDealProductMutationError,
  });

  const handleOnConfirmDeleteDealProductButtonClick = useCallback(() => {
    deleteDealProduct({
      variables: {
        id: dealProduct.id,
      },
    });
  }, [dealProduct, deleteDealProduct]);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleOnDeleteButtonClick}
      >
        Remover
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={handleOnDialogClose}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle>{`Deletar ${dealProduct.name}`}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'justify' }}>
            Você tem certeza que deseja deletar esse produto? Fazendo isso, os
            clientes não irão poder visualizar esse produto nas simulações e nos
            formulários de requisição de cŕedito. Observação: O produto apenas
            será removido do banco de dados da LCC e não irá afetar os dados dos
            negócios do bitrix.
          </DialogContentText>
          <Backdrop open={isDeleteDealProductMutationLoading}>
            <CircularProgress />
          </Backdrop>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              color: colors.error.second,
            }}
            onClick={handleOnDialogClose}
          >
            fechar
          </Button>
          <Button
            style={{ color: colors.primary.normal.first }}
            onClick={handleOnConfirmDeleteDealProductButtonClick}
            disabled={isDeleteDealProductMutationLoading}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteDealProductButton;
