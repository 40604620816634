import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.section`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const Content = styled.article`
  width: 100%;
  max-width: 768px;
  display: flex;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  flex-direction: column;
  gap: 1rem;
  background: #f5f5f5;
  padding: 3rem 1rem 5rem 1rem;
  border: 2px solid #46a184;
  border-radius: 0.5rem;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  color: ${colors.text.third};
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;
`;
