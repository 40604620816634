import React, { ReactNode } from 'react';

import { Container, Section } from './style';

interface PoliciesTemplateProps {
  children?: ReactNode;
}

function PoliciesTemplate({ children }: PoliciesTemplateProps): JSX.Element {
  return (
    <Container>
      <Section>{children}</Section>
    </Container>
  );
}

export default PoliciesTemplate;
