import React from 'react';
import { useDealsCategories } from '../../../../../hooks/DealsCategories';
import BusinessSection from './BusinessSection';
import SoonBusinessSection from './SoonBusinessSection';
import { Container } from './style';

function AddBusinessPaper(): JSX.Element {
  const { dealsCategories } = useDealsCategories();
  return (
    <Container>
      <h1>Adicione um novo cliente</h1>
      <h2>Preencha o formulário ou copie os links de captação.</h2>
      {dealsCategories.map((dealCategory) =>
        dealCategory.isInDevelopment ? (
          <SoonBusinessSection name={dealCategory.name} key={dealCategory.id} />
        ) : (
          <BusinessSection dealCategory={dealCategory} />
        ),
      )}
    </Container>
  );
}

export default AddBusinessPaper;
