import { gql } from '@apollo/client';

export interface requestsendMailToCreateCompanyMutationVars {
  name: string;
  email: string;
  expiresIn: string;
  eduzzBillID?: number;
  recurrence_code?: number;
  haveLifetimeAccess: boolean;
}

export interface requestsendMailToCreateCompanyMutationResponse {
  sendMailToCreateCompany: boolean;
}

const SEND_EMAIL_TO_CREATE_COMPANY = gql`
  mutation sendMailToCreateCompany(
    $name: String!
    $email: String!
    $expiresIn: String
    $eduzzBillID: Int!
    $recurrence_code: Int
    $haveLifetimeAccess: Boolean
  ) {
    sendMailToCreateCompany(
      data: {
        name: $name
        email: $email
        expiresIn: $expiresIn
        eduzzBillID: $eduzzBillID
        recurrence_code: $recurrence_code
        haveLifetimeAccess: $haveLifetimeAccess
      }
    )
  }
`;

export default SEND_EMAIL_TO_CREATE_COMPANY;
