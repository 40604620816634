import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { TableHead, TableRow, TableBody } from '@material-ui/core';
import { InstallmentData } from '../../../../../../../../GraphQL/mutations/getSACSimulation';
import formatToPTBRMoneyValue from '../../../../../../../../utils/formatMoney';
import { HeadTableCell } from '../style';
import { BodyTableCell } from './style';
import colors from '../../../../../../../../styles/colors';

interface ITableProps {
  installments: InstallmentData[];
}

const RealStateTable = ({ installments }: ITableProps): JSX.Element => {
  return (
    <>
      <TableHead
        style={{
          backgroundColor: colors.primary.normal.first,
        }}
      >
        <HeadTableCell>Parcelas</HeadTableCell>
        <HeadTableCell>Valor</HeadTableCell>
      </TableHead>
      <TableBody>
        {installments.map((installment, index) => {
          const parcels = installments.length;
          const newIndex = index + 1;
          const totalInstallments = newIndex === parcels;
          return (
            totalInstallments && (
              <TableRow key={uuidV4()}>
                <BodyTableCell>{newIndex} - parcelas</BodyTableCell>
                <BodyTableCell>
                  {formatToPTBRMoneyValue(installment.installment)}
                </BodyTableCell>
              </TableRow>
            )
          );
        })}
      </TableBody>
    </>
  );
};

export default RealStateTable;
