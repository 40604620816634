/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IconButton } from '@material-ui/core';
import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Menu } from '@material-ui/icons';
import { IconBaseProps } from 'react-icons/lib';
import { Container, Header, Article } from './style';
import Logo from '../../../assets/logo.color.light.svg';
import LogoMobile from '../../../assets/logoMobile.png';
import { useNavbarHook } from '../hooks/NavbarHook';
import { useRole } from '../../../hooks/Role';
import { SidebarLink } from './SidebarLink';

export interface DashboardLinkData {
  path: string;
  label: string;
  icon: ComponentType<IconBaseProps>;
  isInDevelopment?: boolean;
  className?: string;
  permission?: number;
  isExternalLink?: boolean;
}

interface SideBarProps {
  links: DashboardLinkData[];
}

interface SideBarLinkProps extends DashboardLinkData {
  isActive: boolean;
}

function Sidebar({ links: dashboardLinks }: SideBarProps): JSX.Element {
  const [logo, setLogo] = useState<string>(() =>
    window.innerWidth <= 768 ? LogoMobile : Logo,
  );

  const [links, setLinks] = useState<SideBarLinkProps[]>([
    ...dashboardLinks.map((dashboardLink) => ({
      ...dashboardLink,
      isActive: false,
    })),
  ]);

  const { isSidebarCompressed, setIsSidebarCompressed } = useNavbarHook();

  const handleOnBurgerClick = useCallback(() => {
    setIsSidebarCompressed(true);
  }, [setIsSidebarCompressed]);

  const handleOnWindowResize = useCallback(() => {
    setLogo(window.innerWidth <= 768 ? LogoMobile : Logo);
  }, []);

  window.addEventListener('resize', handleOnWindowResize);
  const history = useHistory();
  const handleOnLogoClick = useCallback(() => {
    history.push('/');
  }, [history]);

  const { pathname } = useLocation();

  useEffect(() => {
    setLinks(
      dashboardLinks.map((link) => ({
        ...link,
        isActive: pathname === link.path,
      })),
    );
  }, [dashboardLinks, pathname]);

  useEffect(() => {
    setLogo(
      !isSidebarCompressed && window.innerWidth > 768 ? Logo : LogoMobile,
    );
  }, [isSidebarCompressed]);

  const { verifyIfHasPermission } = useRole();

  return (
    <Container isSidebarCompressed={isSidebarCompressed}>
      <Header isSidebarCompressed={isSidebarCompressed}>
        <img
          src={logo}
          alt="Logotipo LCC"
          className="logo"
          onClick={handleOnLogoClick}
        />
        <IconButton onClick={handleOnBurgerClick}>
          <Menu />
        </IconButton>
      </Header>
      <Article>
        {links.map(
          ({ path, className, icon, label, permission, isExternalLink }) => {
            const hasPermission = verifyIfHasPermission(permission || 0);
            return (
              hasPermission && (
                <SidebarLink
                  icon={icon}
                  label={label}
                  path={path}
                  className={className}
                  isExternal={isExternalLink}
                  key={path}
                />
              )
            );
          },
        )}
      </Article>
    </Container>
  );
}

export default Sidebar;
