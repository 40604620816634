import React, { ReactNode } from 'react';

import { Container, Content } from './style';

interface SectionProps {
  children: ReactNode;
  title: string;
  gridArea?: string;
}

function Section({ title, children, gridArea }: SectionProps): JSX.Element {
  return (
    <Container gridArea={gridArea}>
      <h1>{title}</h1>
      <Content>{children}</Content>
    </Container>
  );
}

export default Section;
