import { gql } from '@apollo/client';
import { InstallmentData } from './getSACSimulation';

export interface getPriceTableSimulationVars {
  loanAmount: number;
  numberOfInstallments: number;
  loanInterest: number;
  paymentEntry: number;
}

export interface getPriceTableSimulationResponse {
  getPriceTableSimulation: InstallmentData[];
}

const GET_PRICE_TABLE_SIMULATION = gql`
  mutation getPriceTableSimulation(
    $loanAmount: Float!
    $numberOfInstallments: Int!
    $loanInterest: Float!
    $paymentEntry: Float!
  ) {
    getPriceTableSimulation(
      data: {
        loanAmount: $loanAmount
        numberOfInstallments: $numberOfInstallments
        loanInterest: $loanInterest
        paymentEntry: $paymentEntry
      }
    ) {
      amortization
      amount
      interest
      installment
      paymentEntry
    }
  }
`;

export default GET_PRICE_TABLE_SIMULATION;
