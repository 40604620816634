import { gql } from '@apollo/client';
import { InstallmentData } from './getSACSimulation';

export interface getVehicleTableSimulationVars {
  loanAmount: number;
  numberOfInstallments: number;
  loanInterest: number;
  paymentEntry: number;
}

export interface getVehicleTableSimulationResponse {
  getVehicleTableSimulation: InstallmentData[];
}

const GET_VEHICLE_TABLE_SIMULATION = gql`
  mutation getVehicleTableSimulation(
    $loanAmount: Float!
    $numberOfInstallments: Int!
    $loanInterest: Float!
    $paymentEntry: Float!
  ) {
    getVehicleTableSimulation(
      data: {
        loanAmount: $loanAmount
        numberOfInstallments: $numberOfInstallments
        loanInterest: $loanInterest
        paymentEntry: $paymentEntry
      }
    ) {
      amortization
      amount
      interest
      installment
      paymentEntry
    }
  }
`;

export default GET_VEHICLE_TABLE_SIMULATION;
