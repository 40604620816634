import styled, { css } from 'styled-components';
import colors from '../../../../styles/colors';

interface IContainer {
  isSidebarCompressed: boolean;
  isActive: boolean;
}

export const Container = styled.section<IContainer>`
  height: 3rem;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: ${(props) =>
    props.isSidebarCompressed ? '1fr' : '1fr 4fr'};
  cursor: pointer;
  align-items: center;
  justify-items: center;
  ${(props) =>
    !props.isSidebarCompressed &&
    css`
      justify-items: start;

      padding-left: 1rem;
      border-left: 0.3rem solid
        ${() => (props.isActive ? colors.primary.normal.first : 'transparent')};
    `}

  transition: all 0.1s ease;

  & > svg {
    width: 1.3rem;
    height: auto;
    ${(props) =>
      props.isSidebarCompressed &&
      css`
        color: ${props.isActive ? colors.primary.normal.first : '#fff'};
      `}
  }

  &:hover {
    border-color: ${colors.primary.normal.first};
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-left: 0;
    border-left: 0;
    justify-items: center;
    align-items: center;
    justify-content: center;
    color: ${(props) =>
      props.isActive ? colors.primary.normal.first : '#fff'};

    & > p {
      display: none;
    }
  }
`;
