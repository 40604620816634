import { withStyles } from '@material-ui/core';
import Select from '..';
import colors from '../../../styles/colors';

const SelectLight = withStyles({
  root: {
    '& .MuiFilledInput-input': {
      color: colors.text.fourth,
    },
    '& label, svg': {
      color: 'rgba(0, 0, 0, 0.54);',
    },
    '& label.Mui-focused, label.MuiFormLabel-filled': {
      color: colors.primary.normal.third,
    },
    '& .MuiFilledInput-underline:before': {
      borderBottomColor: colors.text.second,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: colors.primary.normal.first,
    },

    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: 'rgba(70, 161, 132, 0.06)',
    },
    '& .Mui-error': {
      color: colors.error.second,
    },
  },
})(Select);

export default SelectLight;
