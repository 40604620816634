import { useMutation } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import REMOVE_PROFILE_PICTURE from '../GraphQL/mutations/removeProfiePicture';

import api from '../services/api';
import { useAuth } from './Auth';
import { useCompany } from './Company';

interface AvatarContextData {
  avatarSource: string | null;
  removeProfilePicture(): void;
  loadingRemoveProfilePicture: boolean;
  getAvatarFromApi(): Promise<void>;
  isAvatarUploading: boolean;
  setIsAvatarUploading(isAvatarUploading: boolean): void;
}

const AvatarContext = createContext<AvatarContextData>({} as AvatarContextData);

interface AvatarProviderProps {
  children: ReactNode;
}

export function AvatarProvider({ children }: AvatarProviderProps): JSX.Element {
  const [avatarSource, setAvatarSource] = useState<string | null>(null);
  const [isAvatarUploading, setIsAvatarUploading] = useState<boolean>(false);

  const { company } = useCompany();
  const { isCompanyLogged } = useAuth();

  const getAvatarFromApi = useCallback(async (): Promise<void> => {
    if (company.id) {
      try {
        const response = await api.get(`/company/avatar/${company.id}`, {
          responseType: 'arraybuffer',
        });
        const avatarFile = new Blob([response.data]);
        const avatarURL = URL.createObjectURL(avatarFile);
        setAvatarSource(avatarURL);
      } catch (error) {
        setAvatarSource(null);
      }
    }
  }, [company]);

  const handleOnRemoveProfilePictureCompleted = useCallback(() => {
    setAvatarSource(null);
    toast.success('Avatar removido com sucesso');
  }, []);

  const handleOnRemoveProfilePictureError = useCallback((mutationError) => {
    toast.warn(
      `Não foi possível remover o seu avatar: ${mutationError.message}`,
    );
  }, []);
  const [
    removeProfilePicture,
    { loading: loadingRemoveProfilePicture },
  ] = useMutation(REMOVE_PROFILE_PICTURE, {
    onCompleted: handleOnRemoveProfilePictureCompleted,
    onError: handleOnRemoveProfilePictureError,
  });

  useEffect(() => {
    if (isCompanyLogged) {
      getAvatarFromApi();
    }
  }, [getAvatarFromApi, isCompanyLogged]);


  return (
    <AvatarContext.Provider
      value={{
        avatarSource,
        removeProfilePicture,
        loadingRemoveProfilePicture,
        getAvatarFromApi,
        isAvatarUploading,
        setIsAvatarUploading,
      }}
    >
      {children}
    </AvatarContext.Provider>
  );
}

export function useAvatar(): AvatarContextData {
  const context = useContext(AvatarContext);
  if (!context) {
    throw new Error('useAvatar must be used within a AvatarProvider');
  }
  return context;
}
