import React from 'react';
import Navbar from '../../templates/DashboardTemplate/Navbar';
import ChangeDataForm from './ChangeDataForm';
import { ProfileProvider } from './hooks/Profile';
import ProfileSection from './ProfileSection';

import { Container, Content, Title } from './style';

function Profile(): JSX.Element {
  return (
    <ProfileProvider>
      <Navbar title="Meu Perfil" />
      <Container>
        <Content>
          <Title>Meu Perfil</Title>
          <ProfileSection />
          <ChangeDataForm />
        </Content>
      </Container>
    </ProfileProvider>
  );
}

export default Profile;
