import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: min-content;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  gap: 1rem;
  padding: 1rem;

  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const DealProductFormSection = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
