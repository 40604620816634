import styled from 'styled-components';
import colors from '../../../../../styles/colors';

interface IconContainerProps {
  backgroundColor: string;
}

export const Container = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: ease 0.3s;

  @media screen and (max-width: 1250px) {
    flex-wrap: wrap;
  }
`;

export const IconContainer = styled.section<IconContainerProps>`
  width: 41px;
  min-width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;

  & > svg {
    width: 22 px;
  }
`;

export const InfoContainer = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  gap: 0.2rem;

  & > p {
    color: ${colors.text.second};
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    line-height: 12px;
    letter-spacing: -0.500744px;
  }

  & > h1 {
    color: ${colors.text.third};
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    margin-top: 2px;
  }

  @media screen and (max-width: 1250px) {
    gap: 0.5rem;
  }
`;
