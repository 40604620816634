import React from 'react';
import Controller from './Controller';

import { Container, Divider } from './style';
import UserPresentation from './UserPresentation';

function PaperController(): JSX.Element {
  return (
    <Container>
      <UserPresentation />
      <Divider />
      <Controller />
    </Container>
  );
}

export default PaperController;
