/* eslint-disable jsx-a11y/label-has-associated-control */
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { ChangeEvent, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/Auth';
import { useAvatar } from '../../../../hooks/Avatar';
import api from '../../../../services/api';
import colors from '../../../../styles/colors';

import { Container } from './style';

function ChangeAvatarButton(): JSX.Element {
  const { getAvatarFromApi, setIsAvatarUploading } = useAvatar();
  const { token } = useAuth();
  const handleOnFileChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = event.target.files;
      if (selectedFiles && selectedFiles.length > 0) {
        const data = new FormData();
        const file = selectedFiles[0];
        data.append('file', file, file.name);
        setIsAvatarUploading(true);
        const response = await api.patch('/company/avatar', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
        setIsAvatarUploading(false);

        if (response.status === 400) {
          const warnMessage = 'Não foi possível atualizar o seu avatar';
          toast.warn(warnMessage);
          return;
        }
        if (response.status === 200) {
          const successMessage = 'Avatar alterado com sucesso';
          toast.success(successMessage);
          getAvatarFromApi();
        }
      }
    },
    [getAvatarFromApi, setIsAvatarUploading, token],
  );

  return (
    <Container>
      <input
        accept="image/*"
        id="profilePictureButton"
        type="file"
        onChange={handleOnFileChange}
      />
      <label htmlFor="profilePictureButton">
        <IconButton
          style={{
            padding: 5,
            color: colors.text.first,
            backgroundColor: colors.primary.normal.first,
          }}
          component="span"
        >
          <Edit />
        </IconButton>
      </label>
    </Container>
  );
}

export default ChangeAvatarButton;
