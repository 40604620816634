import {
  Dialog,
  DialogTitle,
  DialogProps,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { SimulationData } from '../../../../../GraphQL/mutations/createSimulation';
import DeleteSimulationButton from './Actions/DeleteSimulationButton';
import DownloadSimulationButton from './Actions/DownloadSimulationButton';
import SimulationVisualization from './SimulationVisualization';

interface ActionsDialogProps extends DialogProps {
  simulation: SimulationData;
  handleOnClose(): void;
}

function ActionsDialog({
  simulation,
  handleOnClose,
  ...rest
}: ActionsDialogProps): JSX.Element {
  return (
    <Dialog onClose={handleOnClose} {...rest}>
      <DialogTitle>Simulação de Crédito</DialogTitle>
      <DialogContent>
        <SimulationVisualization simulation={simulation} />
      </DialogContent>
      <DialogActions>
        <DownloadSimulationButton simulation={simulation} />
        <DeleteSimulationButton
          simulation={simulation}
          handleOnCloseMainDialog={handleOnClose}
        />
        <Tooltip title="Fechar aba">
          <IconButton color="secondary" onClick={handleOnClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}

export default ActionsDialog;
