import React, { useCallback } from 'react';
import { ArrowForwardIos, LockOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import { Container } from './style';

interface PlatformCardProps {
  icon: string;
  title: string;
  subtitle: string;
  path?: string;
  ismouseblocked?: boolean;
  isAnExternalPath?: boolean;
}

function PlatformCard({
  icon,
  title,
  subtitle,
  path,
  ismouseblocked = false,
  isAnExternalPath = false,
}: PlatformCardProps): JSX.Element {
  const history = useHistory();

  const handleOnClick = useCallback(() => {
    if (!ismouseblocked && path) {
      if (isAnExternalPath) {
        window.open(path);
      } else {
        history.push(path);
      }
    }
  }, [history, isAnExternalPath, ismouseblocked, path]);

  return (
    <Container>
      <img src={icon} alt="icon" />
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      <Button
        fullWidth
        onClick={handleOnClick}
        endIcon={ismouseblocked ? <LockOutlined /> : <ArrowForwardIos />}
        ismouseblocked={ismouseblocked}
      >
        Quero Acessar
      </Button>
    </Container>
  );
}

export default PlatformCard;
