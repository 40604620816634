import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  height: min-content;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  align-items: center;
  justify-items: start;
  gap: 1rem;
  background: #ffffff;
  & > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #071620;
  }
  & > p {
    text-align: justify;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */
    /* Text/Text Dark */
    color: #363c4c;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: #fbfbfb;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 4px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
