import React, { useCallback, useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core';

import { Container, ControlSection, SelectCustomized } from './style';
/*  import EditDealCategoryButton from './EditDealCategoryButton';
import CreateDealCategoryButton from './CreateDealCategoryButton';
import DeleteDealCategoryButton from './DeleteDealCategoryButton';
import DealCategoryVisibilityButton from './DealCategoryVisibilityButton'; */
import { useDealsCategories } from '../../../../../hooks/DealsCategories';
import { useDeals } from '../Hooks/Deals';

function Header(): JSX.Element {
  const { dealsCategories } = useDealsCategories();
  const { setSelectedDealCategory, selectedDealCategory } = useDeals();

  const handleOnSelectDealCategoryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedDealCategoryValue = event.target.value;
      const DealCategory = dealsCategories.find(
        (dealCategory) => dealCategory.id === selectedDealCategoryValue,
      );
      if (DealCategory) {
        setSelectedDealCategory(DealCategory);
      }
    },
    [dealsCategories, setSelectedDealCategory],
  );
  const [
    isAnyDealCategorySelected,
    setIsAnyDealCategorySelected,
  ] = useState<boolean>(false);

  const [isThereAnyDealCategory, setIsThereAnyDealCategory] = useState<boolean>(
    false,
  );

  useEffect(() => {
    setIsAnyDealCategorySelected(JSON.stringify(selectedDealCategory) !== '{}');
    setIsThereAnyDealCategory(dealsCategories.length > 0);
  }, [dealsCategories, selectedDealCategory]);

  return (
    <Container>
      {isThereAnyDealCategory && (
        <SelectCustomized
          variant="outlined"
          label="Tipo de Crédito"
          fullWidth
          select
          onChange={handleOnSelectDealCategoryChange}
        >
          {dealsCategories.map((dealCategory) => (
            <MenuItem key={dealCategory.id} value={dealCategory.id}>
              {dealCategory.name}
            </MenuItem>
          ))}
        </SelectCustomized>
      )}
      {/* <ControlSection>
        {<CreateDealCategoryButton />}
        {isAnyDealCategorySelected && <EditDealCategoryButton />}
        {isAnyDealCategorySelected && <DeleteDealCategoryButton />}
        {isAnyDealCategorySelected && <DealCategoryVisibilityButton />}
      </ControlSection> */}
    </Container>
  );
}

export default Header;
