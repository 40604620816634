import React, { ReactNode } from 'react';

import { ApolloProvider } from '@apollo/client';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import client from '../services/client';
import { AuthProvider } from './Auth';
import { AvatarProvider } from './Avatar';
import { ModalProvider } from './Modal';
import { OnboardProvider } from './Onboard';
import { RoleProvider } from './Role';
import { DealsCategoriesProvider } from './DealsCategories';
import { DealsProvider } from './Deals';
import { DealsStagesProvider } from './DealsStages';
import { WindowProvider } from './Window';
import { ThemeProvider } from './Theme';
import { DealsProductsProvider } from './DealsProducts';
import { ChatWidget } from '../components/ChatWidget';
import { CompanyProvider } from './Company';
import { CompaniesProvider } from './ListCompanies';
import { AdminProvider } from '../pages/Admin/hooks/Admin';

interface AppProviderProps {
  children: ReactNode;
}

function AppProvider({ children }: AppProviderProps): JSX.Element {
  return (
    <ThemeProvider>
      <WindowProvider>
        <ApolloProvider client={client}>
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <AuthProvider>
              <AdminProvider>
                <CompanyProvider>
                  <CompaniesProvider>
                    <RoleProvider>
                      <DealsCategoriesProvider>
                        <DealsProductsProvider>
                          <DealsStagesProvider>
                            <DealsProvider>
                              <AvatarProvider>
                                <OnboardProvider>
                                  <ModalProvider>
                                    <ChatWidget>{children}</ChatWidget>
                                  </ModalProvider>
                                </OnboardProvider>
                              </AvatarProvider>
                            </DealsProvider>
                          </DealsStagesProvider>
                        </DealsProductsProvider>
                      </DealsCategoriesProvider>
                    </RoleProvider>
                  </CompaniesProvider>
                </CompanyProvider>
              </AdminProvider>
            </AuthProvider>
          </MuiPickersUtilsProvider>
        </ApolloProvider>
      </WindowProvider>
    </ThemeProvider>
  );
}

export default AppProvider;
