import React from 'react';
import NewsList from './NewstList';

import { Container } from './style';


function NewsScreenshotContainer(): JSX.Element {

  return (
    <Container>
      <NewsList />
    </Container>
  );
}

export default NewsScreenshotContainer;
