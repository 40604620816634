import React, { useCallback } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@material-ui/core';
import colors from '../../../../../styles/colors';
import { useSimulationSteps } from '../hooks/SimulationSteps';
// import { BackButton } from '../../BackButton';

interface CreateSimulationDialogProps extends DialogProps {
  handleOnDialogClose(): void;
}

function CreateSimulationDialog({
  handleOnDialogClose: onClose,
  ...rest
}: CreateSimulationDialogProps): JSX.Element {
  const {
    currentForm: CurrentForm,
    handleOnClose,
    isCreateSimulationMutationLoading,
    dialogContentText,
    confirmButtonText,
    cancelButtonText,
  } = useSimulationSteps();
  const handleOnDialogClose = useCallback(() => {
    onClose();
    handleOnClose();
  }, [handleOnClose, onClose]);
  return (
    <Dialog onClose={handleOnDialogClose} {...rest}>
      <DialogTitle>Criar simulação</DialogTitle>
      <DialogContentText style={{ textAlign: 'justify', padding: '0 1rem' }}>
        {dialogContentText}
      </DialogContentText>
      <DialogContent>{CurrentForm}</DialogContent>
      <DialogActions>
        <Button
          style={{ color: colors.error.second }}
          variant="outlined"
          onClick={handleOnDialogClose}
          disabled={isCreateSimulationMutationLoading}
        >
          {cancelButtonText}
        </Button>
        {/* <BackButton /> */}
        <Button
          style={{ color: colors.primary.normal.first }}
          variant="contained"
          type="submit"
          form="simulationForm"
          disabled={isCreateSimulationMutationLoading}
        >
          {`${
            isCreateSimulationMutationLoading ? 'Criando' : confirmButtonText
          }`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateSimulationDialog;
