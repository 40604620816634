import { gql } from '@apollo/client';

export interface GetAdminDataQueryData {
  activeAccounts: number;
  inactiveAccounts: number;
  numberOfPlatformAccess: number;
  numberOfNewBusiness: number;
}

export interface GetAdminDataQueryResponse {
  getAdminData: GetAdminDataQueryData;
}

const GET_ADMIN_DATA = gql`
  query getAdminData {
    getAdminData {
      activeAccounts
      inactiveAccounts
      numberOfPlatformAccess
      numberOfNewBusiness
    }
  }
`;

export default GET_ADMIN_DATA;
