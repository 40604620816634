import styled from 'styled-components';

export const Container = styled.section`
  height: 100%;
  width: 100%;
  display: grid;
  position: relative;
`;

export const NavbarButtonsContainer = styled.article`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  & > * {
    max-width: 236px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    & > * {
      max-width: 100%;
    }
    & > button {
      width: 100%;
    }
  }
`;
