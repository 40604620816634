import { gql } from '@apollo/client';
import { DealCategoryData } from './getDealsCategories';

export interface DealProductData {
  id: string;
  name: string;
  bitrix_id: number;
  averageRate: number;
  competitiveRate: number;
  maxNumberOfInstallments: number;
  dealCategory: DealCategoryData;
  createdAt: Date;
  updatedAt: Date;
}

export interface getDealSProductsQueryResponse {
  getDealsProducts: DealProductData[];
}

const GET_DEALS_PRODUCTS = gql`
  query getDealsProducts {
    getDealsProducts {
      id
      name
      bitrix_id
      averageRate
      maxNumberOfInstallments
      competitiveRate
      dealCategory {
        id
      }
      createdAt
      updatedAt
    }
  }
`;

export default GET_DEALS_PRODUCTS;
