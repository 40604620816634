import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2.104rem;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));
  background: #fff;
  border-radius: 0.313rem;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
    height: 100%;
  }

  @media screen and (min-width: 769px) {
    overflow-y: auto;
  }
`;

export const Content = styled.article`
  height: 100%;
  width: 100%;
  display: grid;
  gap: 1rem;
  overflow: scroll;
  @media screen and (max-width: 768px) {
    height: 20rem;
  }
`;
