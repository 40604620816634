import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import BitwiseAnd from '../utils/BitwiseAnd';

import { useCompany } from './Company';

interface RoleContextData {
  companyPermissionValue: number;
  verifyIfHasPermission(permissionValue: number): boolean;
}

const RoleContext = createContext<RoleContextData>({} as RoleContextData);

interface RoleProviderProps {
  children: ReactNode;
}

export function RoleProvider({ children }: RoleProviderProps): JSX.Element {
  const [companyPermissionValue, setCompanyPermissionValue] = useState<number>(
    0,
  );
  const verifyIfHasPermission = useCallback(
    (permissionValue: number): boolean => {
      return BitwiseAnd(companyPermissionValue, permissionValue);
    },
    [companyPermissionValue],
  );

  const { company } = useCompany();

  useEffect(() => {
    if (company.roleId) {
      setCompanyPermissionValue(company.roleId.permissions);
    } else {
      setCompanyPermissionValue(0);
    }
  }, [company]);

  return (
    <RoleContext.Provider
      value={{
        companyPermissionValue,
        verifyIfHasPermission,
      }}
    >
      {children}
    </RoleContext.Provider>
  );
}

export function useRole(): RoleContextData {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error('useContext must be used within a RoleProvider');
  }
  return context;
}
