import React, { useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { ChevronRight } from '@material-ui/icons';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Logo from '../../assets/logo.color.light.svg';

import { Container, Content, SignInContainer } from './style';

import CREATE_BASIC_COMPANY, {
  createBasicCompanyResponse,
  createBasicCompanyVars,
} from '../../GraphQL/mutations/createBasicCompany';
import getValidationErrors from '../../utils/getValidationErrors';
import InputNumber from '../../components/InputNumber';
import TermsAndConditionsMessage from '../../components/TermsAndConditionsMessage';

const SignUpBasic = (): JSX.Element => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [
    createBasicCompany,
    { loading, error: createBasicCompanyError },
  ] = useMutation<createBasicCompanyVars, createBasicCompanyResponse>(
    CREATE_BASIC_COMPANY,
  );

  const handleOnSubmit = useCallback(
    async (data) => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required(
            'É necessário informar o nome da sua empresa',
          ),

          email: Yup.string()
            .required('Você precisa inserir um email')
            .email('Digite um email válido'),

          phone: Yup.string().required('Insira um telefone para contato'),
        });
        await schema.validate(data, { abortEarly: false });
        await createBasicCompany({
          variables: data,
        });
        toast.success(
          `Seja bem vindo(a) ao nosso sistema! Verifique o seu email, nossa equipe irá entrar em contato com você!`,
        );
        history.push('/login');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          toast.warning('Por favor, preencha os dados corretamente');
          return;
        }
        if (createBasicCompanyError) {
          toast.warning(createBasicCompanyError.message);
          return;
        }
        toast.warning(
          'Houve um erro ao consultar o banco de dados. Por favor, tente novamente mais tarde.',
        );
      }
    },
    [createBasicCompany, createBasicCompanyError, history],
  );
  return (
    <Container>
      <Content ref={formRef} onSubmit={handleOnSubmit}>
        <img src={Logo} alt="Logotipo LCC" className="logo" />
        <Input name="name" label="Nome" type="text" />
        <Input
          name="companyName"
          label="Nome da empresa (opcional)"
          type="text"
        />
        <Input name="email" label="E-mail" />
        <InputNumber name="phone" label="Telefone" format="(##) # ####-####" />
        <TermsAndConditionsMessage ButtonName="Cadastrar" />
        <Button fullWidth type="submit" endIcon={<ChevronRight />}>
          {loading ? 'Cadastrando...' : 'Cadastrar'}
        </Button>
        <SignInContainer>
          <p>Já tem login?</p>
          <Link to="/login">Faça o login</Link>
        </SignInContainer>
      </Content>
    </Container>
  );
};

export default SignUpBasic;
