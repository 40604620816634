import styled from 'styled-components';

export const Container = styled.div`
  & svg {
    width: 1rem;
    height: 1rem;
  }

  & > input {
    display: none;
  }
`;
