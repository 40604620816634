import { ApolloError, useLazyQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';

import {
  ICompany,
  getCompanyQuery,
  IGetCompanyQueryResponse,
} from '../GraphQL/queries/getCompany';
import { useAuth } from './Auth';

interface ICompanyContext {
  company: ICompany;
  setCompany(company: ICompany): void;
  loading: boolean;
  isAdmin: boolean;
}

const companyContext = createContext<ICompanyContext>({} as ICompanyContext);

interface ICompanyResolver {
  children: ReactNode;
}

export function CompanyProvider({ children }: ICompanyResolver): JSX.Element {
  const [company, setCompany] = useState<ICompany>({} as ICompany);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const handleOnGetCompanyQueryCompleted = useCallback(
    ({ getCompany: fetchedCompany }: IGetCompanyQueryResponse) => {
      setCompany(fetchedCompany);
      setIsAdmin(fetchedCompany.roleId?.name === 'Administrator');
      toast.success(`Seja bem vindo(a), ${fetchedCompany.name}!`);
    },
    [],
  );
  const handleOnGetCompanyQueryError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(`Não foi possível obter os dados do usuário: ${message}`);
    },
    [],
  );
  const [getCompany, { loading }] = useLazyQuery<IGetCompanyQueryResponse>(
    getCompanyQuery,
    {
      onCompleted: handleOnGetCompanyQueryCompleted,
      onError: handleOnGetCompanyQueryError,
    },
  );
  const { isCompanyLogged } = useAuth();

  useEffect(() => {
    if (isCompanyLogged) {
      getCompany();
    }
  }, [getCompany, isCompanyLogged]);

  useEffect(() => {
    const localStorageCompanyName = '@LCC:company';
    localStorage.removeItem(localStorageCompanyName);
  }, []);

  return (
    <companyContext.Provider value={{ company, isAdmin, loading, setCompany }}>
      {children}
    </companyContext.Provider>
  );
}

export function useCompany(): ICompanyContext {
  const context = useContext(companyContext);
  if (!context) {
    throw new Error('useCompany must be used within a CompanyProvider');
  }
  return context;
}
