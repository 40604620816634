import { createMuiTheme, withStyles } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import { KeyboardDatePicker } from '@material-ui/pickers';
import colors from '../../../styles/colors';

export const KeyboardDatePickerCustomized = withStyles({
  root: {
    '& input': {
      color: colors.text.fourth,
    },
    '& svg': {
      color: colors.primary.normal.first,
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& label.Mui-focused, label.MuiFormLabel-filled': {
      color: colors.primary.normal.third,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: colors.text.second,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.primary.normal.first,
    },

    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: colors.primary.light.first,
    },
    '& .Mui-error': {
      color: colors.error.second,
    },
  },
})(KeyboardDatePicker);

export const MuiPickerTheme = createMuiTheme({
  palette: {
    primary: teal,
  },
});
