import React from 'react';
import Avatar from '../../../../../../components/Avatar';
import { useCompany } from '../../../../../../hooks/Company';

import { Container, AvatarContainer, UserInfoContainer } from './style';

function UserPresentation(): JSX.Element {
  const { company } = useCompany();
  return (
    <Container>
      <AvatarContainer>
        <Avatar />
      </AvatarContainer>
      <UserInfoContainer>
        <h1>{company.personName}</h1>
        <h2>{company.name}</h2>
      </UserInfoContainer>
    </Container>
  );
}

export default UserPresentation;
