import { gql } from '@apollo/client';

export interface confirmTutorialHasBeenViewedMutationData {
  sawTutorial: boolean;
}

export interface confirmTutorialHasBeenViewedMutationResponse {
  confirmTutorialHasBeenViewed: confirmTutorialHasBeenViewedMutationData;
}

const CONFIRM_TUTORIAL_HAS_BEEN_VIEWED = gql`
  mutation {
    confirmTutorialHasBeenViewed {
      sawTutorial
    }
  }
`;

export default CONFIRM_TUTORIAL_HAS_BEEN_VIEWED;
