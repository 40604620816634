import styled from 'styled-components';
import colors from '../../../../../../styles/colors';

export const Container = styled.section`
  position: absolute;

  width: min-content;

  background-color: ${colors.primary.normal.first};

  right: 1%;
  top: 25%;
  opacity: 50%;

  border-radius: 50%;

  filter: drop-shadow(0px 20px 25px rgba(0, 0, 0, 0.1)),
    drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.04));

  transition: all 0.3s ease;

  & svg {
    color: #fff;
  }

  &:hover {
    opacity: 100%;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
