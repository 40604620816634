export const personTypes = [
  {
    label: 'Pessoa Física',
    value: 'pf',
  },
  {
    label: 'Pessoa Jurídica',
    value: 'pj',
  },
];
