import { ApolloError, useLazyQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import {
  getCompanyDealsQuery,
  IGetCompanyDealsQueryResponse,
  IGetCompanyDealsQueryVars,
} from '../GraphQL/queries/getCompanyDeals';
import { BitrixDealData } from '../GraphQL/queries/getDealsUpdated';

import { useAuth } from './Auth';

interface DealsContextData {
  deals: BitrixDealData[];
  setDeals(deals: BitrixDealData[]): void;
  loading: boolean;
}

const DealsContext = createContext<DealsContextData>({} as DealsContextData);

interface DealsProviderProps {
  children: ReactNode;
}
export function DealsProvider({ children }: DealsProviderProps): JSX.Element {
  const [deals, setDeals] = useState<BitrixDealData[]>([] as BitrixDealData[]);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handleOnGetDealsQueryCompleted = useCallback(
    ({ getCompanyDeals: queryResponse }: IGetCompanyDealsQueryResponse) => {
      const { result, next: nextPage } = queryResponse;
      const nonExistingDeals = result.filter(
        (fetchedDeal) =>
          deals.findIndex((deal) => deal.ID === fetchedDeal.ID) < 0,
      );
      setDeals([...deals, ...nonExistingDeals]);
      if (nextPage) {
        setCurrentPage(nextPage);
      }
    },
    [deals],
  );
  const handleOnGetDealsQueryError = useCallback(({ message }: ApolloError) => {
    toast.warn(`Não foi possível obter todos os negócios: ${message}`);
  }, []);

  const [getCompanyDeals, { loading }] = useLazyQuery<
    IGetCompanyDealsQueryResponse,
    IGetCompanyDealsQueryVars
  >(getCompanyDealsQuery, {
    onCompleted: handleOnGetDealsQueryCompleted,
    onError: handleOnGetDealsQueryError,
  });
  const { isCompanyLogged } = useAuth();
  useEffect(() => {
    if (isCompanyLogged) {
      getCompanyDeals({
        variables: {
          page: currentPage,
        },
      });
    } else {
      setDeals([]);
      setCurrentPage(0);
    }
  }, [currentPage, getCompanyDeals, isCompanyLogged]);
  return (
    <DealsContext.Provider
      value={{
        deals,
        setDeals,
        loading,
      }}
    >
      {children}
    </DealsContext.Provider>
  );
}

export function useDeals(): DealsContextData {
  const context = useContext(DealsContext);
  if (!context) {
    throw new Error('useDeals must be used within a DealsProvider');
  }
  return context;
}
