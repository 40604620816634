import { Popover } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import Button from '../../../../components/Button';
import AddBusinessPaper from './AddBusinessPaper';

function AddBusinessButton(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleOnClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Button endIcon={<Add />} className="second-step" onClick={handleOnClick}>
        Adicionar Cliente
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleOnClose}
        style={{
          top: 7,
          boxShadow:
            '0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <AddBusinessPaper />
      </Popover>
    </>
  );
}

export default AddBusinessButton;
