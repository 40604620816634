/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import NewsCard from './NewsCard';
import { Container } from './style';

import Loja from '../../../../../assets/images/newsOnboard/Loja.png';
import Simulador from '../../../../../assets/images/newsOnboard/Simulador.png';

import colors from '../../../../../styles/colors';

const NewsList = (): JSX.Element => {
  const [currentImage, setCurrentImage] = useState(0);
  const news = [
    {
      image: Loja,
      title: '',
    },
    {
      image: Simulador,
      title: '',
    },
  ];
  const length = news.length;

  const handleNextSlide = () => {
    if (currentImage < length - 1) {
      setCurrentImage(currentImage + 1);
    }
  };

  const handlePreviousSlide = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    }
  };

  return (
    <Container>
      <MdArrowBack
        style={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          width: '40px',
          height: '40px',
          color: `${colors.primary.normal.third}`,
          border: `1px solid ${colors.primary.normal.third}`,
          borderRadius: '50%',
          opacity: 0.8,
          padding: '5px',
          zIndex: 100,
        }}
        onClick={handlePreviousSlide}
      />
      <MdArrowForward
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          width: '40px',
          height: '40px',
          color: `${colors.primary.normal.third}`,
          border: `1px solid ${colors.primary.normal.third}`,
          borderRadius: '50%',
          opacity: 0.8,
          padding: '5px',
          zIndex: 100,
        }}
        onClick={handleNextSlide}
      />
      {news.map((item, index) =>
        index === currentImage ? (
          <NewsCard image={item.image} key={index} />
        ) : null,
      )}
    </Container>
  );
};

export default NewsList;
