import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: min-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;

  & > img {
    height: 3rem;
  }

  & > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
`;
