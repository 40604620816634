import React, { useCallback } from 'react';
import { useModal } from '../../../../hooks/Modal';
import { useOnBoard } from '../../../../hooks/Onboard';

import {
  Container,
  ControllerContainer,
  ButtonFilled,
  ButtonOutlined,
} from '../Welcome/style';

function Finish(): JSX.Element {
  const { confirmTutorialHasBeenViewed } = useOnBoard();
  const { setIsModalOpen } = useModal();
  const { setIsTourOpen, setCurrentStep } = useOnBoard();

  const handleOnAlreadyButtonClick = useCallback(() => {
    setIsModalOpen(false);
    confirmTutorialHasBeenViewed({});
  }, [confirmTutorialHasBeenViewed, setIsModalOpen]);

  const handleOnRestartOnBoardButtonClick = useCallback(() => {
    setCurrentStep(0);
    setIsTourOpen(true);
    setIsModalOpen(false);
  }, [setCurrentStep, setIsModalOpen, setIsTourOpen]);
  return (
    <Container>
      <h1>Agora é sua vez!</h1>
      <p>
        Você está pronto para usar a nossa Plataforma de Negócios. Caso não se
        sinta confiante, você pode aprender novamente ou revisitar esse tutorial
        sempre que quiser.
      </p>
      <ControllerContainer>
        <ButtonFilled onClick={handleOnAlreadyButtonClick}>
          Estou pronto!
        </ButtonFilled>
        <ButtonOutlined onClick={handleOnRestartOnBoardButtonClick}>
          Aprenda novamente
        </ButtonOutlined>
      </ControllerContainer>
    </Container>
  );
}

export default Finish;
