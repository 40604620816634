import { ApolloClient, InMemoryCache } from '@apollo/client';
import authLink from '../GraphQL/context/authLink';
import httpLink from '../GraphQL/http/httpLink';

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
