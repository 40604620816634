import React, { ReactNode } from 'react';
import { BiStoreAlt } from 'react-icons/bi';
import { MdBusinessCenter } from 'react-icons/md';
import { FaUserAlt, FaUsers, FaCalculator, FaBookReader, FaUserShield } from 'react-icons/fa';
import { AiOutlineLink } from 'react-icons/ai';
import { NavbarHookProvider } from './hooks/NavbarHook';
import Sidebar, { DashboardLinkData } from './Sidebar';
import { Container, Aside, Section } from './style';

import { useCompany } from '../../hooks/Company';

interface DashboardTemplateProps {
  children: ReactNode;
}

function DashboardTemplate({ children }: DashboardTemplateProps): JSX.Element {
  const { company } = useCompany();
  const dashBoardLinks: DashboardLinkData[] = [
    {
      path: `https://loja.lcchub.com.br/digitalbanker/${company.id}`,
      label: 'Minha Loja Virtual',
      icon: BiStoreAlt,
      isExternalLink: true,
      className: 'first-step first-newsUpdate-step',
    },
    {
      path: '/negocios',
      label: 'Negócios',
      icon: MdBusinessCenter,
    },
    {
      path: '/clientes',
      label: 'Clientes',
      icon: FaUsers,
      className: 'sixth-step',
    },
    {
      path: '/links',
      label: 'Links de Captação',
      icon: AiOutlineLink,
      className: 'third-step',
    },
    {
      path: '/perfil',
      label: 'Perfil',
      icon: FaUserAlt,
    },
    {
      path: '/admin',
      label: 'Administração',
      icon: FaUserShield,
      permission: 2048,
    },
    {
    path: '/simulacao',
    label: 'Simulação',
    icon: FaCalculator,
    className: 'seventh-step second-newsUpdate-step'
    },
    {
      path: 'https://lucrandocomcredito.memberkit.com.br/',
      label: 'Cursos',
      className: "eigth-step",
      icon: FaBookReader,
      isExternalLink: true,
    },
  ] as DashboardLinkData[];
  return (
    <NavbarHookProvider>
      <Container>
        <Aside>
          <Sidebar links={dashBoardLinks} />
        </Aside>
        <Section>{children}</Section>
      </Container>
    </NavbarHookProvider>
  );
}

export default DashboardTemplate;
