import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import InputLight from '../../../../../../../components/Input/InputLight';
import { CPFCNPJInputNumber } from '../../../../../../../components/InputNumber/CPFCNPJInputNumber';
import InputNumberLight from '../../../../../../../components/InputNumber/InputNumberLight';
import getValidationErrors from '../../../../../../../utils/getValidationErrors';
import validateCPF from '../../../../../../../utils/ValidateCPF';
import { validateCNPJ } from '../../../../../../../utils/ValidateCNPJ';
import { useSimulationSteps } from '../../../hooks/SimulationSteps';
import { FormContainer } from '../style';
import colors from '../../../../../../../styles/colors';

interface FormData {
  name: string;
  phone: string;
  cpf_cnpj: string;
  email: string;
}

function FirstStepForm(): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const {
    currentStep,
    setName,
    setCPF_CNPJ,
    setEmail,
    setPhone,
    setCurrentStep,
    setDialogContentText,
    setConfirmButtonText,
  } = useSimulationSteps();

  const handleOnSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          name: yup
            .string()
            .required('Você precisa inserir o nome do seu cliente'),
          cpf_cnpj: yup
            .string()
            .required('Você precisa fornecer o seu CPF/CNPJ')
            .test(
              'cpf_cnpj',
              'Você precisa inserir um cpf/CNPJ válido',
              (cpf_cnpj) =>
                validateCPF(String(cpf_cnpj) || '') ||
                validateCNPJ(String(cpf_cnpj) || ''),
            ),
          email: yup
            .string()
            .email('Você precisa inserir um email válido')
            .required('Você precisa inserir o email do seu cliente'),
          phone: yup
            .string()
            .required('Você precisa inserir o telefone do seu cliente'),
        });
        await schema.validate(data, { abortEarly: false });
        setName(data.name);
        setCPF_CNPJ(data.cpf_cnpj);
        setEmail(data.email);
        setPhone(data.phone);
        setDialogContentText('Insira os dados do cliente.');
        setConfirmButtonText('Criar Simulação');
        setCurrentStep(currentStep + 1);
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          toast.warning('Por favor, preencha os dados corretamente');
          return;
        }
        toast.warning(
          'Houve um erro avançar para o próximo passo. Por favor, entre em contato com a equipe de suporte caso o erro persista.',
        );
      }
    },
    [
      currentStep,
      setName,
      setCPF_CNPJ,
      setEmail,
      setPhone,
      setCurrentStep,
      setDialogContentText,
      setConfirmButtonText,
    ],
  );

  return (
    <FormContainer
      ref={formRef}
      id="simulationForm"
      onSubmit={handleOnSubmit}
      style={{
        color: `${colors.text.fourth}`,
      }}
    >
      <InputLight name="name" label="Nome Completo" />
      <CPFCNPJInputNumber name="cpf_cnpj" label="CPF/CNPJ" />
      <InputLight name="email" label="E-mail" />
      <InputNumberLight
        name="phone"
        label="Telefone"
        format="(##) # #### - ####"
        isNumericString
      />
    </FormContainer>
  );
}

export default FirstStepForm;
