import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useLayoutEffect,
  useEffect,
} from 'react';

interface WindowContextData {
  isInMobile: boolean;
  width: number;
  height: number;
}

const WindowContext = createContext<WindowContextData>({} as WindowContextData);

interface WindowProvider {
  children: ReactNode;
}

export function WindowProvider({ children }: WindowProvider): JSX.Element {
  const [isInMobile, setIsInMobile] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(() => window.innerWidth);
  const [height, setHeight] = useState<number>(() => window.innerHeight);
  useLayoutEffect(() => {
    const handleWindowResize = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      setWidth(windowWidth);
      setHeight(windowHeight);
      setIsInMobile(windowWidth <= 768);
    };
    window.addEventListener('resize', handleWindowResize);
  }, []);
  useEffect(() => {
    setIsInMobile(width < 768);
  }, [width]);
  return (
    <WindowContext.Provider
      value={{
        isInMobile,
        height,
        width,
      }}
    >
      {children}
    </WindowContext.Provider>
  );
}

export function useWindow(): WindowContextData {
  const context = useContext(WindowContext);
  if (!context) {
    throw new Error('useWindow must be used within a WindowProvider');
  }
  return context;
}
