import { ApolloError, useLazyQuery } from '@apollo/client';
import React, { useCallback, useState, useEffect } from 'react';
import { AiFillPhone, AiOutlineUser } from 'react-icons/ai';
import { HiMail } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { Skeleton } from '@material-ui/lab';
import {
  getCompanyContactQueryResponse,
  getCompanyContactQueryVars,
  GET_COMPANY_CONTACT,
} from '../../../../../../../../../../../../../../../GraphQL/queries/getCompanyContact';
import { Contact } from '../../../../../../../../../../../../../../../GraphQL/queries/getContacts';
import { Divider } from '../../../../../../../../../../../../../../../templates/DashboardTemplate/Navbar/UserController/PaperController/style';
import formatDate from '../../../../../../../../../../../../../../../utils/formateDate';
import { formatPhoneNumber } from '../../../../../../../../../../../../../../../utils/formatPhoneNumber';
import { CardContainer } from '../../../CardContainer';
import {
  Container,
  ClientInformationContainer,
  ContactInformationContainer,
  IconContainer,
  PresentationSection,
  DateContainer,
} from './style';
import { useDeal } from '../../../../../../../../hooks/Deal';
import { useCompany } from '../../../../../../../../../../../../../../../hooks/Company';

import { useAuth } from '../../../../../../../../../../../../../../../hooks/Auth';

function ClientInformationCard(): JSX.Element {
  const { deal } = useDeal();
  const [contact, setContact] = useState<Contact>({} as Contact);
  const handleOnGetCompanyContactQueryCompleted = useCallback(
    ({ getCompanyContact: fetchedContact }: getCompanyContactQueryResponse) => {
      setContact(fetchedContact);
    },
    [],
  );
  const handleOnGetCompanyContactQueryError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(`Não foi possível obter o contato desse negócio: ${message}`);
    },
    [],
  );
  const [getCompanyContact, { loading }] = useLazyQuery<
    getCompanyContactQueryResponse,
    getCompanyContactQueryVars
  >(GET_COMPANY_CONTACT, {
    onCompleted: handleOnGetCompanyContactQueryCompleted,
    onError: handleOnGetCompanyContactQueryError,
  });
  const { company } = useCompany();
  const { isCompanyLogged } = useAuth();

  useEffect(() => {
    if (isCompanyLogged) {
      getCompanyContact({
        variables: {
          contactID: deal.CONTACT_ID,
        },
      });
    }
  }, [deal, getCompanyContact, isCompanyLogged]);

  return (
    <CardContainer>
      <Container>
        <PresentationSection>
          <IconContainer>
            <article>
              <AiOutlineUser />
            </article>
          </IconContainer>
          <ClientInformationContainer>
            {loading ? (
              <Skeleton />
            ) : (
              <>
                <h3>Cliente</h3>
                <h1>{contact.NAME}</h1>
                <h2>{company.name}</h2>
                <p>{contact.UF_CRM_1602185690 || contact.UF_CRM_1607694757}</p>
              </>
            )}
          </ClientInformationContainer>
        </PresentationSection>
        <Divider />
        <ContactInformationContainer>
          <article>
            <HiMail />
            {loading ? (
              <Skeleton />
            ) : (
              <p>{!!contact.EMAIL && contact.EMAIL[0].VALUE}</p>
            )}
          </article>
          <article>
            <AiFillPhone />
            {loading ? (
              <Skeleton variant="rect" />
            ) : (
              <p>
                {contact.PHONE && formatPhoneNumber(contact.PHONE[0].VALUE)}
              </p>
            )}
          </article>
        </ContactInformationContainer>
        {!loading && (
          <DateContainer>{formatDate(contact.DATE_CREATE)}</DateContainer>
        )}
      </Container>
    </CardContainer>
  );
}

export { ClientInformationCard };
