import React from 'react';

import {
  Button as MaterialButton,
  ButtonProps as MaterialButtonProps,
} from '@material-ui/core';
import colors from '../../styles/colors';

interface ButtonProps extends MaterialButtonProps {
  ismouseblocked?: boolean;
}

function Button({ children, ...rest }: ButtonProps): JSX.Element {
  return (
    <MaterialButton
      style={{
        height: '48px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: colors.primary.normal.first,
        color: colors.text.first,
        textTransform: 'none',
        padding: '0 1rem',
      }}
      {...rest}
    >
      {children}
    </MaterialButton>
  );
}

export default Button;
