import { CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';
import TableIcons from '../../../components/TableIcons';
import { useContacts } from '../hooks/Contacts';

import { Container, Content } from './style';

function ContactsTable(): JSX.Element {
  const { contacts, loading } = useContacts();

  return (
    <Container>
      <Content>
        {!loading ? (
          <MaterialTable
            columns={[
              { title: 'Nome', field: 'NAME' },
              { title: 'E-mail', field: 'EMAIL[0].VALUE' },
              { title: 'Telefone', field: 'PHONE[0].VALUE' },
            ]}
            data={JSON.parse(JSON.stringify(contacts))}
            icons={TableIcons}
            style={{
              width: '100%',
              height: '100%',
            }}
            options={{
              showTitle: false,
              minBodyHeight: 0,
              maxBodyHeight: '100%',
            }}
            localization={{
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
              },
              pagination: {
                nextTooltip: 'Próximo',
                previousTooltip: 'Anterior',
                lastTooltip: 'Ultimo',
                firstTooltip: 'Primeiro',
                labelRowsSelect: '',
                labelDisplayedRows: '{from}-{to} de {count}',
              },
            }}
          />
        ) : (
          <CircularProgress />
        )}
      </Content>
    </Container>
  );
}

export default ContactsTable;
