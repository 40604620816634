import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.section`
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
`;

export const Content = styled.article`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;

  padding-bottom: 0.5rem;

  & > h2 {
    color: ${colors.text.third};
    font-size: 1.25rem;
    line-height: 1rem;
  }
`;
