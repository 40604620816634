import { gql } from '@apollo/client';

export interface IRole {
  id: string;
  permissions: number;
  name: string;
}

export interface ICompany {
  id: string;
  name: string;
  personName: string;
  email: string;
  phone: string;
  cpf_cnpj: string;
  bitrix_id: number;
  roleId: IRole;
  createdAt: Date;
  updatedAt: Date;
  isSuspended: boolean;
}

export interface IGetCompanyQueryResponse {
  getCompany: ICompany;
}

const getCompanyQuery = gql`
  query getCompany {
    getCompany {
      id
      name
      personName
      email
      phone
      cpf_cnpj
      avatarFile
      bitrix_id
      createdAt
      updatedAt
      isSuspended
      roleId {
        id
        permissions
        name
      }
    }
  }
`;

export { getCompanyQuery };
