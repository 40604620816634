import styled from 'styled-components';

export const Container = styled.section`
  height: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const LabelContainer = styled.article`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.321rem;
  & > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.625rem;
  }

  & > h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.321rem;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #3f9177;

    &::before {
      width: 0.643rem;
      height: 0.643rem;
      content: '';
      border-radius: 0.322rem;
      background-color: #3f9177;
    }
  }
`;

export const AmountContainer = styled.h1`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 1.625rem;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;
