import { BaseTextFieldProps, MenuItem } from '@material-ui/core';
import { useField } from '@unform/core';
import React, { ChangeEvent, useEffect, useRef } from 'react';
import { SelectInputCustomized } from './style';

interface Option {
  value: number | string;
  label: string;
}

interface SelectProps extends BaseTextFieldProps {
  name: string;
  options: Option[];
  handleOnChange?(event: ChangeEvent<HTMLInputElement>): void;
}

function Select({
  name,
  options,
  handleOnChange,
  ...rest
}: SelectProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>();
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        // eslint-disable-next-line no-param-reassign
        ref.current.value = value;
      },
      clearValue: (ref) => {
        // eslint-disable-next-line no-param-reassign
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);
  return (
    <SelectInputCustomized
      inputRef={inputRef}
      variant="filled"
      inputProps={{
        name: fieldName,
        value: defaultValue,
      }}
      error={!!error}
      helperText={error}
      select
      fullWidth
      onChange={handleOnChange}
      {...rest}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </SelectInputCustomized>
  );
}

export default Select;
