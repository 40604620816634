import { Badge } from '@material-ui/core';
import React from 'react';
import Avatar from '../../../components/Avatar';
import ChangeAvatarButton from './ChangeAvatarButton';
import RemoveAvatarButton from './RemoveAvatarButton';

import { Container } from './style';

function ProfileSection(): JSX.Element {
  return (
    <Container>
      <Badge
        badgeContent={<ChangeAvatarButton />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        overlap="circle"
      >
        <Avatar
          style={{
            width: '5rem',
            height: '5rem',
          }}
        />
      </Badge>
      <RemoveAvatarButton />
    </Container>
  );
}

export default ProfileSection;
