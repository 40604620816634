import { CheckCircle } from '@material-ui/icons';
import React from 'react';
import { useSimulationSteps } from '../../../hooks/SimulationSteps';

import { FormContainer } from '../style';

function ThirdStepForm(): JSX.Element {
  const { handleOnClose } = useSimulationSteps();
  return (
    <FormContainer id="simulationForm" onSubmit={handleOnClose}>
      <CheckCircle />
    </FormContainer>
  );
}

export default ThirdStepForm;
