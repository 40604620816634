import { Dialog } from '@material-ui/core';
import styled from 'styled-components';

export const DialogCustomized = styled(Dialog)`
  height: 100vh;
  background-color: #f5f5f5;
  @media screen and (max-width: 768px) {
    min-height: 100%;
  }
`;

export const Container = styled.section`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  @media screen and (max-width: 768px) {
    display: grid;
    flex: 1;
    padding: 0.5rem;
  }
`;

export const Content = styled.section`
  padding: 1rem;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    padding: 0.5rem;
    flex-direction: column;
  }
`;

export const Header = styled.header`
  height: min-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
