import { FormHandles } from '@unform/core';
import React, { ChangeEvent, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { personTypes } from './types/personTypes';
import InputNumberLight from '../../../../../../../components/InputNumber/InputNumberLight';
import RadioGroup from '../../../../../../../components/RadioGroup';
import SelectLight from '../../../../../../../components/Select/SelectLight';
import { useDealsCategories } from '../../../../../../../hooks/DealsCategories';
import getValidationErrors from '../../../../../../../utils/getValidationErrors';
import { useSimulationSteps } from '../../../hooks/SimulationSteps';

import { FormContainer } from '../style';
import { realStateAmortizationType } from './types/realStateAmortizationTypes';
import InputDateLight from '../../../../../../../components/InputDate/InputDateLight';
import { vehicleAmortizationType } from './types/vehicleAmortizationType';

interface FormData {
  dealCategory: string;
  dealProduct: string;
  value: number;
  numberOfInstallments: number;
  amortizationType: string;
  personType: string;
  birthday: Date;
  paymentEntry?: number;
}

function SecondStepForm(): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const { dealsCategories } = useDealsCategories();
  const selectedDealCategory = formRef.current?.getData().dealCategory;
  const {
    name,
    email,
    cpf_cnpj,
    phone,
    selectedDealProduct,
    availableDealsProducts,
    createSimulation,
    setSelectedDealCategory,
    setSelectedDealProduct,
    setValue,
    setBirthday,
    setAmortizationType,
    setNumberOfInstallments,
    setPersonType,
    setPaymentEntry,
  } = useSimulationSteps();

  const realStateID = dealsCategories
    .filter((dealCategory) => {
      return dealCategory.bitrix_id === 1;
    })
    .map((dealCategory) => dealCategory.id)
    .join(',');

  const vehicleCreditID = dealsCategories
    .filter((dealCategory) => {
      return dealCategory.bitrix_id === 5;
    })
    .map((dealCategory) => dealCategory.id)
    .join(',');

  const handleOnDealCategorySelectChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const selectedDealCategoryID = event.target.value;
      const selectedDealsCategory = dealsCategories.find(
        (dealCategory) => dealCategory.id === selectedDealCategoryID,
      );
      if (selectedDealsCategory) {
        setSelectedDealCategory(selectedDealsCategory);
      }
    },
    [dealsCategories, setSelectedDealCategory],
  );

  const handleOnDealProductSelectChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const selectedDealProductID = event.target.value;
      const foundDealProduct = availableDealsProducts.find(
        (dealProduct) => dealProduct.id === selectedDealProductID,
      );
      if (foundDealProduct) {
        setSelectedDealProduct(foundDealProduct);
      }
    },
    [availableDealsProducts, setSelectedDealProduct],
  );

  const handleOnSubmit = useCallback(
    async (data: FormData) => {
      formRef.current?.setErrors({});
      const minYearDate = new Date(new Date().getFullYear() - 18, 1, 1);

      function validateAgePayment() {
        const maxAgeToPayFinanceAndPort = 80;
        const dateBirthday = data.birthday.getFullYear();
        const dateNow = new Date().getFullYear();
        const diffYears = dateNow - dateBirthday;
        const yearsToPayFinanceAndPort = data.numberOfInstallments / 12;
        return (
          yearsToPayFinanceAndPort + diffYears <= maxAgeToPayFinanceAndPort
        );
      }

      function validatePaymentEntry() {
        const totalValue = Number(data.value);
        const paymentEntry = Number(data.paymentEntry);
        const maxEntry = totalValue * 0.8;
        return paymentEntry <= maxEntry && paymentEntry <= totalValue;
      }

      function validateMaxParcels() {
        const parcels = data.numberOfInstallments;
        const isRealStateCredit = data.dealCategory === realStateID;
        const isVehicleCreditSelected = data.dealCategory === vehicleCreditID;
        return (
          isRealStateCredit ||
          (isVehicleCreditSelected && parcels <= 60 && !isRealStateCredit)
        );
      }

      try {
        const schema = yup.object().shape({
          value: yup
            .number()
            .required('Você precisa informar o valor do crédito')
            .positive('Por favor, insira um valor positivo'),
          birthday: yup
            .date()
            .required('Você precisar informar o sua data de nascimento')
            .max(minYearDate, 'Você precisa ter mais de 18 anos'),
          numberOfInstallments: yup
            .number()
            .required(
              'Você precisa informar o número de parcelas do seu crédito',
            )
            .positive('Por favor, insira um valor positivo')
            .max(
              selectedDealProduct.name === 'Refinanciamento' ? 240 : 420,
              `A quantidade máxima de parcelas para esse produto é de ${
                selectedDealProduct.name === 'Refinanciamento' ? 240 : 420
              } meses`,
            )
            .test(
              'Idade',
              'O cliente precisa terminar o pagamento aos 80 anos',
              () => validateAgePayment(),
            )
            .test('Parcelas', 'O valor máximo de parcelas é 60.', () =>
              validateMaxParcels(),
            ),
          dealCategory: yup
            .string()
            .required('Você precisa selecionar o tipo de crédito'),
          dealProduct: yup
            .string()
            .required('Você precisa selecionar um produto'),
          amortizationType: yup
            .string()
            .required('Você precisa selecionar o tipo de amortização'),
          personType: yup
            .string()
            .required('Você precisa selecionar o tipo de pessoa'),
          paymentEntry: yup
            .string()
            .required('Você precisa informar o valor do pagamento')
            .test(
              'Entrada',
              'O valor de entrada máxima é de 80% do valor do crédito',
              () => validatePaymentEntry(),
            ),
        });
        await schema.validate(data, { abortEarly: false });
        setValue(Number(data.value));
        setAmortizationType(data.amortizationType);
        setNumberOfInstallments(Number(data.numberOfInstallments));
        setPersonType(data.personType);
        setBirthday(data.birthday);
        setPaymentEntry(Number(data.paymentEntry));
        await createSimulation({
          name,
          email,
          cpf_cnpj,
          phone,
          birthday: data.birthday,
          personType: data.personType,
          amortizationType: Number(data.amortizationType),
          dealCategoryID: data.dealCategory,
          dealProductID: data.dealProduct,
          numberOfInstallments: Number(data.numberOfInstallments),
          value: Number(data.value),
          paymentEntry: Number(data.paymentEntry),
        });
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          toast.warning('Por favor, preencha os dados corretamente');
          return;
        }
        toast.warning(
          'Houve um erro avançar para o próximo passo. Por favor, entre em contato com a equipe de suporte caso o erro persista.',
        );
      }
    },
    [
      name,
      cpf_cnpj,
      email,
      phone,
      selectedDealProduct,
      realStateID,
      vehicleCreditID,
      setAmortizationType,
      setNumberOfInstallments,
      setPersonType,
      setValue,
      createSimulation,
      setBirthday,
      setPaymentEntry,
    ],
  );

  return (
    <FormContainer id="simulationForm" onSubmit={handleOnSubmit} ref={formRef}>
      <SelectLight
        name="dealCategory"
        label="Tipo de crédito"
        handleOnChange={handleOnDealCategorySelectChange}
        options={dealsCategories
          .filter((dealCategory) => {
            return dealCategory.bitrix_id === 1 || dealCategory.bitrix_id === 5;
          })
          .map((dealCategory) => ({
            label: dealCategory.name,
            value: dealCategory.id,
          }))}
      />
      <SelectLight
        name="dealProduct"
        label="Produto"
        handleOnChange={handleOnDealProductSelectChange}
        options={availableDealsProducts.map((dealProduct) => ({
          label: dealProduct.name,
          value: dealProduct.id,
        }))}
      />
      <InputDateLight name="birthday" label="Data de nascimento" />

      <InputNumberLight
        name="value"
        label={`${
          realStateID === selectedDealCategory
            ? 'Valor do imóvel'
            : 'Valor do veículo'
        }`}
        prefix="R$ "
      />
      <InputNumberLight
        name="numberOfInstallments"
        label="Quantidade de parcelas"
      />
      <InputNumberLight
        name="paymentEntry"
        label="Valor de Entrada"
        prefix="R$ "
      />
      {realStateID === selectedDealCategory ? (
        <RadioGroup
          legend="Tipo de amortização"
          name="amortizationType"
          options={realStateAmortizationType}
        />
      ) : null}

      {vehicleCreditID === selectedDealCategory ? (
        <RadioGroup name="amortizationType" options={vehicleAmortizationType} />
      ) : null}
      <RadioGroup
        legend="Pessoa Física ou Jurídica"
        name="personType"
        options={personTypes}
      />
    </FormContainer>
  );
}

export default SecondStepForm;
