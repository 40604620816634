import { CheckCircle } from '@material-ui/icons';
import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Button from '../../../components/Button';
import InputNumberLight from '../../../components/InputNumber/InputNumberLight';
import { useCompany } from '../../../hooks/Company';
import getValidationErros from '../../../utils/getValidationErrors';
import { useProfile } from '../hooks/Profile';
import ChangePassword from './ChangePassword/index';
import { Container, InputCustomized, RowDiv } from './style';

function ChangeDataForm(): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const { company } = useCompany();
  const { loading, updateProfile } = useProfile();
  const handleOnSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = yup.object().shape({
          companyName: yup
            .string()
            .required('Você precisa informar o nome da sua empresa'),
          password: yup
            .string()
            .min(8, 'Sua senha deve conter no mínimo 8 dígitos'),
          name: yup.string().required('Você precisa informar o seu nome'),
          phoneNumber: yup
            .string()
            .required('Você precisa informar o seu telefone para contato'),
        });
        await schema.validate(data, { abortEarly: false });
        updateProfile({
          variables: {
            name: data.companyName,
            personName: data.name,
            phone: data.phoneNumber,
            password: data.password,
          },
        });
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          toast.warning('Por favor, preencha os dados corretamente');
          return;
        }
        toast.warning('Houve um erro ao realizar as alterações do perfil');
      }
    },
    [updateProfile],
  );

  useEffect(() => {
    formRef.current?.setData({
      name: company.personName,
      companyName: company.name,
      cpf: company.cpf_cnpj,
      email: company.email,
      phoneNumber: company.phone,
    });
  }, [
    company.cpf_cnpj,
    company.email,
    company.name,
    company.personName,
    company.phone,
  ]);

  return (
    <>
      <Container ref={formRef} onSubmit={handleOnSubmit}>
        <InputCustomized name="companyName" label="Nome da Empresa" />
        <InputCustomized name="name" label="Seu nome" />
        <RowDiv>
          <InputNumberLight
            format="###.###.###-##"
            name="cpf"
            label="CPF/CNPJ"
            isNumericString
            disabled
          />
          <InputCustomized name="phoneNumber" label="Telefone" />
        </RowDiv>
        <InputCustomized name="email" label="E-mail" disabled />
        <InputCustomized name="password" label="Senha" type="password" />
        <Button
          disabled={loading}
          fullWidth
          type="submit"
          endIcon={<CheckCircle />}
        >
          {`${loading ? 'Salvando' : 'Salvar'} alterações`}
        </Button>
      </Container>
      <ChangePassword />
    </>
  );
}

export default ChangeDataForm;
