import styled from 'styled-components';

export const Container = styled.div`
  width: 300px;
  height: 250px;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 0fr min-content min-content;
  gap: 0.5rem;
  padding: 0.8rem;
  & .MuiPaper-elevation8 {
    border: 0.322581px solid #ebebf9;
    box-sizing: border-box;
    box-shadow: 0px 6.45161px 9.67742px rgba(0, 0, 0, 0.1),
      0px 2.58065px 3.87097px rgba(0, 0, 0, 0.05);
    border-radius: 5.16129px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border: 1px solid #31715C;
  content: '';
`;
