import { CircularProgress } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { useCallback, useState } from 'react';
import TableIcons from '../../../../components/TableIcons';
import Transition from '../../../../components/Transition';
import { SimulationData } from '../../../../GraphQL/mutations/createSimulation';
import { useDealsCategories } from '../../../../hooks/DealsCategories';
import formatDate from '../../../../utils/formateDate';
import formatToPTBRMoneyValue from '../../../../utils/formatMoney';
import CategorySelect from './ActionsDialog/CategorySelect';
import { useSimulation } from '../../hooks/simulation';
import ActionsDialog from './ActionsDialog';
import { Container, Content } from './style';

function SimulationsTable(): JSX.Element {
  const { isGetSimulationsQueryLoading, simulations } = useSimulation();
  const { dealsCategories } = useDealsCategories();
  const [dialogSimulation, setDialogSimulation] = useState<SimulationData>(
    {} as SimulationData,
  );
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const handleOnDialogClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);
  const [selectSimulationType, setSelectSimulationType] = useState<string>('');

  const realStateID = dealsCategories
    .filter((dealCategory) => {
      return dealCategory.bitrix_id === 1;
    })
    .map((dealCategory) => dealCategory.id)
    .join(',');

  const vehicleCreditID = dealsCategories
    .filter((dealCategory) => {
      return dealCategory.bitrix_id === 5;
    })
    .map((dealCategory) => dealCategory.id)
    .join(',');

  const realStateSimulation = simulations
    .filter((simulation) => simulation.dealCategory?.id === realStateID)
    .map((simulation) => ({
      ...simulation,
      dealCategory: 'Crédito imobiliário',
      amortizationType: simulation.amortizationType === 0 ? 'SAC' : 'PRICE',
      createdAt: formatDate(String(simulation.createdAt)),
      value: formatToPTBRMoneyValue(simulation.value),
    }))
    .reverse();

  const vehicleSimulation = simulations
    .filter((simulation) => simulation.dealCategory?.id === vehicleCreditID)
    .map((simulation) => ({
      ...simulation,
      dealCategory: 'Crédito veicular',
      amortizationType: 'Padrão',
      createdAt: formatDate(String(simulation.createdAt)),
      value: formatToPTBRMoneyValue(simulation.value),
    }))
    .reverse();

  const handleCreditType = useCallback(
    (category: string) => {
      setSelectSimulationType(category);
    },
    [setSelectSimulationType],
  );

  return (
    <>
      <Container>
        <CategorySelect onCategorySelected={handleCreditType} />
        <Content>
          {!isGetSimulationsQueryLoading ? (
            <MaterialTable
              columns={[
                { title: 'Nome', field: 'name' },
                { title: 'Tipo de crédito', field: 'dealCategory' },
                { title: 'Tabela', field: 'amortizationType' },
                { title: 'Valor', field: 'value' },
                {
                  title: 'Parcelas',
                  field: 'numberOfInstallments',
                  width: 0,
                },
                { title: 'E-mail', field: 'email' },
                { title: 'Criado em', field: 'createdAt' },
              ]}
              data={JSON.parse(JSON.stringify(selectSimulationType === vehicleCreditID ? vehicleSimulation : realStateSimulation))}
              icons={TableIcons}
              style={{
                width: '100%',
                height: '100%',
              }}
              options={{
                showTitle: false,
                minBodyHeight: 0,
                maxBodyHeight: '100%',
                actionsColumnIndex: -1,
              }}
              actions={[
                {
                  icon: () => <Visibility />,
                  tooltip: 'Opções',
                  onClick: (_, rowData) => {
                    const data = rowData as SimulationData;
                    const foundSimulation = simulations.find(
                      (simulation) => simulation.id === data.id,
                    );
                    if (typeof foundSimulation !== 'undefined') {
                      setDialogSimulation(foundSimulation);
                      setIsDialogOpen(true);
                    }
                  },
                },
              ]}
              localization={{
                toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar',
                },
                pagination: {
                  nextTooltip: 'Próximo',
                  previousTooltip: 'Anterior',
                  lastTooltip: 'Ultimo',
                  firstTooltip: 'Primeiro',
                  labelRowsSelect: '',
                  labelDisplayedRows: '{from}-{to} de {count}',
                },
                body: {
                  emptyDataSourceMessage: 'Você ainda não tem simulações.',
                },
                header: {
                  actions: 'Visualizar',
                },
              }}
            />
          ) : (
            <CircularProgress />
          )}
        </Content>
      </Container>
      <ActionsDialog
        simulation={dialogSimulation}
        open={isDialogOpen}
        TransitionComponent={Transition}
        handleOnClose={handleOnDialogClose}
        fullScreen
      />
    </>
  );
}

export default SimulationsTable;
