import { ApolloError, useLazyQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback, useContext,
  useEffect, useState
} from 'react';
import { toast } from 'react-toastify';
import { BitrixDealData } from '../../../../../../../../../../GraphQL/queries/getDealsUpdated';
import {
  IBitrixTimeLineComment,
  listTimeLineCommentsQueryResponse,
  listTimeLineCommentsQueryVars,
  LIST_TIMELINE_COMMENTS
} from '../../../../../../../../../../GraphQL/queries/listTimeLineComments';
import { useAuth } from '../../../../../../../../../../hooks/Auth';
import { useDeal } from '../../../hooks/Deal';



interface IDealCommentsContextData {
  deal: BitrixDealData;
  comments: IBitrixTimeLineComment[];
  setComments(comments: IBitrixTimeLineComment[]): void;
  loading: boolean;
}

const dealCommentsContext = createContext<IDealCommentsContextData>(
  {} as IDealCommentsContextData,
);

interface IDealCommentsProvider {
  children: ReactNode;
}

export function DealCommentsProvider({
  children,
}: IDealCommentsProvider): JSX.Element {
  const [comments, setComments] = useState<IBitrixTimeLineComment[]>(
    [] as IBitrixTimeLineComment[],
  );

  const handleOnListDealCommentsTimelineQueryCompleted = useCallback(
    ({ listTimeLineComments }: listTimeLineCommentsQueryResponse) => {
      const { result } = listTimeLineComments;
      setComments(result);
    },
    [],
  );

  const handleOnListDealCommentsTimelineQueryError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(`Não foi possível os comentários deste negócio: ${message}`);
    },
    [],
  );

  const [listTimelineComments, { loading }] = useLazyQuery<
    listTimeLineCommentsQueryResponse,
    listTimeLineCommentsQueryVars
  >(LIST_TIMELINE_COMMENTS, {
    onCompleted: handleOnListDealCommentsTimelineQueryCompleted,
    onError: handleOnListDealCommentsTimelineQueryError,
  });

  const { selectedDeal, deal } = useDeal();
  const { isCompanyLogged } = useAuth();

  useEffect(() => {
    if (selectedDeal && isCompanyLogged) {
      listTimelineComments({
        variables: {
          id: selectedDeal,
        },
      });
    } else {
      setComments([] as IBitrixTimeLineComment[]);
    }
  }, [selectedDeal, isCompanyLogged, listTimelineComments]);

  return (
    <dealCommentsContext.Provider
      value={{
        comments,
        deal,
        loading,
        setComments,
      }}
    >
      {children}
    </dealCommentsContext.Provider>
  );
}

export function useDealComments(): IDealCommentsContextData {
  const context = useContext(dealCommentsContext);
  if (!context) {
    throw new Error(
      'useDealComments should be used within a DealCommentsProvider',
    );
  }
  return context;
}
