import { ApolloError, useLazyQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import GET_DEALS_CATEGORIES, {
  DealCategoryData,
  getDealsCategoriesQueryResponse,
} from '../GraphQL/queries/getDealsCategories';
import { useCompany } from './Company';

interface DealsCategoriesContextData {
  dealsCategories: DealCategoryData[];
  setDealsCategories(dealsCategories: DealCategoryData[]): void;
  isGetDealsCategoriesQueryLoading: boolean;
}

const DealsCategoriesContext = createContext<DealsCategoriesContextData>(
  {} as DealsCategoriesContextData,
);

interface DealsCategoriesProviderProps {
  children: ReactNode;
}

export function DealsCategoriesProvider({
  children,
}: DealsCategoriesProviderProps): JSX.Element {
  const [dealsCategories, setDealsCategories] = useState<DealCategoryData[]>(
    [] as DealCategoryData[],
  );

  const handleOnGetDealsCategoriesQueryCompleted = useCallback(
    ({ getDealsCategories }: getDealsCategoriesQueryResponse) => {
      setDealsCategories(getDealsCategories);
    },
    [setDealsCategories],
  );

  const handleOnGetDealsCategoriesQueryError = useCallback(
    ({ message }: ApolloError) => {
      toast.warn(
        `Não foi possível obter as categorias de negócios da plataforma: ${message}`,
      );
    },
    [],
  );

  const [
    getDealsCategories,
    { loading: isGetDealsCategoriesQueryLoading },
  ] = useLazyQuery<getDealsCategoriesQueryResponse>(GET_DEALS_CATEGORIES, {
    onCompleted: handleOnGetDealsCategoriesQueryCompleted,
    onError: handleOnGetDealsCategoriesQueryError,
  });

  const { company } = useCompany();

  useEffect(() => {
    if (company) {
      getDealsCategories();
    } else {
      setDealsCategories([] as DealCategoryData[]);
    }
  }, [company, getDealsCategories]);

  return (
    <DealsCategoriesContext.Provider
      value={{
        dealsCategories,
        isGetDealsCategoriesQueryLoading,
        setDealsCategories,
      }}
    >
      {children}
    </DealsCategoriesContext.Provider>
  );
}

export function useDealsCategories(): DealsCategoriesContextData {
  const context = useContext(DealsCategoriesContext);
  if (!context) {
    throw new Error(
      'useDealsCategories must be used within a DealsCategoriesProvider',
    );
  }
  return context;
}
