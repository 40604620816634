import { withStyles, TextField } from '@material-ui/core';
import { Form } from '@unform/web';
import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const Container = styled(Form)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const ButtonsContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
`;

export const InputCustomizedRed = withStyles({
  root: {
    '& input': {
      color: colors.text.fourth,
    },
    '& label': {
      color: colors.error.third,
    },
    '& label.Mui-focused, label.MuiFormLabel-filled': {
      color: colors.error.third,
    },
    '& .MuiFilledInput-underline:before': {
      borderBottomColor: colors.error.third,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: colors.error.third,
    },
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: colors.error.first,
    },
    '& .Mui-error': {
      color: colors.error.second,
    },
  },
})(TextField);

export const Text = styled.p`
  font-size: 0.8rem;
  text-align: center;
  color: #888;
`;
