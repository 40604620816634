import { IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import React, { RefObject, useCallback } from 'react';

import { Container } from './style';

interface ScrollToLeftButtonProps {
  reference: RefObject<HTMLDivElement>;
}

function ScrollToLeftButton({
  reference,
}: ScrollToLeftButtonProps): JSX.Element {
  const scrollReference = useCallback(() => {
    if (reference.current) {
      // eslint-disable-next-line no-param-reassign
      reference.current.scrollLeft -= 1000;
    }
  }, [reference]);

  return (
    <Container>
      <IconButton onClick={scrollReference}>
        <ChevronLeft />
      </IconButton>
    </Container>
  );
}

export default ScrollToLeftButton;
