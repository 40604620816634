import { gql } from '@apollo/client';

export interface suspendCompanyIDMutationVars {
  companyID: string;
}

export interface suspendCompanyIDMutationResponse {
  getSuspendedCompanyID: string;
}

const suspendCompany = gql`
  mutation suspendCompany($companyID: String!) {
    suspendCompany(data: { companyID: $companyID }) {
      id
    }
  }
`;

export default suspendCompany;
