import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DealCategoryData } from '../../../../../GraphQL/queries/getDealsCategories';
import { DealProductData } from '../../../../../GraphQL/queries/getDealsProducts';
import { useDealsProducts } from '../../../../../hooks/DealsProducts';

interface DealsContextData {
  selectedDealCategory: DealCategoryData;
  setSelectedDealCategory(dealCategory: DealCategoryData): void;
  selectedCategoryDealsProducts: DealProductData[];
  setSelectedCategoryDealsProducts(dealsProducts: DealProductData[]): void;
}

const DealsContext = createContext<DealsContextData>({} as DealsContextData);

interface DealsProviderProps {
  children: ReactNode;
}

export function DealsProvider({ children }: DealsProviderProps): JSX.Element {
  const [
    selectedDealCategory,
    setSelectedDealCategory,
  ] = useState<DealCategoryData>({} as DealCategoryData);

  const [
    selectedCategoryDealsProducts,
    setSelectedCategoryDealsProducts,
  ] = useState<DealProductData[]>([] as DealProductData[]);

  const { dealsProducts } = useDealsProducts();

  useEffect(() => {
    const dealsProductsFiltered = dealsProducts.filter(
      (dealProduct) => dealProduct.dealCategory.id === selectedDealCategory.id,
    );
    setSelectedCategoryDealsProducts(dealsProductsFiltered);
  }, [dealsProducts, selectedDealCategory]);

  return (
    <DealsContext.Provider
      value={{
        selectedDealCategory,
        setSelectedDealCategory,
        selectedCategoryDealsProducts,
        setSelectedCategoryDealsProducts,
      }}
    >
      {children}
    </DealsContext.Provider>
  );
}

export function useDeals(): DealsContextData {
  const context = useContext(DealsContext);
  if (!context) {
    throw new Error('useDealsCategories must be used within a DealsProvider');
  }
  return context;
}
