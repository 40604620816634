import React, { useCallback, useEffect, useState } from 'react';
import { DealCategoryStageData } from '../../../../../../GraphQL/queries/getDealsCategoryStages';
import { BitrixDealData } from '../../../../../../GraphQL/queries/getDealsUpdated';
import formatToPTBRMoneyValue from '../../../../../../utils/formatMoney';
import DealCard from './DealCard';
import { DealStageProvider } from './hooks/DealStage';

import { Container, Header, Section } from './style';

interface DealsStatusProps {
  deals: BitrixDealData[];
  title: string;
  dealStage: DealCategoryStageData;
}

function DealsStatus({
  deals,
  dealStage,
  title,
}: DealsStatusProps): JSX.Element {
  const [totalValue, setTotalValue] = useState<number>(0);
  const getTotalValue = useCallback((): number => {
    let totalOpportunity = 0;
    deals.forEach((deal) => {
      totalOpportunity += deal.OPPORTUNITY;
    });
    return totalOpportunity;
  }, [deals]);
  useEffect(() => {
    setTotalValue(getTotalValue());
  }, [getTotalValue]);
  return (
    <DealStageProvider dealStage={dealStage}>
      <Container>
        <Header>
          <h1>{title}</h1>
          <h2>{formatToPTBRMoneyValue(totalValue)}</h2>
        </Header>
        <Section hideScrollbars={false}>
          {deals.map((deal) => (
            <DealCard deal={deal} key={deal.ID} />
          ))}
        </Section>
      </Container>
    </DealStageProvider>
  );
}

export default DealsStatus;
