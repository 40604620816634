import React, { useCallback } from 'react';
import { useCompany } from '../../../../hooks/Company';
import { useModal } from '../../../../hooks/Modal';
import { useOnBoard } from '../../../../hooks/Onboard';

import Finish from '../Finish';

import {
  Container,
  ControllerContainer,
  ButtonFilled,
  ButtonOutlined,
} from './style';

function Welcome(): JSX.Element {
  const { company } = useCompany();

  const {
    setIsTourOpen,
    setHandleOnBeforeClose,
    setCurrentStep,
  } = useOnBoard();
  const { setIsModalOpen, setModalContent } = useModal();

  const handleOnBeforeClose = useCallback(() => {
    setIsTourOpen(false);
    setIsModalOpen(true);
  }, [setIsModalOpen, setIsTourOpen]);

  const handleOnWantToLearnButtonClick = useCallback(() => {
    setCurrentStep(0);
    setHandleOnBeforeClose(() => handleOnBeforeClose);
    setIsModalOpen(false);
    setIsTourOpen(true);
    setModalContent(<Finish />);
  }, [
    handleOnBeforeClose,
    setCurrentStep,
    setHandleOnBeforeClose,
    setIsModalOpen,
    setIsTourOpen,
    setModalContent,
  ]);

  const handleOnTryLaterButtonClick = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <Container>
      <h1>
        {`Olá, ${company.personName}.`}
        <br />
        Seja bem-vindo(a)!
      </h1>
      <p>Deseja iniciar um beve tutorial de como usar a plataforma?</p>
      <ControllerContainer>
        <ButtonFilled onClick={handleOnWantToLearnButtonClick}>
          Quero Aprender
        </ButtonFilled>
        <ButtonOutlined onClick={handleOnTryLaterButtonClick}>
          Tentar depois
        </ButtonOutlined>
      </ControllerContainer>
    </Container>
  );
}

export default Welcome;
