import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { Fade, Modal } from '@material-ui/core';

interface ModalContextData {
  setIsModalOpen(isModalOpen: boolean): void;
  setModalContent(content: ReactNode): void;
  handleOnModalClose(): void;
}

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

interface ModalProviderProps {
  children: ReactNode;
}

export function ModalProvider({ children }: ModalProviderProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [ModalContent, setModalContent] = useState<ReactElement>(
    (<></>) as ReactElement,
  );
  const handleOnModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        setIsModalOpen,
        setModalContent,
        handleOnModalClose,
      }}
    >
      <Modal
        open={isModalOpen}
        onClose={handleOnModalClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>{ModalContent}</Fade>
      </Modal>
      {children}
    </ModalContext.Provider>
  );
}

export function useModal(): ModalContextData {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}
