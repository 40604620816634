import { useQuery } from '@apollo/client';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import GET_CONTACTS, {
  Contact,
  getContactsQueryData,
  getContactsQueryVars,
} from '../../../GraphQL/queries/getContacts';
import { useAuth } from '../../../hooks/Auth';

interface ContactsContextData {
  contacts: Contact[];
  setContacts(contacts: Contact[]): void;
  loading: boolean;
}

const ContactsContext = createContext<ContactsContextData>(
  {} as ContactsContextData,
);

interface ContactsProviderProps {
  children: ReactNode;
}

export function ContactsProvider({
  children,
}: ContactsProviderProps): JSX.Element {
  const [contacts, setContacts] = useState<Contact[]>([] as Contact[]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { isCompanyLogged } = useAuth();
  const handleOnLoadContactsCompleted = useCallback(
    ({ getContacts }: getContactsQueryData) => {
      const { result, next } = getContacts;
      setContacts([...contacts, ...result]);
      if (next) {
        setCurrentPage(next);
      }
    },
    [contacts],
  );

  const { loading, refetch } = useQuery<
    getContactsQueryData,
    getContactsQueryVars
  >(GET_CONTACTS, {
    onCompleted: handleOnLoadContactsCompleted,
  });

  useEffect(() => {
    if (isCompanyLogged) {
      refetch({ page: currentPage });
    } else {
      setCurrentPage(0);
      setContacts([] as Contact[]);
    }
  }, [currentPage, isCompanyLogged, refetch]);

  return (
    <ContactsContext.Provider value={{ contacts, setContacts, loading }}>
      {children}
    </ContactsContext.Provider>
  );
}

export function useContacts(): ContactsContextData {
  const context = useContext(ContactsContext);
  if (!context) {
    throw new Error('useContacts must be used within a ContactsProvider');
  }
  return context;
}
