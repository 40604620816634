import { /* ApolloError, */ useLazyQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState
} from 'react';
/* import { toast } from 'react-toastify'; */
import GET_ADMIN_DATA, {
  GetAdminDataQueryData,
  GetAdminDataQueryResponse
} from '../../../GraphQL/queries/getAdminData';

interface AdminContextData {
  adminData: GetAdminDataQueryData;
}

const AdminContext = createContext<AdminContextData>({} as AdminContextData);

interface AdminProviderProps {
  children: ReactNode;
}

export function AdminProvider({ children }: AdminProviderProps): JSX.Element {
  const [adminData, setAdminData] = useState<GetAdminDataQueryData>(
    {} as GetAdminDataQueryData,
  );


  const handleOnGetAdminDataQueryCompleted = useCallback(
    ({ getAdminData }: GetAdminDataQueryResponse) => {
      setAdminData(getAdminData);
    },
    [],
  );

  /* const handleOnGetAdminDataQueryError = useCallback((error: ApolloError) => {

    toast.warn(
      `Não foi possível consultar os dados da seção de administração: ${error.message}`,
    );
  }, []); */

  useLazyQuery<GetAdminDataQueryResponse>(GET_ADMIN_DATA, {
    onCompleted: handleOnGetAdminDataQueryCompleted,
    /* onError: handleOnGetAdminDataQueryError, */
  });


  /* useEffect(() => {
    if (isCompanyLogged && isAdmin) {
      getAdminData();
    }
  }, [isCompanyLogged, isAdmin, getAdminData]); */


  return (
    <AdminContext.Provider
      value={{
        adminData,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}

export function useAdmin(): AdminContextData {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('useAdmin must be used within an AdminProvider');
  }
  return context;
}
