export const realStateAmortizationType = [
  {
    value: '0',
    label: 'SAC (Parcelas decrescentes)',
  },
  {
    value: '1',
    label: 'PRICE (Parcelas fixas)',
  },
];
