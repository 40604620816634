import { gql } from '@apollo/client';

export interface GetDealCategoryStagesQueryVars {
  dealCategoryID: string;
}

export interface DealCategoryStageData {
  NAME: string;
  SORT: number;
  STATUS_ID: string;
}

export interface GetDealCategoryStagesQueryResponse {
  getDealCategoryStages: DealCategoryStageData[];
}

const GET_DEAL_CATEGORY_STAGE = gql`
  query getDealCategoryStages($dealCategoryID: String!) {
    getDealCategoryStages(data: { dealCategoryID: $dealCategoryID }) {
      NAME
      SORT
      STATUS_ID
    }
  }
`;

export default GET_DEAL_CATEGORY_STAGE;
