import React from 'react';
import { ClientInformationCard } from './components/DealInformation/components/ClientInformationCard';
import { DealInformation } from './components/DealInformation/components/CreditInformationCard';

import { Container } from './style';

function ClientInformationSection(): JSX.Element {
  return (
    <Container>
      <DealInformation />
      <ClientInformationCard />
    </Container>
  );
}

export { ClientInformationSection };
