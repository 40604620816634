import { gql } from '@apollo/client';

export interface LoginMutationVars {
  email: string;
  password: string;
}

export interface LoginMutationData {
  token: string;
}

export interface LoginMutationResponse {
  login: LoginMutationData;
}

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      token
    }
  }
`;

export default LOGIN;
