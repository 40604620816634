import React from 'react';
import DealsProductsSection from './DealsProductsSection';
import Header from './Header';
import { DealsProvider } from './Hooks/Deals';

function DealsCategoriesSection(): JSX.Element {
  return (
    <>
      <DealsProvider>
        <Header />
        <DealsProductsSection />
      </DealsProvider>
    </>
  );
}

export default DealsCategoriesSection;
