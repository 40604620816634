import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-auto-flow: row;
  gap: 1rem;
`;

export const Content = styled.article`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  gap: 1rem;

  @media screen and (max-width: 1500px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContentSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
`;


export const TableSection = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;

  @media screen and (max-width: 1500px){
    max-width: 90%;
    margin: 0 auto;
  }
`;
