import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Icon = styled.span`
  width: 60px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333;
`;

export const ButtonLink = styled.button`
  background-color: ${colors.text.third};
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 100px;
  cursor: pointer;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  color: ${colors.text.first};
  &:hover {
    background-color: ${colors.primary.normal.first};
  }
`;

export const ProductItemContainer = styled.div`
  width: 13.125rem;
  height: 13.125rem;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  font-size: 0.8rem;

  span {
    font-weight: bold;
    color: #000;
    font-size: 1.3rem;
  }

  span > img {
    width: 65px;
    margin-bottom: -5px;
  }

  span > p {
    font-size: 1.25rem;
  }

  &:hover {
    transform: scale(1.05);
  }
`;
