import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { ApolloError, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ChevronRight } from '@material-ui/icons';
import { Container, Content, SignInContainer } from './style';
import CREATE_COMPANY, {
  createCompanyMutationResponse,
  createCompanyMutationVars,
} from '../../GraphQL/mutations/createCompany';
import getValidationErrors from '../../utils/getValidationErrors';
import Logo from '../../assets/logo.color.light.svg';
import Input from '../../components/Input';
import InputNumber from '../../components/InputNumber';
import Button from '../../components/Button';
import TermsAndConditionsMessage from '../../components/TermsAndConditionsMessage';
import validateCPF from '../../utils/ValidateCPF';
import { CPFCNPJInputNumber } from '../../components/InputNumber/CPFCNPJInputNumber';
import { validateCNPJ } from '../../utils/ValidateCNPJ';

interface RouteParams {
  id: string;
}

function SignUp(): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { id: token } = useParams<RouteParams>();
  const handleOnCreateCompanyCompleted = useCallback(() => {
    toast.success('O seu cadastro foi realizado com sucesso');
    history.push('/login');
  }, [history]);

  const handleOnCreateCompanyError = useCallback((error: ApolloError) => {
    toast.warning(error.message);
  }, []);

  const [createCompany, { loading }] = useMutation<
    createCompanyMutationResponse,
    createCompanyMutationVars
  >(CREATE_COMPANY, {
    onCompleted: handleOnCreateCompanyCompleted,
    onError: handleOnCreateCompanyError,
  });

  const handleOnSubmit = useCallback(
    async (data: Omit<createCompanyMutationVars, 'token'>) => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required(
            'É necessário informar o nome da sua empresa',
          ),
          personName: Yup.string().required('Nos informe o seu nome pessoal'),
          cpf_cnpj: Yup.string()
            .required('É necessário informa o CPF/CNPJ da sua empresa')
            .test(
              'cpf',
              'Você precisa inserir um CPF/CNPJ válido',
              (value) =>
                validateCPF(String(value)) || validateCNPJ(String(value)),
            ),
          email: Yup.string()
            .required('Você precisa digitar um email')
            .email('Digite um email válido'),
          password: Yup.string().min(
            8,
            'Sua senha deve conter no mínimo 8 dígitos',
          ),
          confirmPassword: Yup.string()
            .min(8, 'A senha deve conter pelo menos 8 caracteres')
            .oneOf([Yup.ref('password'), null], 'As senhas não coincidem'),
          phone: Yup.string().required('Nos informe um telefone para contato'),
        });
        await schema.validate(data, { abortEarly: false });
        const dataWithToken: createCompanyMutationVars = { ...data, token };
        await createCompany({
          variables: dataWithToken,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          toast.warning('Por favor, preencha os dados corretamente');
          return;
        }
        toast.warning(
          'Houve um erro ao cadastrar usuário. Por favor, tente novamente mais tarde.',
        );
      }
    },
    [createCompany, token],
  );
  return (
    <Container>
      <Content ref={formRef} onSubmit={handleOnSubmit}>
        <img src={Logo} alt="Logotipo LCC" className="logo" />
        <Input name="name" label="Nome da empresa" />
        <Input name="personName" label="Seu nome" />
        <CPFCNPJInputNumber name="cpf_cnpj" />
        <Input name="email" label="E-mail" />
        <InputNumber name="phone" label="Telefone" format="(##) # ####-####" />
        <Input name="password" label="Senha" type="password" />
        <Input
          name="confirmPassword"
          label="Confirmação da senha"
          type="password"
        />

        <TermsAndConditionsMessage ButtonName="Cadastrar" />
        <Button fullWidth type="submit" endIcon={<ChevronRight />}>
          {loading ? 'Cadastrando...' : 'Cadastrar'}
        </Button>
        <SignInContainer>
          <p>Já tem login?</p>
          <Link to="/login">Faça o login</Link>
        </SignInContainer>
      </Content>
    </Container>
  );
}

export default SignUp;
