import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MenuOutlined } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../../hooks/Auth';

import { Container } from './style';

function Burger(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const { signOut } = useAuth();
  const handleOnClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleOnClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOnBusinessButtonClick = useCallback(() => {
    setAnchorEl(null);
    history.push('/negocios');
  }, [history]);

  const handleOnContactsButtonClick = useCallback(() => {
    setAnchorEl(null);
    history.push('/clientes');
  }, [history]);

  const handleOnCoursesButtonClick = useCallback(() => {
    setAnchorEl(null);
    history.push('/aprendizagem');
  }, [history]);

  const handleOnLogoutButtonCLick = useCallback(() => {
    signOut();
    setAnchorEl(null);
  }, [signOut]);

  return (
    <Container>
      <IconButton
        aria-label="menu"
        aria-controls="burger-menu"
        aria-haspopup="true"
        onClick={handleOnClick}
      >
        <MenuOutlined />
      </IconButton>
      <Menu
        id="burger-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleOnClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleOnBusinessButtonClick}>Negócios</MenuItem>
        <MenuItem onClick={handleOnContactsButtonClick}>Contatos</MenuItem>
        <MenuItem onClick={handleOnCoursesButtonClick}>Cursos</MenuItem>
        <MenuItem onClick={handleOnLogoutButtonCLick}>Sair</MenuItem>
      </Menu>
    </Container>
  );
}

export default Burger;
