import React, { useCallback, useState } from 'react';

import { Container } from './style';

import Logo from '../../../assets/logo.color.light.svg';
import LogoMobile from '../../../assets/logoMobile.png';
import Burger from './Burger';
import { useCompany } from '../../../hooks/Company';

function Header(): JSX.Element {
  const [logo, setLogo] = useState<string>(() => {
    const windowSize = window.innerWidth;
    return windowSize ? Logo : LogoMobile;
  });
  const { company } = useCompany();
  const handleOnWindowResize = useCallback(() => {
    const windowSize = window.innerWidth;
    setLogo(windowSize >= 768 ? Logo : LogoMobile);
  }, []);

  window.addEventListener('resite', handleOnWindowResize);
  return (
    <Container>
      <Burger />
      <img src={logo} alt="Logo Lucrando com Crédito" />
      <p>
        Olá,&nbsp;
        <span>{company.name}</span>
      </p>
    </Container>
  );
}

export default Header;
