import { gql } from '@apollo/client';
import { DealProductData } from './getDealsProducts';

export interface DealCategoryData {
  id: string;
  name: string;
  bitrix_id: number;
  isVisible: boolean;
  createdAt: Date;
  updatedAt: Date;
  products: string[];
  bitrixProductsField: DealProductData;
  isInDevelopment: boolean;
  url?: string;
  dealCategory: DealCategoryData;
}

export interface getDealsCategoriesQueryResponse {
  getDealsCategories: DealCategoryData[];
}

const GET_DEALS_CATEGORIES = gql`
  query getDealsCategories {
    getDealsCategories {
      id
      name
      bitrix_id
      isVisible
      createdAt
      updatedAt
      products {
        id
        name
        bitrix_id
        averageRate
        competitiveRate
        createdAt
        updatedAt
      }
      isInDevelopment
      url
    }
  }
`;

export default GET_DEALS_CATEGORIES;
