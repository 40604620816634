import React from 'react';
import { Switch } from 'react-router-dom';
import Route from '../components/Route';
import Admin from '../pages/Admin';
import Contacts from '../pages/Contacts';
import Deals from '../pages/Deals';
import Home from '../pages/Home';
import Links from '../pages/Links';
import Login from '../pages/Login';
import Onboard from '../pages/Onboard';
import Privacy from '../pages/Policies/Privacy';
import TermsAndConditions from '../pages/Policies/TermsAndConditions';
import Profile from '../pages/Profile';
import RecoverPassword from '../pages/RecoverPassword';
import RecoverPasswordRequest from '../pages/RecoverPasswordRequest';
import SignUp from '../pages/SignUp';
import SignUpBasic from '../pages/SignUpBasic';
import Simulation from '../pages/Simulation';
import DashboardTemplate from '../templates/DashboardTemplate';
/* import NewsOnboard from '../pages/UpdateOnboard' */

function Routes(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/" component={Home} isPrivate />
      <Route path="/login" component={Login} />
      <Route exact path="/cadastro" component={SignUpBasic} />
      <Route path="/cadastro/:id" component={SignUp} />
      <Route exact path="/recuperar-senha" component={RecoverPasswordRequest} />
      <Route path="/recuperar-senha/:token" component={RecoverPassword} />
      <Route path="/onboarding" component={Onboard} />
      {/* <Route path="/novidades" component={NewsOnboard} /> */}
      <Route path="/sobre/privacidade" component={Privacy} />
      <Route path="/sobre/termos" component={TermsAndConditions} />
      <DashboardTemplate>
        <Route path="/negocios" component={Deals} isPrivate />
        <Route path="/clientes" component={Contacts} isPrivate />
        <Route path="/links" component={Links} isPrivate />
        <Route path="/perfil" component={Profile} isPrivate />
        <Route path="/admin" component={Admin} isPrivate permission={2048} />
        <Route path="/simulacao" component={Simulation} isPrivate />
      </DashboardTemplate>
    </Switch>
  );
}

export default Routes;
