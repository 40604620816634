import React, { useCallback, useEffect, ReactNode } from 'react';

interface ChatWidgetProps {
  children: ReactNode;
}

function ChatWidget({ children }: ChatWidgetProps): JSX.Element {
  const insertChartWidget = useCallback(() => {
    const widgetURL =
      'https://cdn.bitrix24.com.br/b15513153/crm/site_button/loader_3_qoo4pp.js';
    const widgetScript = document.createElement('script');
    widgetScript.async = true;
    widgetScript.src = `${widgetURL}?${Date.now() / 60000 || 0}`;
    const arrayOfScriptElements = document.getElementsByTagName('script');
    const h = arrayOfScriptElements[arrayOfScriptElements.length - 1];
    h.parentNode?.insertBefore(widgetScript, h);
  }, []);
  useEffect(() => {
    insertChartWidget();
  }, [insertChartWidget]);
  return <>{children}</>;
}

export { ChatWidget };
