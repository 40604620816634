import { Form } from '@unform/web';
import styled from 'styled-components';
import colors from '../../../../../../styles/colors';

export const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  & > svg {
    color: ${colors.primary.normal.first};
    height: 10rem;
    width: 10rem;
  }
`;
