import React from 'react';
import Navbar from '../../templates/DashboardTemplate/Navbar';
import AddBusinessButton from './components/AddBusinessButton';
import DealCategorySelect from './components/DealCategorySelect';
import DealsSection from './components/DealsSection';
import ScrollDiv from './components/ScrollDiv';
import { BusinessProvider } from './hooks/Business';
import { NavbarButtonsContainer, Container } from './style';

function Deals(): JSX.Element {
  return (
    <BusinessProvider>
      <Navbar title="Meus Negócios">
        <NavbarButtonsContainer>
          <DealCategorySelect />
          <AddBusinessButton />
        </NavbarButtonsContainer>
      </Navbar>
      <Container>
        <ScrollDiv>
          <DealsSection />
        </ScrollDiv>
      </Container>
    </BusinessProvider>
  );
}
export default Deals;
