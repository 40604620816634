import React, { createContext, ReactNode, useState, useContext } from 'react';

import { IBitrixTimeLineComment } from '../../../../../../../../../../../../../../../../GraphQL/queries/listTimeLineComments';

interface IDealCommentContext {
  comment: IBitrixTimeLineComment;
  setComment(comment: IBitrixTimeLineComment): void;
}

const dealCommentContext = createContext<IDealCommentContext>(
  {} as IDealCommentContext,
);

interface DealCommentProviderProps {
  children: ReactNode;
  comment: IBitrixTimeLineComment;
}

export function DealCommentProvider({
  children,
  comment: usedComment,
}: DealCommentProviderProps): JSX.Element {
  const [comment, setComment] = useState<IBitrixTimeLineComment>(usedComment);

  return (
    <dealCommentContext.Provider
      value={{
        comment,
        setComment,
      }}
    >
      {children}
    </dealCommentContext.Provider>
  );
}

export function useDealComment(): IDealCommentContext {
  const context = useContext(dealCommentContext);
  if (!context) {
    throw new Error('useDealComment must be used within a DealCommentProvider');
  }
  return context;
}
