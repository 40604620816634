import { gql } from '@apollo/client';

export interface listTimeLineCommentsQueryVars {
  id: number;
}

export interface IBitrixFile {
  id: number;
  date: string;
  type: string;
  name: string;
  urlDownload: string;
}

export interface IBitrixTimeLineComment {
  ID: number;
  CREATED: string;
  ENTITY_TYPE: string;
  AUTHOR_ID: number;
  COMMENT: string;
  FILES: IBitrixFile[];
}

export interface listTimeLineCommentsQueryDate {
  total: number;
  result: IBitrixTimeLineComment[];
}

export interface listTimeLineCommentsQueryResponse {
  listTimeLineComments: listTimeLineCommentsQueryDate;
}

export const LIST_TIMELINE_COMMENTS = gql`
  query listTimeLineComments($id: Int!) {
    listTimeLineComments(data: { id: $id }) {
      total
      result {
        ID
        CREATED
        ENTITY_TYPE
        AUTHOR_ID
        COMMENT
        FILES {
          id
          date
          type
          name
          urlDownload
        }
      }
    }
  }
`;
