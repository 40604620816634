import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > button {
    flex: 1;
    display: flex;
    justify-content: left;
    text-transform: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1.5rem;
    /* identical to box height, or 129% */
    /* Text/Text Dark */
    color: #363c4c;
    &:hover {
      background-color: #ebfaf7;
    }
  }
`;
