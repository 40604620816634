import React from 'react';
import { useDeals } from '../Hooks/Deals';
import DealProductEditForm from './DealProductEditForm';

import { Container } from './style';

function DealsProductsSection(): JSX.Element {
  const { selectedCategoryDealsProducts } = useDeals();
  return selectedCategoryDealsProducts.length > 0 ? (
    <Container>
      {selectedCategoryDealsProducts.map((dealProduct) => (
        <>
          <DealProductEditForm key={dealProduct.id} dealProduct={dealProduct} />
        </>
      ))}
    </Container>
  ) : (
    <></>
  );
}

export default DealsProductsSection;
