import { Button } from '@material-ui/core';
import React from 'react';
import { useAvatar } from '../../../../hooks/Avatar';

function RemoveAvatarButton(): JSX.Element {
  const { removeProfilePicture } = useAvatar();

  return (
    <Button variant="outlined" color="secondary" onClick={removeProfilePicture} style={{
      width: '80px',
      fontSize: '0.8rem',
    }}>
      Remover
    </Button>
  );
}

export default RemoveAvatarButton;
