import { IconButton } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import React, { RefObject, useCallback } from 'react';

import { Container } from './style';

interface ScrollToRightButtonProps {
  reference: RefObject<HTMLDivElement>;
}

function ScrollToRightButton({
  reference,
}: ScrollToRightButtonProps): JSX.Element {
  const scrollReference = useCallback(() => {
    if (reference.current) {
      // eslint-disable-next-line no-param-reassign
      reference.current.scrollLeft += 1000;
    }
  }, [reference]);

  return (
    <Container>
      <IconButton onClick={scrollReference}>
        <ChevronRight />
      </IconButton>
    </Container>
  );
}

export default ScrollToRightButton;
