import { ApolloError, useMutation } from '@apollo/client';
import { Mail } from '@material-ui/icons';
import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import InputLight from '../../../../components/Input/InputLight';
import REQUEST_RECOVER_PASSWORD, {
  requestRecoverPasswordMutationResponse,
  requestRecoverPasswordMutationVars,
} from '../../../../GraphQL/mutations/requestRecoverPassword';
import { useCompany } from '../../../../hooks/Company';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { Container, Content, PresentationContainer } from './style';

function ChangePassword(): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const { company } = useCompany();

  const handleOnRequestRecoverPasswordCompleted = useCallback(() => {
    toast.success(`As instruções foram enviadas com sucesso!`);
  }, []);

  const handleOnRequestRecoverPasswordError = useCallback(
    (error: ApolloError) => {
      toast.warning(
        `Não foi possível realizar a recuperação de email: ${error.message}`,
      );
    },
    [],
  );

  const [requestRecoverPassword, { loading }] = useMutation<
    requestRecoverPasswordMutationResponse,
    requestRecoverPasswordMutationVars
  >(REQUEST_RECOVER_PASSWORD, {
    onError: handleOnRequestRecoverPasswordError,
    onCompleted: handleOnRequestRecoverPasswordCompleted,
  });

  const handleOnSubmit = useCallback(
    async (data: requestRecoverPasswordMutationVars) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Você precisa digitar um email')
            .email('Digite um email válido'),
        });
        await schema.validate(data, { abortEarly: false });
        await requestRecoverPassword({ variables: data });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          toast.warning('Por favor, preencha os dados corretamente');
          return;
        }
        toast.error('Houve um erro ao consultar o banco de dados');
      }
    },
    [requestRecoverPassword],
  );

  useEffect(() => {
    formRef.current?.setData({
      email: company.email,
    });
  }, [company.email]);

  return (
    <Container>
      <Content ref={formRef} onSubmit={handleOnSubmit}>
        <PresentationContainer>
          <h1>Redefinir senha</h1>
          <h2>Clique no botão abaixo para redefinir sua senha.</h2>
        </PresentationContainer>
        <InputLight
          type="hidden"
          name="email"
          disabled
          style={{
            display: 'none',
          }}
        />
        <Button fullWidth type="submit" endIcon={<Mail />}>
          {`${!loading ? 'Enviar' : 'Enviando'} instruções para meu e-mail`}
        </Button>
      </Content>
    </Container>
  );
}

export default ChangePassword;
