import styled from 'styled-components';

export const Container = styled.div`
  height: min-content;
  width: min-content;

  & > button {
    color: #fff;
  }
`;
