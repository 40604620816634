import React, { useCallback } from 'react';

import { useCompany } from '../../hooks/Company';
import { useModal } from '../../hooks/Modal';
import NewsScreenshotContainer from './components/NewsScreenshotContainer';

import { Container, ButtonOutlined } from './style';

function NewsOnboardWindow(): JSX.Element {
  const { company } = useCompany();
  const { setIsModalOpen} = useModal();


  const handleOnCloseButtonClick = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <Container>
      <ButtonOutlined onClick={handleOnCloseButtonClick}>X</ButtonOutlined>
      <h1>{`Olá, ${company.personName}.`}</h1>
      <p>Confira as novidades mais recentes em nossa plataforma!</p>
      <NewsScreenshotContainer />
    </Container>
  );
}

export default NewsOnboardWindow;
