import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { TableHead, TableRow, TableBody } from '@material-ui/core';
import { InstallmentData } from '../../../../../../../../GraphQL/mutations/getSACSimulation';
import formatToPTBRMoneyValue from '../../../../../../../../utils/formatMoney';
import { HeadTableCell } from '../style';
import { BodyTableCell } from './style';
import colors from '../../../../../../../../styles/colors';

interface ITableProps {
  installments: InstallmentData[];
}

const RealStateTable = ({ installments }: ITableProps): JSX.Element => {
  return (
    <>
      <TableHead
        style={{
          backgroundColor: colors.primary.normal.first,
          justifyContent: 'center',
        }}
      >
        <HeadTableCell>Parcelas</HeadTableCell>
        <HeadTableCell>Valor</HeadTableCell>
        <HeadTableCell>Juros</HeadTableCell>
        <HeadTableCell>Amortização</HeadTableCell>
        <HeadTableCell>Saldo Devedor</HeadTableCell>
      </TableHead>
      <TableBody>
        {installments.map((installment, index) => {
          const newIndex = index + 1;
          const x12 = newIndex === 12;
          const x24 = newIndex === 24;
          const x36 = newIndex === 36;
          const x48 = newIndex === 48;
          const x60 = newIndex === 60;
          const x120 = newIndex === 120;
          const x240 = newIndex === 240;
          const x360 = newIndex === 360;
          const x420 = newIndex === 420;
          const last = newIndex === installments.length;
          const totalInstallments =
            newIndex === 1 ||
            x12 ||
            x24 ||
            x36 ||
            x48 ||
            x60 ||
            x120 ||
            x240 ||
            x360 ||
            x420 || last;
          return (
            totalInstallments && (
              <TableRow key={uuidV4()}>
                <BodyTableCell>{newIndex}º - parcela</BodyTableCell>
                <BodyTableCell>
                  {formatToPTBRMoneyValue(installment.installment)}
                </BodyTableCell>
                <BodyTableCell>
                  {formatToPTBRMoneyValue(installment.interest)}
                </BodyTableCell>
                <BodyTableCell>
                  {formatToPTBRMoneyValue(installment.amortization)}
                </BodyTableCell>
                <BodyTableCell>
                  {formatToPTBRMoneyValue(installment.amount)}
                </BodyTableCell>
              </TableRow>
            )
          );
        })}
      </TableBody>
    </>
  );
};

export default RealStateTable;
