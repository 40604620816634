import { Radio, RadioGroup, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled(RadioGroup)`
  width: 100%;
`;

export const RadioCustomized = withStyles({
  root: {
    fontWeight: 'normal',
    lineHeight: '150%',
    color: colors.text.fourth,
    '& .MuiIconButton-label': {
      color: colors.primary.normal.first,
    },
  },
})(Radio);
