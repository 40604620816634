import React, { createContext, ReactNode, useContext, useState } from 'react';

interface NavbarHookContextData {
  isSidebarCompressed: boolean;
  setIsSidebarCompressed(isSidebarCompressed: boolean): void;
}

const NavbarHookContext = createContext<NavbarHookContextData>(
  {} as NavbarHookContextData,
);

interface NavbarHookProviderProps {
  children: ReactNode;
}

export function NavbarHookProvider({
  children,
}: NavbarHookProviderProps): JSX.Element {
  const [isSidebarCompressed, setIsSidebarCompressed] = useState<boolean>(
    false,
  );

  return (
    <NavbarHookContext.Provider
      value={{
        isSidebarCompressed,
        setIsSidebarCompressed,
      }}
    >
      {children}
    </NavbarHookContext.Provider>
  );
}

export function useNavbarHook(): NavbarHookContextData {
  const context = useContext(NavbarHookContext);
  if (!context) {
    throw new Error('useNavbarHook must me used within a NavbarHookProvider');
  }
  return context;
}
