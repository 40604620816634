import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroupProps,
} from '@material-ui/core';
import { useField } from '@unform/core';
import { v4 } from 'uuid';

import { Container, RadioCustomized } from './style';

interface Option {
  value: string;
  label: string;
}

interface Props extends RadioGroupProps {
  name: string;
  options: Option[];
  legend?: string;
}

function RadioGroup({ name, options, legend }: Props): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [checkedOption, setCheckedOption] = useState(defaultValue);
  const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.value = event.target.value;
      setCheckedOption(inputRef.current.value);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        // eslint-disable-next-line no-param-reassign
        ref.current.value = value;
      },
      clearValue: (ref) => {
        // eslint-disable-next-line no-param-reassign
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);
  return (
    <Container
      ref={inputRef}
      name={fieldName}
      defaultValue={defaultValue}
      value={checkedOption}
      onChange={handleOnChange}
    >
      {!!legend && <FormLabel component="legend">{legend}</FormLabel>}
      {options.map((option) => (
        <FormControlLabel
          value={option.value}
          label={option.label}
          control={<RadioCustomized />}
          key={v4()}
        />
      ))}
      {!!error && (
        <FormHelperText style={{ color: '#db0242' }}>{error}</FormHelperText>
      )}
    </Container>
  );
}

export default RadioGroup;
